@import url(https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap);
.yt-lp-mt-5 {
  margin-top: 50px;
}
.yt-lp-mt-4 {
  margin-top: 40px;
}

.yt-lp-mb-5 {
  margin-bottom: 50px;
}
.continuePhoneLogin {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--color-black);
  margin-top: 0.5rem;
}
.continuePhoneLogin:hover {
  color: var(--color-RegularActiveText);
}
.yt-login-wrap {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.sign-in {
  font-size: 18px !important;
}

.yt-login-list-style-none {
  list-style-type: none;
}

.lp-tabs-name li {
  font-size: 18px;
  line-height: 0.94;
  color: var(--color-dark-grey);
  padding: 0 15px 15px;
  cursor: pointer;
}

.lp-tabs-name li:first-child {
  margin-right: 10px;
}

.lp-tabs-name li.active {
  color: var(--color-RegularActiveText);
  /* color: black; */
  font-weight: bold;
  border-bottom: 2px solid var(--color-RegularActiveText);
  line-height: 1.11;
}

.yt-signup-icn,
.yt-login-icn {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn2 {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn3 {
  max-width: 24px;
  position: absolute;
  top: 18%;
  transform: translateY(-50%);
  left: 20px;
}

.yt-signup-form .form-group,
.yt-login-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.yt-signup-form .form-group .form-control,
.yt-login-form .form-group .form-control {
  padding-left: 65px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-blue);
  border: solid 1px #cae2fe;
}

.yt-signup-form .form-group .form-control:focus,
.yt-login-form .form-group .form-control:focus {
  border: solid 1px var(--color-blue);
}

.yt-sign-up-message-show,
.yt-login-message-show {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  position: absolute;
  bottom: -20px;
  display: none;
}

.yt-signup-form .form-group.error .yt-sign-up-message-show,
.yt-login-form .form-group.error .yt-login-message-show {
  display: block;
  color: var(--color-orange);
}

.yt-signup-form .form-group.error .form-control,
.yt-login-form .form-group.error .form-control {
  border-color: var(--color-orange);
}

.yt-signup-bottom-info,
.yt-login-bottom-info {
  margin-top: 15px;
}

.yt-signup-via-tag {
  margin: 20px 0;
}

.yt-login-via-tag {
  margin: 30px 0;
}

.yt-signup-pass-info,
.yt-login-pass-info {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  color: var(--color-black);
  position: absolute;
  bottom: -20px;
}

.btn-primary,
.btn-secondary {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: var(--button-text-primary);
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
}
/* .btn-secondary {
    color: var(--color-orange);
    background-color: var(--color-orange);
    border-color: var(--color-orange);
  } */
.yt-signup-tag-line,
.yt-login-tag-line {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 27px;
  margin-top: 23px;
}

.yt-sign-up-pass-vie-icn,
.yt-login-pass-vie-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-login-pass-vie-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-signup-via-tag,
.yt-login-via-tag {
  font-size: 14px;
  line-height: 1.14;
  color: silver;
}

.yt-signup-via-fb,
.yt-login-via-fb {
  background-color: white;
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-blue);
  line-height: 1.11;
  border: white;
}

.lp-bottom-links {
  margin-top: 17px;
}

.yt-signup-via-email,
.yt-login-via-email {
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-orange);
  line-height: 1.11;
  background-color: white;
  border-color: white;
}

.yt-signup-aggre-tag,
.yt-login-aggre-tag {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--color-dark-grey);
  margin-bottom: 10px;
}

.yt-signup-term-privacy-link,
.yt-login-term-privacy-link {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}

.yt-signup-skip-btn,
.yt-login-skip-btn {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-login-bg-banner {
  /* background-image: url(../images/login-image.jpg); */
  background-size: cover;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}

.yt-signup-oi-tag-small,
.yt-login-oi-tag-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  margin-bottom: 22px;
}

.yt-signup-oi-tag-big,
.yt-login-oi-tag-big {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.3;
  color: #ffffff;
}

.yt-signup-sp-now,
.yt-login-sp-now {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  max-width: 180px;
  margin-top: 56px;
}

.yt-sign-other-info,
.yt-login-other-info {
  position: absolute;
  right: 0;
  bottom: 10%;
  padding: 0 40px;
}

.yt-signup-bdr,
.yt-login-bdr {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 80px;
  right: -40px;
}

.yt-login-forget-pass {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: var(--color-primary);
  display: inline-block;
  cursor: pointer;
}

.yt-login-inner-content.yt-login-bg-banner.yt-login-col {
  width: 45%;
}

.yt-login-inner-content.yt-login-form-wrapper {
  padding-left: 0px;
  width: 100%;
}

.yt-signup-form img,
.yt-login-form img,
.yt-fp-form img {
  top: 50% !important;
  transform: translateY(-50%);
}
/**/

@media (min-width: 1900px) {
  .yt-login-col {
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .yt-login-wrap {
    max-width: 600px;
  }
}

.login-on-cart-page .container {
  max-width: 100%;
  padding: 0;
}
/*
    * Cart Page Login Component CSS
    */

.login-on-cart-page .lp-bottom-links {
  display: none;
}

.login-on-cart-page .yt-sign-up-message-show {
  bottom: -20px;
}

.login-on-cart-page .yt-login-wrap {
  margin-top: 0 !important;
  max-width: 100%;
  margin-bottom: 18px;
}

@media (min-width: 1367px) {
  .login-on-cart-page.login-box-top {
    display: none;
  }
}

@media (max-width: 1367px) {
  .login-on-cart-page.login-box-bottom {
    display: none;
  }
}

.err_invalid {
  color: #dc3545;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
}
/*
    * Screen for under 1280 Resolution   Tab
    */

@media (max-width: 1279.92px) {
  .continuePhoneLogin {
    margin: 0;
  }
  .yt-login-forget-pass {
    padding: 0;
  }
  .yt-login-inner-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-via-tag {
    margin: 20px 0;
  }
  .yt-login-wrap {
    margin-top: 37px;
  }
  .yt-login-inner-content.yt-login-col {
    width: 100% !important;
  }
  .yt-login-wrap {
    max-width: 588px;
    padding: 0;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 30px 30px;
    order: -1;
  }
  .yt-login-bg-banner {
    min-height: 210px;
    background-position: 100% 35%;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.15;
    font-size: 48px;
  }
  .yt-sign-other-info,
  .yt-login-other-info {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 30px;
    line-height: 1.13;
    font-size: 16px;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    max-width: 150px;
  }
  .yt-signup-bdr,
  .yt-login-bdr {
    bottom: 63px;
  }
  .lp-tabs-name li {
    font-size: 16px;
    line-height: 1.13;
    padding: 0 12px 7.5px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 14px;
    line-height: 1.14;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    margin-bottom: 10px !important;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-signup-btn {
    margin-top: 47px;
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .yt-signup-pass-info {
    bottom: -24px !important;
  }
  .yt-signup-form {
    margin-bottom: 20px !important;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 150px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-form {
    margin-bottom: 20px !important;
  }
  .yt-login-bottom-info {
    margin-top: 20px !important;
  }
  .lp-bottom-links .yt-skip-wrap .yt-login-skip-btn {
    display: block;
  }
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 100px 30px;
  }
}
/*
    * Screen for under 768 Resolution Mobile
    */

@media (max-width: 767.98px) {
  .continuePhoneLogin {
    margin: 0;
  }
  .yt-signup-form .invalid-feedback,
  .yt-login-form .invalid-feedback {
    margin: 6px 0 0;
  }
  .lp-tabs-name li {
    font-size: 14px;
    line-height: 1.21 !important;
  }
  .lp-tabs-name li.active {
    line-height: 1.14 !important;
  }
  .yt-login-wrap {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .yt-login-wrap {
    max-width: 335px;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.13;
    font-size: 30px;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding: 14px 27px !important;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 11px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 13px;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 13px;
    line-height: 1.15;
  }
  .yt-signup-btn {
    margin-top: 41px;
  }
  .yt-login-btn {
    margin-top: 20px !important;
  }
  .yt-signup-form {
    margin-bottom: 15px !important;
  }
  .yt-signup-skip-btn,
  .yt-login-skip-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-via-tag,
  .yt-login-via-tag {
    font-size: 11px;
    line-height: 1.18;
    margin: 15px 0;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 110px;
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    padding-left: 10px;
  }
  .yt-signup-via-email,
  .yt-login-via-email {
    padding-left: 15px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 14px;
    line-height: 1.14;
  }
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }

  .yt-signup-form .yt-form-err,
  .yt-login-form .yt-form-err {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .yt-login-wrap {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100%;
  }
  .yt-signup-pass-info {
    bottom: -32px !important;
  }
  .err_invalid {
    font-size: 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-signup-form .form-group .form-control {
    font-size: 16px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    font-size: 16px;
  }
  .yt-signup-via-email {
    font-size: 16px;
  }
  .yt-login-wrap {
    padding: 30px 40px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    margin-bottom: 35px;
    margin-top: 15px;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    bottom: -23px;
  }
  .err_invalid,
  .invalid-feedback {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-login-bottom-info .yt-login-via-tag {
    margin-top: 19px;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-login-form.mb-4 {
    margin-bottom: 17px !important;
  }
  .yt-login-bottom-info.mt-4 {
    margin-top: 17px !important;
  }
}

.btn-block {
  width: 100%;
}
.yt-lp-mt-5 {
  margin-top: 50px;
}
.yt-lp-mt-4 {
  margin-top: 40px;
}

.yt-lp-mb-5 {
  margin-bottom: 50px;
}
.signup-btn {
  font-size: 18px !important;
}
.yt-login-wrap {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.yt-login-list-style-none {
  list-style-type: none;
}

.lp-tabs-name li {
  font-size: 18px;
  line-height: 0.94;
  color: var(--color-dark-grey);
  padding: 0 15px 15px;
  cursor: pointer;
}

.lp-tabs-name li:first-child {
  margin-right: 10px;
}

.lp-tabs-name li.active {
  color: black;
  font-weight: bold;
  border-bottom: 2px solid var(--color-primary);
  line-height: 1.11;
}

.yt-signup-icn,
.yt-login-icn {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn2 {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn3 {
  max-width: 24px;
  position: absolute;
  top: 18%;
  transform: translateY(-50%);
  left: 20px;
}

.yt-signup-form .form-group,
.yt-login-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.yt-signup-form .form-group .form-control,
.yt-login-form .form-group .form-control {
  padding-left: 65px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-blue);
  border: solid 1px #cae2fe;
}

.yt-signup-form .form-group .form-control:focus,
.yt-login-form .form-group .form-control:focus {
  border: solid 1px var(--color-blue);
}

.yt-sign-up-message-show,
.yt-login-message-show {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  position: absolute;
  bottom: -20px;
  display: none;
}

.yt-signup-form .form-group.error .yt-sign-up-message-show,
.yt-login-form .form-group.error .yt-login-message-show {
  display: block;
  color: var(--color-orange);
}

.yt-signup-form .form-group.error .form-control,
.yt-login-form .form-group.error .form-control {
  border-color: var(--color-orange);
}

.yt-signup-bottom-info,
.yt-login-bottom-info {
  margin-top: 15px;
}

.yt-signup-via-tag {
  margin: 20px 0;
}

.yt-login-via-tag {
  margin: 30px 0;
}

.yt-signup-pass-info,
.yt-login-pass-info {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  color: var(--color-black);
  position: absolute;
  bottom: -20px;
}

.yt-signup-btn,
.yt-login-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  /* color: var(--button-text-primary) !important; */
  color: var(--color-ButtonTextRegular);
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  /* background-color: var(--button-primary) !important; */
  background-color: var(--color-ButtonBackRegular) !important;
  border-color: var(--button-primary) !important;
}

.yt-signup-btn:hover,
.yt-login-btn:hover {
  background-color: var(--color-ButtonBackHover) !important;
  color: var(--color-ButtonTextHover);
}

.yt-signup-tag-line,
.yt-login-tag-line {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 27px;
  margin-top: 23px;
}

.yt-sign-up-pass-vie-icn,
.yt-login-pass-vie-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-sign-up-pass-vie-icn,
.yt-login-pass-vie-icn,
#eyeicon {
  position: absolute;
  /* top: 50%; */
  top: 36%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-login-pass-vie-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-signup-via-tag,
.yt-login-via-tag {
  font-size: 14px;
  line-height: 1.14;
  color: silver;
}

.yt-signup-via-fb,
.yt-login-via-fb {
  background-color: white !important ;
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-blue) !important;
  line-height: 1.11;
  border: white !important;
  font-family: "GTWalsheimPro-Medium-";
  font-size: 18px !important;
  font-weight: 500 !important;
}

.lp-bottom-links {
  margin-top: 17px;
}

.yt-signup-via-email,
.yt-login-via-email {
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-orange) !important;
  line-height: 1.11;
  background-color: white !important;
  border-color: white !important;
  font-family: "GTWalsheimPro-Medium-" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.yt-signup-aggre-tag,
.yt-login-aggre-tag {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--color-dark-grey);
  margin-bottom: 10px;
}

.yt-signup-term-privacy-link,
.yt-login-term-privacy-link {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}

.yt-signup-skip-btn,
.yt-login-skip-btn {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-login-bg-banner {
  /* background-image: url(../images/login-image.jpg); */
  background-size: cover;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}

.yt-signup-oi-tag-small,
.yt-login-oi-tag-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  margin-bottom: 22px;
}

.yt-signup-oi-tag-big,
.yt-login-oi-tag-big {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.3;
  color: #ffffff;
}

.yt-signup-sp-now,
.yt-login-sp-now {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  max-width: 180px;
  margin-top: 56px;
}

.yt-sign-other-info,
.yt-login-other-info {
  position: absolute;
  right: 0;
  bottom: 10%;
  padding: 0 40px;
}

.yt-signup-bdr,
.yt-login-bdr {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 80px;
  right: -40px;
}

.yt-login-forget-pass {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: var(--color-primary);
  display: inline-block;
  cursor: pointer;
}

.yt-login-inner-content.yt-login-bg-banner.yt-login-col {
  width: 45%;
}

.yt-login-inner-content.yt-login-form-wrapper {
  padding-left: 0px;
  width: 100%;
}

.yt-signup-form img,
.yt-login-form img,
.yt-fp-form img {
  top: 50% !important;
  transform: translateY(-50%);
}
.yt-signup-form img,
.yt-login-form img,
.yt-fp-form img,
#passwordkey {
  top: 40% !important;
  transform: translateY(-50%);
}
/**/

@media (min-width: 1900px) {
  .yt-login-col {
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .yt-login-wrap {
    max-width: 600px;
  }
}

.login-on-cart-page .container {
  max-width: 100%;
  padding: 0;
}
/*
    * Cart Page Login Component CSS
    */

.login-on-cart-page .lp-bottom-links {
  display: none;
}

.login-on-cart-page .yt-sign-up-message-show {
  bottom: -20px;
}

.login-on-cart-page .yt-login-wrap {
  margin-top: 0 !important;
  max-width: 100%;
  margin-bottom: 18px;
}

@media (min-width: 1367px) {
  .login-on-cart-page.login-box-top {
    display: none;
  }
}

@media (max-width: 1367px) {
  .login-on-cart-page.login-box-bottom {
    display: none;
  }
}

.err_invalid {
  color: #dc3545;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
}
/*
    * Screen for under 1280 Resolution   Tab
    */

@media (max-width: 1279.92px) {
  .yt-login-forget-pass {
    padding: 0;
  }
  .yt-login-inner-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-via-tag {
    margin: 20px 0;
  }
  .yt-login-wrap {
    margin-top: 37px;
  }
  .yt-login-inner-content.yt-login-col {
    width: 100% !important;
  }
  .yt-login-wrap {
    max-width: 588px;
    padding: 0;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 30px 30px;
    order: -1;
  }
  .yt-login-bg-banner {
    min-height: 210px;
    background-position: 100% 35%;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.15;
    font-size: 48px;
  }
  .yt-sign-other-info,
  .yt-login-other-info {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 30px;
    line-height: 1.13;
    font-size: 16px;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    max-width: 150px;
  }
  .yt-signup-bdr,
  .yt-login-bdr {
    bottom: 63px;
  }
  .lp-tabs-name li {
    font-size: 16px;
    line-height: 1.13;
    padding: 0 12px 7.5px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 14px;
    line-height: 1.14;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    margin-bottom: 10px !important;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-signup-btn {
    margin-top: 47px;
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .yt-signup-pass-info {
    bottom: -24px !important;
  }
  .yt-signup-form {
    margin-bottom: 20px !important;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 150px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-form {
    margin-bottom: 20px !important;
  }
  .yt-login-bottom-info {
    margin-top: 20px !important;
  }
  /* .yt-skip-wrap {
      display: none;
    } */
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 100px 30px;
  }
}
/*
    * Screen for under 768 Resolution Mobile
    */

@media (max-width: 767.98px) {
  .yt-signup-form .invalid-feedback,
  .yt-login-form .invalid-feedback {
    margin: 6px 0 0;
  }
  .lp-tabs-name li {
    font-size: 14px;
    line-height: 1.21 !important;
  }
  .lp-tabs-name li.active {
    line-height: 1.14 !important;
  }
  .yt-login-wrap {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .yt-login-wrap {
    max-width: 335px;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.13;
    font-size: 30px;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding: 14px 27px !important;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 11px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    font-size: 10px;
    line-height: 1.1;
    height: 10% !important;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 30px !important;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 13px;
    line-height: 1.15;
  }
  .yt-signup-btn {
    margin-top: 41px;
  }
  .yt-login-btn {
    margin-top: 20px !important;
  }
  .yt-signup-form {
    margin-bottom: 15px !important;
  }
  .yt-signup-skip-btn,
  .yt-login-skip-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-via-tag,
  .yt-login-via-tag {
    font-size: 11px;
    line-height: 1.18;
    margin: 15px 0;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 110px;
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    padding-left: 10px;
  }
  .yt-signup-via-email,
  .yt-login-via-email {
    padding-left: 15px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 14px;
    line-height: 1.14;
  }
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }

  .yt-signup-form .yt-form-err,
  .yt-login-form .yt-form-err {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .yt-login-wrap {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100%;
  }
  .yt-signup-pass-info {
    bottom: -32px !important;
  }
  .err_invalid {
    font-size: 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-signup-form .form-group .form-control {
    font-size: 16px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    font-size: 16px;
  }
  .yt-signup-via-email {
    font-size: 16px;
  }
  .yt-login-wrap {
    padding: 30px 40px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    margin-bottom: 35px;
    margin-top: 15px;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    bottom: -23px;
  }
  .err_invalid,
  .invalid-feedback {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-login-bottom-info .yt-login-via-tag {
    margin-top: 19px;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-login-form.mb-4 {
    margin-bottom: 17px !important;
  }
  .yt-login-bottom-info.mt-4 {
    margin-top: 17px !important;
  }
}

.yt-lp-mt-5 {
  margin-top: 50px;
}
.yt-lp-mt-4 {
  margin-top: 40px;
}

.yt-lp-mb-5 {
  margin-bottom: 50px;
}

.yt-login-wrap {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.yt-login-list-style-none {
  list-style-type: none;
}
.buttonTitle {
  font-family: "GTWalsheimPro-Bold";
  font-size: "18px";
  font-weight: "bold";
}
.lp-tabs-name li {
  font-size: 18px;
  line-height: 0.94;
  color: var(--color-dark-grey);
  padding: 0 15px 15px;
  cursor: pointer;
}

.lp-tabs-name li:first-child {
  margin-right: 10px;
}

.lp-tabs-name li.active {
  color: var(--color-RegularActiveText);
  font-weight: bold;
  border-bottom: 2px solid var(--color-RegularActiveText);
  line-height: 1.11;
}

.yt-signup-icn,
.yt-login-icn {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn2 {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn3 {
  max-width: 24px;
  position: absolute;
  top: 18%;
  transform: translateY(-50%);
  left: 20px;
}

.yt-signup-form .form-group,
.yt-login-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.yt-signup-form .form-group .form-control,
.yt-login-form .form-group .form-control {
  padding-left: 65px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-blue);
  border: solid 1px #cae2fe;
}

.yt-signup-form .form-group .form-control:focus,
.yt-login-form .form-group .form-control:focus {
  border: solid 1px var(--color-blue);
}

.yt-sign-up-message-show,
.yt-login-message-show {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  position: absolute;
  bottom: -20px;
  display: none;
}

.yt-signup-form .form-group.error .yt-sign-up-message-show,
.yt-login-form .form-group.error .yt-login-message-show {
  display: block;
  color: var(--color-orange);
}

.yt-signup-form .form-group.error .form-control,
.yt-login-form .form-group.error .form-control {
  border-color: var(--color-orange);
}

.yt-signup-bottom-info,
.yt-login-bottom-info {
  margin-top: 15px;
}

.yt-signup-via-tag {
  margin: 20px 0;
}

.yt-login-via-tag {
  margin: 30px 0;
}

.yt-signup-pass-info,
.yt-login-pass-info {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  color: var(--color-black);
  position: absolute;
  bottom: -20px;
}

.yt-signup-btn,
.yt-login-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: var(--button-text-primary);
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
}

.yt-signup-tag-line,
.yt-login-tag-line {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 27px;
  margin-top: 23px;
}

.yt-sign-up-pass-vie-icn,
.yt-login-pass-vie-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-login-pass-vie-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-signup-via-tag,
.yt-login-via-tag {
  font-size: 14px;
  line-height: 1.14;
  color: silver;
}

.yt-signup-via-fb,
.yt-login-via-fb {
  background-color: white;
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-blue);
  line-height: 1.11;
  border: white;
}

.lp-bottom-links {
  margin-top: 17px;
}

.yt-signup-via-email,
.yt-login-via-email {
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-orange);
  line-height: 1.11;
  background-color: white;
  border-color: white;
}

.yt-signup-aggre-tag,
.yt-login-aggre-tag {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--color-dark-grey);
  margin-bottom: 10px;
}

.yt-signup-term-privacy-link,
.yt-login-term-privacy-link {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}

.yt-signup-skip-btn,
.yt-login-skip-btn {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-login-bg-banner {
  /* background-image: url(../images/login-image.jpg); */
  background-size: cover;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}

.yt-signup-oi-tag-small,
.yt-login-oi-tag-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  margin-bottom: 22px;
}

.yt-signup-oi-tag-big,
.yt-login-oi-tag-big {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.3;
  color: #ffffff;
}

.yt-signup-sp-now,
.yt-login-sp-now {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  max-width: 180px;
  margin-top: 56px;
}

.yt-sign-other-info,
.yt-login-other-info {
  position: absolute;
  right: 0;
  bottom: 10%;
  padding: 0 40px;
}

.yt-signup-bdr,
.yt-login-bdr {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 80px;
  right: -40px;
}

.yt-login-forget-pass {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: var(--color-primary);
  display: inline-block;
  cursor: pointer;
}

.yt-login-inner-content.yt-login-bg-banner.yt-login-col {
  width: 45%;
}

.yt-login-inner-content.yt-login-form-wrapper {
  padding-left: 0px;
  width: 100%;
}

.yt-signup-form img,
.yt-login-form img,
.yt-fp-form img {
  top: 50% !important;
  transform: translateY(-50%);
}
/**/

@media (min-width: 1900px) {
  .yt-login-col {
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .yt-login-wrap {
    max-width: 600px;
  }
}

.login-on-cart-page .container {
  max-width: 100%;
  padding: 0;
}
/*
  * Cart Page Login Component CSS
  */

.login-on-cart-page .lp-bottom-links {
  display: none;
}

.login-on-cart-page .yt-sign-up-message-show {
  bottom: -20px;
}

.login-on-cart-page .yt-login-wrap {
  margin-top: 0 !important;
  max-width: 100%;
  margin-bottom: 18px;
}

@media (min-width: 1367px) {
  .login-on-cart-page.login-box-top {
    display: none;
  }
}

@media (max-width: 1367px) {
  .login-on-cart-page.login-box-bottom {
    display: none;
  }
}

.err_invalid {
  color: #dc3545;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
}
/*
  * Screen for under 1280 Resolution   Tab
  */

@media (max-width: 1279.92px) {
  .yt-login-forget-pass {
    padding: 0;
  }
  .yt-login-inner-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-via-tag {
    margin: 20px 0;
  }
  .yt-login-wrap {
    margin-top: 37px;
  }
  .yt-login-inner-content.yt-login-col {
    width: 100% !important;
  }
  .yt-login-wrap {
    max-width: 588px;
    padding: 0;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 30px 30px;
    order: -1;
  }
  .yt-login-bg-banner {
    min-height: 210px;
    background-position: 100% 35%;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.15;
    font-size: 48px;
  }
  .yt-sign-other-info,
  .yt-login-other-info {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 30px;
    line-height: 1.13;
    font-size: 16px;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    max-width: 150px;
  }
  .yt-signup-bdr,
  .yt-login-bdr {
    bottom: 63px;
  }
  .lp-tabs-name li {
    font-size: 16px;
    line-height: 1.13;
    padding: 0 12px 7.5px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 14px;
    line-height: 1.14;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    margin-bottom: 10px !important;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-signup-btn {
    margin-top: 47px;
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .yt-signup-pass-info {
    bottom: -24px !important;
  }
  .yt-signup-form {
    margin-bottom: 20px !important;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 150px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-form {
    margin-bottom: 20px !important;
  }
  .yt-login-bottom-info {
    margin-top: 20px !important;
  }
  /* .yt-skip-wrap {
    display: none;
  } */
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 100px 30px;
  }
}
/*
  * Screen for under 768 Resolution Mobile
  */

@media (max-width: 767.98px) {
  .yt-signup-form .invalid-feedback,
  .yt-login-form .invalid-feedback {
    margin: 6px 0 0;
  }
  .lp-tabs-name li {
    font-size: 14px;
    line-height: 1.21 !important;
  }
  .lp-tabs-name li.active {
    line-height: 1.14 !important;
  }
  .yt-login-wrap {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .yt-login-wrap {
    max-width: 335px;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.13;
    font-size: 30px;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding: 14px 27px !important;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 11px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 13px;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 13px;
    line-height: 1.15;
  }
  .yt-signup-btn {
    margin-top: 41px;
  }
  .yt-login-btn {
    margin-top: 20px !important;
  }
  .yt-signup-form {
    margin-bottom: 15px !important;
  }
  .yt-signup-skip-btn,
  .yt-login-skip-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-via-tag,
  .yt-login-via-tag {
    font-size: 11px;
    line-height: 1.18;
    margin: 15px 0;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 110px;
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    padding-left: 10px;
  }
  .yt-signup-via-email,
  .yt-login-via-email {
    padding-left: 15px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 14px;
    line-height: 1.14;
  }
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }

  .yt-signup-form .yt-form-err,
  .yt-login-form .yt-form-err {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .yt-login-wrap {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100%;
  }
  .yt-signup-pass-info {
    bottom: -32px !important;
  }
  .err_invalid {
    font-size: 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-signup-form .form-group .form-control {
    font-size: 16px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    font-size: 16px;
  }
  .yt-signup-via-email {
    font-size: 16px;
  }
  .yt-login-wrap {
    padding: 30px 40px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    margin-bottom: 35px;
    margin-top: 15px;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    bottom: -23px;
  }
  .err_invalid,
  .invalid-feedback {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-login-bottom-info .yt-login-via-tag {
    margin-top: 19px;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-login-form.mb-4 {
    margin-bottom: 17px !important;
  }
  .yt-login-bottom-info.mt-4 {
    margin-top: 17px !important;
  }
}

.mt-10 {
  margin-top: 10px;
}

.smt-btn {
  font-size: 18px !important;
}

.yt-forgot-pw-wrap {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.send-otp-btn {
  /* background: var(--button-primary) !important;
    color: #ffffff !important; */
  background-color: var(--color-ButtonBackRegular) !important;
  color: var(--color-ButtonTextRegular);
}

.send-otp-btn:hover {
  background-color: var(--color-ButtonBackHover) !important;
  color: var(--color-ButtonTextHover);
}

.yt-fpp-mb-5 {
  margin-bottom: 50px;
}

.yt-fpp-mt-5 {
  margin-top: 50px;
}

.yt-fp-top-back-tag-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: var(--color-primary) !important;
  font-family: "GTWalsheimPro-Bold" !important;
}

.yt-fp-back-icn {
  font-size: 40px;
  margin-left: -12px;
}

.yt-fp-bg-banner {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}

.yt-fp-other-info {
  position: absolute;
  right: 40px;
  bottom: 10%;
}

.yt-fp-oi-tag-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}

.yt-fp-oi-tag-big {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}

.yt-fp-bdr {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}

.yt-fp-sp-now {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}

.yt-fp-tag-line {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black) !important;
  font-family: "GTWalsheimPro-Regular-";
  margin-bottom: 40px;
  margin-top: 16px;
}

.yt-fp-tag-line-2 {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 40px !important;
  font-family: "GTWalsheimPro-Regular-";
}
.yt-fp-form {
  position: relative;
}
.yt-fp-form .form-group {
  position: relative;
  margin-bottom: 30px !important;
}

.yt-fp-icn {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.yt-fp-icn2 {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}

.yt-fp-form {
  margin-bottom: 30px;
}

.yt-fp-form .form-group .form-control {
  padding-left: 65px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}

.yt-fp-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  /* color: var(--button-text-primary); */
  color: var(--color-ButtonTextRegular);
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-ButtonBackRegular);
  /* background-color: var(--button-primary); */
  border-color: var(--button-primary);
}
.yt-fp-btn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.yt-fp-skip-btn {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.otp-timer {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}

.yt-resent-otp-btn {
  font-size: 22px;
  line-height: 1.14;
  /* color: var(--color-orange); */
  color: var(--color-ButtonTextRegular);
  text-decoration: none !important;
  box-shadow: none !important;
  padding: 0;
}

.yt-fp-form .error.form-group .form-control {
  border: solid 1px var(--color-orange);
}

.yt-optp-message-show {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.yt-fp-form .error.form-group .yt-optp-message-show {
  color: var(--color-orange);
  display: block;
}

.yt-pas-eye-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-pas-eye-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-fp-form .success.form-group .yt-optp-message-show {
  color: var(--color-green);
  display: block;
}

.error.form-group,
.success.form-group {
  margin-bottom: 43px !important;
}

.invalid {
  color: var(--color-orange);
}

.invalid-input {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}

.normal-input:active {
  border: 1px solid var(--color-blue) !important;
}

.yt-forgot-pass-vie-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-forgot-pass-vie-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.pass_success {
  color: green;
}

.yt-fp-inner-content.yt-banner-col {
  width: 45%;
}

.yt-fp-inner-content.yt-form-col {
  padding-left: 0px;
  width: 100%;
}

.invalid {
  color: var(--color-orange);
}
.invalid-feedback {
  font-size: 12px;
  line-height: 1.17;
}
/**/
.yt-etpass-msg {
  line-height: 1.1;
  font-size: 13.5px;
  margin-top: -1rem;
  position: absolute;
  word-break: break-all;
  /* bottom: 39px; */
}
form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group .invalid-feedback {
  bottom: -15px;
}
form.yt-new-pass-form-wrap {
  position: relative;
}
@media (min-width: 1400px) {
  .yt-frgt-row .yt-banner-col,
  .yt-frgt-row .yt-form-col {
    width: 100%;
  }
  .yt-forgot-pw-wrap {
    max-width: 600px;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}
/*
  * Screen for under 1280 Resolution 
  */

@media (max-width: 1279.92px) {
  .yt-forgot-pw-wrap {
    max-width: 588px;
    padding: 0;
  }
  .yt-frgt-row .yt-fp-inner-content {
    width: 100%;
  }
  .yt-frgt-row .yt-fp-bg-banner {
    order: 2;
  }
  .yt-fp-inner-content.yt-form-col {
    padding: 32px 30px 30px;
  }
  .yt-fp-top-back-tag-name {
    font-size: 18px;
    line-height: 1.11;
    font-family: "GTWalsheimPro-Bold" !important;
    font-weight: bold;
    color: var(--color-primary) !important;
  }
  .yt-fp-tag-line {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
  }
  .yt-fp-tag-line-2 {
    margin-bottom: 34px !important;
    margin-top: 0;
  }
  .yt-fp-form .form-group .form-control {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-fp-form .form-group {
    margin-bottom: 20px;
  }
  .yt-fp-btn {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .yt-fp-bg-banner {
    min-height: 211px;
    background-position: 100% 35%;
  }
  .yt-fp-oi-tag-small {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
  }
  .yt-fp-oi-tag-big {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
  }
  .yt-fp-sp-now {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
  }
  .yt-fp-bdr {
    bottom: 55px !important;
  }
}
/*
  * Screen for under 768 Resolution 
  */

@media (max-width: 767.88px) {
  .yt-fp-top-back-tag-name {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-fp-form .form-group.yt-form-err span {
    bottom: -20px;
  }
  .yt-new-pass-form-wrap .yt-fp-btn {
    margin-top: 25px !important;
  }
  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-fp-btn {
    margin-top: 40px !important;
  }
  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-etpass-msg {
    bottom: 65px;
    top: 130px;
  }
  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-form-err:first-child {
    margin-bottom: 35px !important;
  }
  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-form-err:first-child span {
    top: 95%;
  }
  .yt-fp-inner-content.yt-form-col {
    padding: 15px;
  }
  .yt-forgot-pw-wrap {
    max-width: 335px;
  }
  .yt-fp-tag-line {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
  }
  .yt-fp-tag-line-2 {
    margin-bottom: 20px !important;
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-fp-form .form-group .form-control {
    padding-top: 13px;
    padding-bottom: 12px;
  }
  .yt-fp-form {
    margin-bottom: 15px;
  }
  .yt-fp-oi-tag-small {
    font-size: 10px;
    line-height: 1.11;
  }
  .yt-fp-oi-tag-big {
    font-size: 30px;
    line-height: 1.13;
  }
  .yt-fp-bg-banner {
    min-height: 180px;
  }
  .yt-otp-sd-wrap {
    margin-top: 20px !important;
    padding: 0 !important;
  }

  .yt-otp-sd-wrap button {
    background: var(--button-primary) !important;
    color: #ffffff;
  }
  .yt-fp-btn {
    margin-top: 0 !important;
  }
  .yt-fp-skip-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-forgot-pw-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .yt-fp-form .form-group.yt-form-err {
    margin-bottom: 28px !important;
  }
  .yt-fp-form .invalid.yt-form-err {
    bottom: -20px;
    font-size: 12px;
    line-height: 1.17;
  }
}
@media (max-width: 576px) {
  .yt-forgot-pw-wrap {
    max-width: 100%;
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-fp-form .form-group {
    margin-bottom: 20px !important;
  }
  .yt-fp-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-forgot-pw-wrap {
    padding: 26px 40px 34px;
  }
  .yt-fp-tag-line {
    margin-bottom: 34px;
    margin-top: 26px;
  }
  .yt-fp-tag-line-2 {
    margin-top: 0;
    margin-bottom: 30px !important;
  }
  .invalid {
    font-size: 12px;
    line-height: 1.17;
  }
  .invalid-feedback.yt-invalid-minimum-pass {
    margin: 0;
    top: 108%;
  }
  .yt-fp-form {
    margin-bottom: 20px !important;
  }
  .invalid-feedback {
    bottom: -17px;
  }
  .yt-fp-form .yt-new-pass-form-wrap .form-group.yt-form-err {
    margin-bottom: 36px !important;
  }
  .yt-new-pass-form-wrap .yt-fp-btn {
    margin-top: 35px !important;
  }
  .yt-fp-form .yt-new-pass-form-wrap .yt-form-confirm-err.form-group {
    margin-bottom: 50px !important;
  }
  form.yt-new-pass-form-wrap span.yt-etpass-msg {
    bottom: 68px;
  }
  form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group .invalid-feedback {
    bottom: -35px;
  }
  form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group + span {
    bottom: 83px !important;
  }
}

/*
* Global CSS of Help Center
*/
.help-center-list-content {
  font-size: 16px !important;
  font-weight: 100 !important;
  line-height: 1.5 !important;
  text-align: left !important;
  color: var(--color-black) !important;
}
/*
* Breadcrumbs CSS
*/
.hcb-breadcrumbs {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.bb-desktop-view {
  /* display: block;  */
}
.hcb-breadcrumbs .hcb-home {
  color: var(--color-black) !important;
}
.hcb-breadcrumbs .hcb-home:hover {
  color: var(--color-sky-blue) !important;
  font-weight: bold !important;
}

.bb-mobile-view {
  display: none !important;
}

.hcb-inner-wrap {
  padding: 40px 40px 36px;
}
.hcb-content-wrap {
  padding: 40px 40px 36px;
}

.hcb-inner-contnet .hcb-tabs-name li:first-child {
  padding-top: 0;
}
.hcb-inner-contnet .hcb-tabs-name li:last-child {
  padding-bottom: 0;
}
.hcb-current {
  color: var(--color-black) !important;
  font-size: 12px;
  font-weight: bold !important;
  line-height: 1.17;
}
.hcb-beckfrom-ct-page {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.13;
}
.hcb-mb-30 {
  margin-bottom: 30px;
}
.hc-mt-30 {
  margin-bottom: 30px;
}
.hc-mb-50 {
  margin-bottom: 50px;
}
.radius-10 {
  border-radius: 10px;
}
.list-style-none {
  list-style: none;
}
.hcp-back-arrow {
  cursor: pointer;
}
.hcp-back-arrow-inner {
  cursor: pointer;
}
/*
* Left Col CSS
*/
.hcb-inner-contnet .hcb-tabs-name li {
  padding: 10.5px 0;
  border-bottom: 1px solid #9e9797;
  font-size: 15px !important;
  line-height: 1.15;
  color: var(--color-black);
  cursor: pointer;
}
.hcb-inner-contnet .hcb-tabs-name li:last-child {
  border: none;
  padding-bottom: 0;
}
.hcb-inner-contnet .hcb-tabs-name li.active {
  font-weight: 500 !important;
  color: #e55240 !important;
}

/*
* Right Col CSS
*/

.hc-tab-main-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 40px;
}
.hc-tab-sub-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
  margin-bottom: 10px;
}
.ht-tab-description {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.hcb-tab-content {
  padding: 40px 40px 23px 30px;
}
.yt-cm-lt-col {
  padding-right: 7px;
}
.yt-cm-rt-col {
  padding-left: 7px;
}

.bb-mb-pg-ttl {
  display: none;
}

@media (max-width: 768px) {
  .hcp-back-arrow {
    display: none !important;
  }
  .bb-mobile-view {
    display: block !important;
  }
  .bb-desktop-view {
    display: none !important;
  }

  .bb-cm-rt-col {
    /* display: none !important; */
  }
  .bb-mb-pg-ttl {
    display: block;
  }
}

.radius-10 {
  border-radius: 10px;
}
.trans-fl-pg-mb-30 {
  margin-bottom: 30px;
}
.trans-fl-pg-mb-80 {
  margin-bottom: 80px;
}
.trans-fl-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-orange) !;
}
.trans-fl-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey) !important;
}
.trans-fl-btn {
  opacity: 0.99;
  border-radius: 6px;
  /* background-color: var(--color-dark-black); */
  background-color: var(--color-ButtonBackRegular) !important;
  /* border-color: var(--color-dark-black) !important; */
  border-color: none !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  /* color: #ffffff; */
  color: var(--color-ButtonTextRegular) !important;
}
.trans-fl-btn:hover {
  background-color: var(--color-ButtonBackHover) !important;
  color: var(--color-ButtonTextHover) !important;
}
.trans-fl-cancel-btn {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
  box-shadow: none !important;
}
.yt-transaction-cl-icn {
  max-height: 135px;
  width: auto;
}
.trans-fl-pg-inner-wrap {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1280px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 60px !important;
    margin-bottom: 60px;
  }
}
@media (min-width: 1900px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 80px !important;
    margin-bottom: 80px;
  }
}
/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .trans-fl-wrap {
    margin-top: 75px;
    margin-bottom: 40px;
  }
}
/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 15px !important;
    margin-bottom: 15px;
    padding: 11px 20px 20px !important;
  }
  .trans-fl-btn {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
  }
  .cart-pg-empty-main-wrap {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .trans-fl-cancel-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .trans-fl-ttl {
    font-size: 16px;
    line-height: 1.13;
  }
  .trans-fl-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .trans-fl-wrap {
    margin-bottom: 22px !important;
    margin-top: 20px !important;
  }
}

.contentcontainer {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}

.item {
  padding-bottom: 30px;
}
.item-details > p {
  margin-bottom: 0px!important;
}
.item-details {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}

.cardcontainer {
  margin-top: 40px;
}

.border-radius-10 {
  border-radius: 10px!important;
}
.yt-main-wrapper {
  padding: 30px;
  border: 1px solid #e6e6e6;
}
.yt-main-wrapper .item-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
  margin-bottom: 20px;
}



.yt-inner-wrap .yt-items.item:first-child {
  padding-top: 0;
}

@media (min-width: 1900px) {
  .yt-main-wrapper {
    padding: 30px;
  }
}
@media (max-width: 1280px) {
  .yt-main-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 768px) {
  .yt-cm-mobile-bread {
    display: none;
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .yt-main-wrapper {
    margin: 0px 0px;
    /* padding: 20px 20px; */
  }
  .yt-main-wrapper .item-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    letter-spacing: normal;
    margin-bottom: 11px;
  }
  .item-details {
   
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
  }
  .lastparagraph{
    margin-bottom:0px!important;
  }
  .yt-inner-wrap .yt-items.item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width:1280px) and (max-width:1365px){
  .yt-inner-wrap .yt-items.item {
    padding-top: 0px!important;
  }
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
  .pageroute {
    color: gray;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1.17;
  }
  .yt-profile-mb-ttl.profile-pg-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-cm-mobile-bread {
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .yt-main-wrapper {
    /* margin: 10px 0 10px; */
    padding: 20px;
  }
  .yt-main-wrapper .item-title {
    font-size: 16px;
    line-height: 1.13;
  }
  .item-details {
    line-height: 1.92;
    letter-spacing: 0.12px;
    font-size: 12px;
  }
  .yt-inner-wrap .yt-items.item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}


@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-inner-wrap .yt-items.item {
    padding:30px
  }
  
}

.border-radius-10 {
    border-radius: 10px;
  }
  
  .yt-inner-content {
    padding: 30px;
    border: 1px solid #e6e6e6;
  }
  
  .yt-inner-wrap {
    position: relative;
  }
  
  .yt-section-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
    /* margin-bottom: 30px; */
    padding:15px;
  }
  .yt-review-main-wrapper {
    /* padding-top: 64px;
    padding-bottom: 15px; */
  }
  .yt-review-item .profile-image {
    border-radius: 100%;
  }
  
  .yt-review-item .item-name {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    color: var(--color-black);
    padding: 20px 0;
  }
  
  .yt-review-item {
    margin-bottom: 30px;
  }
  
  .yt-review-item .text-content-block {
    padding: 0px 0px 30px;
    margin: 0px 22px;
    border-bottom: 2px solid rgb(206, 222, 253);
    width: 100%;
  }
  
  .yt-review-item .item-comment {
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.18px;
    color: var(--color-dark-grey);
  }
  
  .yt-about-us-pg .yt-main-wrapper {
    /* margin-bottom: 160px; */
    margin-bottom: 5%;
  }
  
  .yt-review-btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--button-text-primary);
    border-radius: 5px;
    background-color: var(--button-primary);
    border-color: var(--button-primary);
    padding: 15px 42px;
    position: absolute;
    bottom: -77px;
    right: 0;
  }
  
  @media (min-width: 1900px) {
    .yt-inner-wrap {
      /* padding-top: 80px; */
    }
  }
  
  @media (max-width: 1280px) {
    .yt-inner-wrap {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  /*
  * Screen for under 1280 Resolution 
  */
  
  @media (max-width: 1279.92px) {
    .yt-inner-wrap {
      padding: 0;
      margin: 0;
    }
    .yt-inner-content {
      padding: 20px;
    }
    .yt-review-item .profile-image {
      max-width: 40px;
      height: 40px;
    }
    .yt-section-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.11;
      padding:15px;
    }
    .yt-review-item .item-name {
      padding: 9px 0 13px;
      font-size: 16px;
      line-height: 1.13;
    }
    .yt-review-item .item-comment {
      font-size: 12px;
      line-height: 1.92;
      letter-spacing: 0.12px;
    }
    .yt-main-wrapper {
      margin-bottom: 120px;
    }
  }
  /*
  * Screen for under 768 Resolution 
  */
  
  @media (max-width: 767.92px) {
    .yt-inner-wrap {
      margin: 0;
      padding: 0;
    }
    .yt-section-title {
      margin-top: -13px;
      font-size: 18px;
      line-height: 1.11;
    }
    .yt-inner-content {
      padding: 15px 15px;
    }
    .yt-review-item .profile-image {
      max-width: 25px;
      height: 25px;
    }
    .yt-review-item .text-content-block {
      margin: 0 8px;
      padding-bottom: 10px;
    }
    .yt-review-item .item-name {
      padding: 5px 0;
      font-size: 14px;
      line-height: 1.14;
    }
    .yt-review-item .item-comment {
      line-height: 1.92;
      letter-spacing: 0.12px;
      font-size: 12px;
      margin-top: 10px;
    }
    .yt-review-btn {
      font-size: 16px;
      line-height: 1.13;
      letter-spacing: 0.32px;
      background-color: transparent;
      color: var(--color-black);
      padding: 0 !important;
      border: none;
      bottom: -27px;
    }
    .yt-main-wrapper {
      margin-bottom: 60px;
      padding-top: 15px!important;
      padding-bottom: 15px!important; 
    }
    .yt-review-main-wrapper {
      margin-bottom: 30px !important;
      padding-top: 5px!important;
      padding-bottom: 5px!important;
    }
  }
  @media (min-width:768px) and (max-width:1365px){
    .yt-review-main-wrapper {
      /* margin-bottom: 80px !important; */
      /* padding-top: 15px!important;
      padding-bottom: 15px!important; */
    }
  }
  @media (min-width: 1366px) and (max-width: 1900px) {
    .yt-section-title {
      font-size: 26px;
      padding:15px;
    }
    .yt-review-item .item-comment {
      font-size: 16px;
    }
    .yt-review-main-wrapper {
      /* padding-top: 15px!important;
      padding-bottom: 15px!important; */
  
    }
  }
  
/*
* Global CSS of Help Center
*/
.p-40 {
  padding: 40px;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.profile-pg-inner-wrap {
  border: 1px solid #e6e6e6;
}
.no-order-btn,
.no-wishlist {
  /* color: #fff !important; */
  /* background: var(--button-primary) !important; */
  color: var(--color-ButtonTextRegular);
  background-color: var(--color-ButtonBackRegular);
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: "GTWalsheimPro-Medium-" !important;
  line-height: 1.11 !important;
}
.no-order-btn:hover,
.no-wishlist:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

/*
* Breadcrumbs CSS
*/
.yt-mb-80 {
  margin-bottom: 80px;
}
.profile-pg-breadcrumbs {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.profile-pg-home {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  font-family: "GTWalsheimPro-Medium";
}
.profile-pg-home:hover {
  color: var(--color-sky-blue) !important;
  font-weight: 500 !important;
}
.profile-pg-current {
  color: var(--color-dark-grey) !important;
  font-size: 12px;
  font-weight: bold !important;
  line-height: 1.17;
  font-family: "GTWalsheimPro-Medium";
}
.profile-pg-beckfrom-ct-page {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.profile-pg-mb-10 {
  margin-bottom: 10px;
}
.profile-pg-mb-30 {
  margin-bottom: 30px;
}
.profile-pg-mt-30 {
  margin-bottom: 30px;
}
.profile-pg-mb-50 {
  margin-bottom: 50px;
}
.radius-10 {
  border-radius: 10px;
}
.list-style-none {
  list-style: none;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li:first-child {
  padding-top: 0;
}
.profile-pg-address-list {
  border: 1px solid #e6e6e6;
  padding: 40px;
  position: relative;
}
/*
* Left Col CSS
*/
.profile-pg-inner-contnet .profile-pg-tabs-name li {
  padding: 12.5px 0;
  border-bottom: 2px solid #cae2fe;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-black);
  cursor: pointer;
  position: relative;
  font-weight: 500;
}
.profile-pg-inner-contnet .pg-profile-box li:first-child.active::after {
  opacity: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
  content: "";
  height: 75%;
  width: 3px;
  background: var(--color-primary);
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
}
.profile-pg-inner-contnet .profile-pg-tabs-name li:last-child {
  border: none;
  padding-bottom: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active {
  font-weight: 500;
  color: var(--color-primary);
}

/*
* Right Col CSS
*/
.order-data-scroll {
  max-height: 145vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.order-data-scroll::-webkit-scrollbar {
  width: 4px;
}
.order-data-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 2px;
}
.order-data-scroll::-webkit-scrollbar-thumb {
  background: #888;
}
.order-data-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.loadMoreBtn {
  text-align: center !important;
  margin-top: 20px;
}

.loadMoreBtn .btn-outline-secondary:hover {
  color: #fff;
  background-color: #3e454f;
  border: 1px solid #3e454f;
}
.profile-pg-tab-main-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-pg-tab-sub-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
}
.ht-tab-description {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}

/*
*/
.img-upload svg {
  font-size: 34px;
  color: var(--color-black);
}
.img-upload {
  width: 109px;
  height: 109px;
  background-color: #f6f8fa;
  border-radius: 6px;
}
.profile-name {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-email {
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-grey);
}
.profile-item-wrap {
  position: relative;
}
.profile-notiy {
  position: absolute;
  background-color: var(--color-primary);
  font-size: 13px;
  line-height: 1.15;
  color: var(--color-white);
  padding: 5px;
  border-radius: 50px;
  top: -12px;
  left: 30px;
  width: 20px;
  text-align: center;
  height: 23px;
}
.notifications-notiy {
  position: absolute;
  background-color: var(--color-primary);
  font-size: 13px;
  line-height: 1.15;
  color: var(--color-white);
  padding: 5px;
  border-radius: 50px;
  top: -9.5px;
  left: 30px;
  width: 20px;
  text-align: center;
  height: 23px;
}
.profile-pg-title {
  font-size: 30px !important;
  font-weight: 500 !important;
  line-height: 1.13 !important;
  color: var(--color-black) !important;
}
.profile-data-tag {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-user-name {
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-black);
  border-bottom: 2px solid #f6f8fa;
}
.profile-edit-btn {
  border-radius: 6px;
  border-color: var(--color-ButtonBackRegular);
  background-color: var(--color-ButtonBackRegular);
  color: var(--color-ButtonTextRegular);
  width: 100%;
  max-width: 180px;
  font-size: 18px !important;
  line-height: 1.11 !important;
  font-weight: bold !important;
  font-family: "GTWalsheimPro-Medium-" !important;
}

.profile-edit-btn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.profile-edit-pass {
  /* color: var(--color-primary); */
  color: var(--color-ButtonTextRegular);
}

.profile-edit-pass,
.profile-edit-btn {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  padding: 15px 15px;
}

.yt-cm-lt-col {
  padding-right: 7px;
  /* max-width: 480px; */
}
.yt-cm-rt-col {
  padding-left: 7px;
}
@media (min-width: 768px) {
  .yt-profile-mb-ttl {
    display: none;
  }
}
@media (min-width: 1280.88px) {
  .yt-cm-lt-col {
    padding-right: 10px;
  }
  .yt-cm-rt-col {
    padding-left: 10px;
  }
}
@media (min-width: 1900px) {
  .yt-cm-lt-col {
    padding-right: 20px;
  }
  .yt-cm-rt-col {
    padding-left: 20px;
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
    right: -30px !important;
  }
  .pg-profile-box .img-upload {
    width: 80px;
    height: 80px;
  }
  .yt-cm-lt-col {
    padding-right: 15px;
  }
  .yt-cm-rt-col {
    padding-left: 15px;
  }
  .yt-cm-ptc-row.yt-btm-inf.row {
    margin-top: 30px;
  }
  .yt-cm-rt-col .profile-pg-inner-wrap {
    padding: 40px 30px 35px !important;
  }
  .profile-name {
    font-size: 21px;
    line-height: 1.14;
  }
  .profile-email {
    line-height: 1.13;
    font-size: 16px;
  }
  .profile-pg-title {
    font-size: 26px;
    line-height: 1.12;
  }
  .profile-pg-inner-wrap.profile-inner-tab-wrap {
    padding: 20px;
  }
  .profile-pg-inner-wrap.profile-inner-tab-content {
    padding: 30px;
  }
  .profile-item-icn {
    max-width: 35px;
  }
  .profile-notiy {
    font-size: 12px;
    line-height: 1.17;
    padding: 3px 0px;
    border-radius: 50px;
    width: 20px;
    text-align: center;
    left: 20px;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li {
    font-weight: normal;
  }
  .profile-edit-pass,
  .profile-edit-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-pg-breadcrumbs {
    margin-bottom: 30px;
  }
  .profile-pg-address-list {
    margin-bottom: 20px !important;
    padding: 30px;
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .profile-item-wrap .profile-item-name {
    font-size: 14px;
    line-height: 1.14;
  }
  .profile-item-wrap .profile-item-icn {
    max-width: 46px;
    margin-right: 17px !important;
  }
  .profile-pg-inner-wrap,
  .tab-content .profile-pg-inner-wrap {
    padding: 15px !important;
  }
  .profile-inner-tab-content {
    padding: 18px 15px 15px !important;
  }
  .profile-notiy {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-cm-lt-col {
    max-width: 250px;
    /* box-sizing: content-box; */
  }
  .img-upload {
    width: 43px;
    height: 43px;
  }
  .yt-profile-img-nm-wrap .img-upload {
    width: 90px;
    height: 90px;
  }
  .yt-profile-img-nm-wrap {
    margin-bottom: 20px !important;
  }
  .img-upload svg {
    font-size: 16px;
  }
  .profile-data-wrap {
    max-width: 330px;
  }
  .profile-data-tag {
    font-size: 12px;
    line-height: 1.17;
  }

  .profile-edit-btn {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: var(--button-primary);
    color: var(--button-text-primary);
    border-color: var(--button-primary);
  }
  .profile-edit-pass,
  .profile-edit-btn {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
  }
  .profile-name {
    font-size: 20px;
    line-height: 1.15;
  }
  .profile-email {
    font-size: 12px;
    line-height: 1.17;
    max-width: 75%;
    overflow-x: auto;
  }
  .user-profileinfo {
    margin-left: 12px !important;
  }
  .profile-pg-inner-wrap.profile-inner-tab-wrap {
    margin-bottom: 10px;
  }
  .profile-pg-title {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 14px !important;
  }
  .yt-cm-ptc-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .profile-pg-breadcrumbs {
    margin-bottom: 58px;
  }
  .yt-profile-img-nm-wrap .img-upload svg {
    font-size: 34px;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
    right: -15px;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 10px;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767px) {
  .profile-pg-title {
    margin-bottom: 10px !important;
  }
  .user-review {
    width: 100%;
    margin-bottom: 30px;
  }
  .img-upload svg {
    font-size: 34px;
  }
  .profile-pg-title {
    font-size: 18px;
    line-height: 1.11;
    padding-left: 8px;
  }
  .profile-pg-breadcrumbs {
    margin-bottom: 10px;
    margin-top: 10px !important;
  }
  .profile-pg-inner-wrap,
  .tab-content .profile-pg-inner-wrap {
    padding: 20px !important;
  }

  .yt-profile-mb-ttl.profile-pg-title {
    display: block;
  }
  .yt-cm-row {
    display: block;
  }
  .yt-cm-row .yt-cm-rt-col,
  .yt-cm-row .yt-cm-lt-col {
    width: 100%;
    max-width: 100%;
  }
  .yt-cm-row.row .yt-cm-rt-col,
  .yt-cm-row.row .yt-cm-lt-col {
    padding-right: 6px;
    padding-left: 6px;
  }

  .profile-edit-pass {
    padding: 0;
    margin-right: auto !important;
  }
  .profile-edit-btn {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: var(--button-primary);
    color: var(--button-text-primary);
    border-color: var(--button-primary);
  }
  .profile-edit-pass,
  .profile-edit-btn {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
  }
  .profile-user-name {
    font-size: 18px !important;
    line-height: 1.11;
    margin-bottom: 24px;
    font-family: "GTWalsheimPro-Regular-" !important;
    color: var(--color-black) !important;
  }
  .profile-data-tag {
    font-size: 13px;
    line-height: 1.15;
  }
  .yt-profile-img-nm-wrap {
    margin-bottom: 24px !important;
  }
  .yt-cm-row .bb-cm-lt-col {
    display: none;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.98px) {
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 14px;
  }
}

.log-out-title-bar .modal-title {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.log-out-body-text {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.log-out-title-bar span {
  /* position: absolute; */
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black) !important;
}
.pp-log-out-btn-modal {
  width: 48%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent !important;
  border-color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-log-out-btn-modal:hover {
  border-color: #fff;
}
.pp-log-out-btn-light-grey {
  color: var(--color-dark-grey) !important;
}
.pp-log-out-btn-dark-grey {
  color: var(--color-black) !important;
}
.log-out-bottom-bar {
  position: relative;
  border-top: 2px solid #dbdbdb;
}
.yt-form-spacer {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}
.yt-log-body-wrap.modal-body {
  padding: 33px 45px 49px;
}

/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .log-out-title-bar span {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
  }

  .log-out-body-text {
    font-size: 16px;
    line-height: 1.13;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .log-out-title-bar span {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
  }
  .yt-log-body-wrap.modal-body {
    padding: 22px 5px 30px;
  }
  .log-out-body-text {
    font-size: 15px;
    line-height: 1.2;
  }
}

.pp-na-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-na-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  /* background-color: var(--button-primary) !important; */
  background-color: var(--color-ButtonBackRegular) !important;
  /* border-color: var(--button-primary) !important; */
  font-size: 18px !important;
  font-weight: 500;
  line-height: 1.11;
  /* color: var(--button-text-primary) !important; */
  color: var(--color-ButtonTextRegular) !important;
  width: 100%;
  max-width: 200px;
  border: none !important;
}

.pp-no-addr-btn:hover {
  background-color: var(--color-ButtonBackHover) !important;
  color: var(--color-ButtonTextHover) !important;
}

.pp-sa-list-none {
  list-style: none;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  padding-left: 32px;
}

.profile-pg-inner-no-add img {
  max-height: 217px;
}
.profile-pg-address-list {
  border: 1px solid #e6e6e6;
}
.profile-pg-inner-wrap {
  border: 1px solid #e6e6e6;
}
.pp-sa-action-wrap {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.profile-pg-address-list {
  padding: 40px;
  position: relative;
}
.profile-pg-sa-no-address-main-wrap {
  padding: 165px 0;
}
.pp-sa-type {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.pp-sa-address {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.pp-sa-address,
.pp-no-addr-btn {
  font-weight: bold;
}
.pp-sa-action-wrap .btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  /* color: darkgray; */
  color: var(--color-primary);
}
.pp-sa-action-wrap .pp-add-delete-btn {
  color: var(--color-orange);
}
.radius-10 {
  border-radius: 10px;
}
.profile-pg-mb-30 {
  margin-bottom: 30px;
}
.pp-sa-all-addres-list li {
  cursor: pointer;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  max-width: 450px;
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .pp-sa-address,
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 45px 0;
  }
  .profile-pg-inner-no-add img {
    max-height: 185px;
    margin-bottom: 30px !important;
  }
  .pp-sa-img-wrap img {
    max-width: 50px;
    height: auto;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .profile-pg-address-list {
    margin-bottom: 20px !important;
    padding: 30px;
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .profile-pg-sa-no-address-main-wrap {
    padding: 39px 0;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 39px !important;
  }
  .pp-na-ttl {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 40px !important;
  }
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 10px;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    padding-left: 14px;
    margin-top: 7px;
    padding-right: 125px;
  }
  .pp-sa-type {
    font-size: 16px;
    line-height: 1.19;
  }
  .pp-sa-address {
    line-height: 1.36;
    font-size: 14px;
  }
  .pp-sa-img-wrap {
    max-width: 80%;
  }
  .pp-sa-action-wrap .btn {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.98px) {
  .pp-sa-info-wrap {
    margin: 20px 0;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 18px 0 5px;
  }
  .pp-na-ttl {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 34px !important;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .pp-no-addr-btn {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 14px;
  }
  .pp-sa-action-wrap {
    position: initial !important;
    transform: none !important;
    width: 100%;
  }
  .pp-sa-action-wrap .pp-sa-delet img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .pp-sa-action-wrap .pp-sa-edit,
  .pp-sa-action-wrap .pp-sa-delet {
    width: 50%;
    text-align: center !important;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    margin: 0 0 16px !important;
    padding: 0 20px 0 15px;
  }
  .pp-sa-action-wrap {
    padding-top: 10px;
    border-top: solid 1px #f2f6f8;
    margin-top: 16px;
  }
  .pp-sa-type {
    margin-bottom: 5px;
    /* font-size: 15px; */
    font-size: 12.5px;
    line-height: 1.2;
  }
  .pp-sa-address {
    font-size: 15px;
    line-height: 1.2;
  }
  .pp-sa-action-wrap .btn {
    font-size: 15px;
    line-height: 1.2;
  }
}

.btnBack {
  border-radius: 6px;
  background-color: #324688 !important;
  font-family: "GTWalsheimPro-Medium-";
  font-size: 18px !important;
  filter: blur(17.4px);
  font-weight: 500 !important;
  text-align: center;
  line-height: 1.11;
  color: #ffffff;
}
.profile-div {
  border-radius: 10px;
  background-color: #ffffff;
}
.breadcrumb > li + li:before {
  content: "\3E"; /* hex code for > */
}
.main-menu-row {
  display: block;
}
.main-menu-inner-wrap {
  border: 1px solid #e6e6e6;
}
.divider {
  border: solid 1px #e2e2e2;
  margin-left: 10;
}
.menu-listing-data {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  /* align-items: center; */
  /* padding: 5px; */
  border-radius: 10px;
  width: "350px" !important;
  border: 1px soild #ffffff !important;
  background-color: #ffffff;
}
.menu-img {
  -o-object-fit: "contain";
     object-fit: "contain";
  width: "5%" !important;
  height: "15%";
  margin-right: 8px;
}
.title-text {
  font-family: "GTWalsheimPro-Medium-";
  font-size: 18px !important;
  color: #3e454f !important;
}
.profile-data {
  border: 1px soild black;
  border-radius: 10px;
  background-color: #ffffff;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 350px;
  margin-left: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
.profile-name {
  font-family: "GTWalsheimPro-Medium-";
  font-size: 30px !important;
  font-weight: 500 !important;
  color: #3e454f !important;
  line-height: 1.13 !important;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.profile-emailText {
  font-family: "GTWalsheimPro-Regular-";
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
  text-align: left;
  color: #8b8f95 !important;
}
.spanText {
  font-family: "GTWalsheimPro-Regular-";
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13 !important;
  letter-spacing: normal;
  text-align: center;
  color: #8b8f95 !important;
}
.menu-text {
  font-family: "GTWalsheimPro-Medium-";
  /* -webkit-filter: blur(11px);
  filter: blur(11px); */
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center !important;
  color: #3e454f !important;
}
.sidemenu-text {
  font-family: "GTWalsheimPro-Medium";
  font-size: 30px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13 !important;
  letter-spacing: normal;
  text-align: left;
  color: #3e454f !important;
}
.profile-img {
  border-radius: 6px;
  text-align: center;
  width: 270%;
  height: 13vh;
}
.without-profileImg {
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  box-sizing: border-box;
  background: url(/static/media/photo-camera.e295147a.svg) center center no-repeat #e4e4e4;
  border-radius: 20px;
  background-size: 60px 60px;
}
.uploaded-profileimg {
  width: 40%;
  height: 13vh;
  -o-object-fit: contain;
     object-fit: contain;
}
.label-text {
  font-family: "GTWalsheimPro-Regular-";
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14 !important;
  letter-spacing: normal;
  text-align: left;
  color: #8b8f95 !important;
}
.submitBtn {
  border-radius: 6px !important;
  background-color: #324688 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  width: 35%;
  height: 6vh;
}
.btnWithoutborder {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
  text-align: left;
  color: #324688 !important;
  font-family: "GTWalsheimPro-Medium-";
  width: 50%;
  height: 5vh;
}
.profileImg {
  -o-object-fit: contain;
     object-fit: contain;
  width: 85%;
  height: 10vh;
}
.profileImgWIthout {
  height: 10vh;
  -o-object-fit: contain;
     object-fit: contain;
}
.modalTitleInputLable {
  font-family: "GTWalsheimPro-Regular-";
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14 !important;
  letter-spacing: normal;
  text-align: left;
  color: #8b8f95;
}
.saveProfileBtn {
  font-family: "GTWalsheimPro-Bold";
  font-size: 16px;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13 !important;
  letter-spacing: 0.32px;
  text-align: center;
  /* color: #ffffff; */
  color: var(--color-ButtonTextRegular);
  border-radius: 6px !important;
  /* background-color: #324688 !important; */
  background-color: var(--color-ButtonBackRegular);
}

.saveProfileBtn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.removeBtn {
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13 !important;
  letter-spacing: normal;
  text-align: left;
  color: #f67054 !important;
  font-family: "GTWalsheimPro-Medium" !important;
}
.saveBtn {
  font-size: 16px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13 !important;
  letter-spacing: normal;
  text-align: left;
  color: #324688 !important;
  font-family: "GTWalsheimPro-Medium" !important;
}
.content-updatePwd {
  font-family: "GTWalsheimPro-Regular-";
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25 !important;
  letter-spacing: normal;
  text-align: left;
  color: #3e454f !important;
}
.badge-profile {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: red;
}
.saveAddressImg {
  -o-object-fit: contain;
     object-fit: contain;
  width: 25%;
  height: 10vh;
}
.actionsDiv {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 580px;
  top: 40px;
}

/* Modal styles */
.modal-title {
  font-family: "GTWalsheimPro-Medium-" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 1.11 !important;
  color: #3e454f !important;
  text-align: center !important;
}
.modal-content {
  font-family: "GTWalsheimPro-Regular-" !important;
  font-size: 16px !important ;
  color: #8b8f95 !important;
  font-weight: normal !important;
  line-height: 1.13;
  text-align: center !important;
}
.modal-btnCancel {
  font-family: "GTWalsheimPro-Medium";
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #8b8f95 !important;
  text-align: center !important;
  line-height: 1.2;
}
.modal-btnSubmit {
  font-family: "GTWalsheimPro-Medium" !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2 !important;
  letter-spacing: normal;
  text-align: center !important;
  color: #3e454f !important;
}

/* Connected accounts */
.socialAccountTitle {
  font-family: "GTWalsheimPro-Medium-" !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15 !important;
  letter-spacing: normal;
  text-align: left !important;
  color: #3e454f !important;
}
.socialAccountSubTitle {
  font-family: "GTWalsheimPro-Medium-" !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2 !important;
  letter-spacing: normal;
  text-align: left !important;
  color: #8b8f95 !important;
}

/* address */
.addressContent {
  font-family: "GTWalsheimPro-Regular-" !important;
  font-size: 20px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15 !important;
  letter-spacing: normal;
  text-align: left !important;
  color: #8b8f95 !important;
}
.addressTitle {
  font-family: "GTWalsheimPro-Medium-" !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15 !important;
  letter-spacing: normal;
  text-align: left !important;
  color: #3e454f !important;
}
.addressbtn {
  font-family: "GTWalsheimPro-Medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #324688;
}

/* ***************************************************************************************************** */
.p-40 {
  padding: 40px;
}
.profile-pg-mb-30 {
  margin-bottom: 30px;
}
.yt-change-pass-vie-icn {
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
  right: 25px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-change-pass-vie-icn2 {
  position: absolute;
  top: 40.95%;
  transform: translateY(-50%);
  right: 25px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-current-pass-vie-icn2 {
  position: absolute;
  top: 20.95%;
  transform: translateY(-50%);
  right: 25px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-current-pass-vie-icn {
  position: absolute;
  top: 20.5%;
  transform: translateY(-50%);
  right: 25px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-confirm-pass-vie-icn2 {
  position: absolute;
  top: 63.95%;
  transform: translateY(-50%);
  right: 25px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-confirm-pass-vie-icn {
  position: absolute;
  top: 63%;
  transform: translateY(-50%);
  right: 25px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .yt-change-pass-vie-icn {
    top: 47%;
  }
  .yt-change-pass-vie-icn2 {
    top: 45.05%;
  }
  .yt-current-pass-vie-icn {
    top: 24.5%;
  }
  .yt-current-pass-vie-icn2 {
    top: 23%;
  }
  .yt-confirm-pass-vie-icn2 {
    top: 67.05%;
  }
  .yt-confirm-pass-vie-icn {
    top: 68%;
  }
}

.pp-na-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-na-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
  width: 100%;
  max-width: 200px;
}
.pp-sa-list-none {
  list-style: none;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  padding-left: 32px;
}

.profile-pg-inner-no-add img {
  max-height: 217px;
  height: 200px;
}
.profile-pg-address-list {
  border: 1px solid #e6e6e6;
}
.profile-pg-inner-wrap {
  border: 1px solid #e6e6e6;
}
.pp-sa-action-wrap {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.profile-pg-address-list {
  padding: 40px;
  position: relative;
}
.profile-pg-sa-no-address-main-wrap {
  padding: 165px 0;
}
.pp-sa-type {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.pp-sa-address {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.pp-sa-address,
.pp-no-addr-btn {
  font-weight: bold;
}
.pp-sa-action-wrap .btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: darkgray;
}
.pp-sa-action-wrap .pp-add-delete-btn {
  color: var(--color-orange);
}
.radius-10 {
  border-radius: 10px;
}
.profile-pg-mb-30 {
  margin-bottom: 30px;
}
/* .addres.pp-sa-all-addres-list li {
  cursor: pointer;
} */
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  max-width: 450px;
}
.addres.pp-sa-list-none {
  max-height: 140vh;
  overflow: auto;
  width: 100%;
}
.pp-sa-list-none {
  list-style: none;
  max-height: 140vh;
  overflow: auto;
  width: 100%;
}
.pp-sa-list-none::-webkit-scrollbar {
  width: 4px;
}
.pp-sa-list-none::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 2px;
}
.pp-sa-list-none::-webkit-scrollbar-thumb {
  background: #888;
}
.pp-sa-list-none::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .pp-sa-address,
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 45px 0;
  }
  .profile-pg-inner-no-add img {
    max-height: 185px;
    margin-bottom: 30px !important;
  }
  .pp-sa-img-wrap img {
    max-width: 50px;
    height: auto;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .profile-pg-address-list {
    margin-bottom: 20px !important;
    padding: 30px;
  }
}
/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .profile-pg-sa-no-address-main-wrap {
    padding: 39px 0;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 39px !important;
  }
  .pp-na-ttl {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 40px !important;
  }
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 10px;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    padding-left: 14px;
    margin-top: 7px;
    padding-right: 125px;
  }
  .pp-sa-type {
    font-size: 16px;
    line-height: 1.19;
  }
  .pp-sa-address {
    line-height: 1.36;
    font-size: 14px;
  }
  .pp-sa-img-wrap {
    max-width: 80%;
  }
  .pp-sa-action-wrap .btn {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.98px) {
  .pp-sa-info-wrap {
    margin: 20px 0;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 18px 0 5px;
  }
  .pp-na-ttl {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 34px !important;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .pp-no-addr-btn {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 14px;
  }
  .pp-sa-action-wrap {
    position: initial !important;
    transform: none !important;
    width: 100%;
  }
  .pp-sa-action-wrap .pp-sa-delet img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .pp-sa-action-wrap .pp-sa-edit,
  .pp-sa-action-wrap .pp-sa-delet {
    width: 50%;
    text-align: center !important;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    margin: 0 0 16px !important;
    padding: 0 20px 0 15px;
  }
  .pp-sa-action-wrap {
    padding-top: 10px;
    border-top: solid 1px #f2f6f8;
    margin-top: 16px;
  }
  .pp-sa-type {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.2;
  }
  .pp-sa-address {
    font-size: 15px;
    line-height: 1.2;
  }
  .pp-sa-action-wrap .btn {
    font-size: 15px;
    line-height: 1.2;
  }
}

.delete-addr-title-bar .modal-title {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
  }
  .delete-addr-body-text {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey);
  }
  .delete-addr-title-bar span {
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    top: 27px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--color-black);
  }
  .delete-addr-bottom-bar {
    border-top: 2px solid #dbdbdb !important;
    position: relative;
  }
  .yt-form-spacer {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    display: block;
    bottom: 10px;
    width: 2px;
    top: 10px;
    background: #dbdbdb;
  }
  .pp-delete-addr-btn-modal {
    width: 48%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent !important;
    border-color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
  }
  .pp-delete-addr-btn-modal:hover {
    border-color: #fff !important;
  }
  .pp-delete-addr-btn-light-grey {
    color: var(--color-dark-grey) !important;
  }
  .pp-delete-addr-btn-dark-grey {
    color: var(--color-black) !important;
  }
  .pp-add-edit-btn {
    box-shadow: none !important;
  }
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.92px) {
    .delete-addr-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .delete-addr-body-text {
      font-size: 16px;
      line-height: 1.13;
    }
  }
  
  /*
  * Screen for under 1280 Resolution 
  */
  @media (max-width: 1279.92px) {
    .delete-addr-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .delete-addr-body-text {
      font-size: 15px;
      line-height: 1.2;
      padding-top: 0 !important;
    }
  }
  
.remove-wh-lst-title-bar .modal-title {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
  }
  .remove-wh-lst-body-text {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: var(--color-orange);
  }
  .remove-wh-lst-title-bar1 span {
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    line-height: 1.11;
    color: var(--color-black);
    font-size: 18px;
    font-weight: 500;
  }
  .pp-remove-wh-lst-btn-modal {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent;
    border-color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
  }
  .pp-remove-wh-lst-btn-modal:hover {
    border-color: #fff;
  }
  .pp-remove-wh-lst-btn-light-grey {
    color: var(--color-dark-grey);
  }
  .pp-remove-wh-lst-btn-dark-grey {
    color: var(--color-black);
  }
  
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.92px) {
    .remove-wh-lst-body-text {
      font-size: 15px;
      line-height: 1.2;
      padding: 0 !important;
    }
  }
  
/*
* Add Address Modal CSs
*/
.form-group {
  margin-bottom: 1rem;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.edit-profile-title-bar span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.edit-profile-title-bar {
  position: relative;
}
.edit-profile-body-wrap .form-control {
  height: auto;
  border: none;
  border-bottom: 2px solid #f6f8fa;
  box-shadow: none;
  padding-left: 0;
}
.edit-profile-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
/* Chrome, Safari, Edge, Opera */
.edit-profile-body-wrap input::-webkit-outer-spin-button,
.edit-profile-body-wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.edit-profile-body-wrap .form-control input[type="number"] {
  -moz-appearance: textfield;
}
.edit-profile-body-wrap .profile-form-tag {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}

.profile-form-field {
  position: relative;
  margin-bottom: 20px;
}
.profile-form-field .form-control {
  border: none;
  border-bottom: 2px solid #f6f8fa;
  padding-left: 0;
  box-shadow: none;
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-black);
  height: auto;
}
.profile-form-field .profile-form-tag {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-form-field.fields-active .profile-form-tag {
  top: 0;
  transform: none;
}
.img-upload svg {
  font-size: 34px;
  color: var(--color-black);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.yt-remove-pic-wrap {
  cursor: pointer;
  color: #f67054;
}
.img-upload {
  width: 109px;
  height: 109px;
  background-color: #f6f8fa;
  border-radius: 6px;
  position: relative;
}

.image-overlay {
  height: 100%;
  width: 100%;
  /* background-color: black; */
  position: absolute;
  display: inline-block;
  /* opacity: 0.05; */
  border-radius: 6px;
}

.image-overlay:hover {
  opacity: 0.6;
}

.profile-edit-profile-btn {
  border-radius: 6px;
  background-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
  border-color: var(--button-primary);
}
.yt-edit-prfl-body {
  padding: 40px 40px 30px;
}
/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .yt-remove-pic-wrap {
    font-size: 13px;
    line-height: 1.15;
  }
  .yt-remove-pic-wrap img {
    max-width: 16px;
  }
  .profile-form-field .form-control {
    padding: 10px 0 !important;
  }
  .profile-edit-profile-btn {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
  .yt-edit-prfl-body {
    padding: 40px 40px 20px;
  }
}
/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .yt-remove-pic-wrap {
    display: none;
  }
  .edit-profile-body-wrap .img-upload {
    margin: 0 auto;
    width: 122px;
    height: 122px;
  }
  .profile-form-field .profile-form-tag {
    font-size: 13px;
    line-height: 1.15;
  }
  .profile-form-field .form-control {
    font-size: 17px;
    line-height: 1.12;
    padding-top: 11px !important;
    padding-bottom: 6px !important;
  }
  .yt-edit-prfl-body {
    padding: 20px 15px;
  }
  .profile-edit-profile-btn {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .profile-edit-pass,
  .profile-edit-btn {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .profile-form-field .form-control {
    padding-top: 16px !important;
    padding-bottom: 6px !important;
  }
  .profile-form-field {
    margin-bottom: 30px;
  }
  .yt-edit-profl-img-wrap {
    margin-bottom: 30px !important;
  }
  .edit-profile-body-wrap .row.form-group {
    margin-bottom: 0;
  }
}

/*
* Global CSS of Help Center
*/
.p-40 {
    padding: 40px;
  }
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  .profile-pg-inner-wrap {
    border: 1px solid #e6e6e6;
  }
  .no-order-btn,
  .no-wishlist {
    /* color: #fff !important; */
    /* background: var(--button-primary) !important; */
    color: var(--color-ButtonTextRegular);
    background-color: var(--color-ButtonBackRegular);
    font-size: 18px !important;
    font-weight: bold !important;
    font-family: "GTWalsheimPro-Medium-" !important;
    line-height: 1.11 !important;
  }
  .no-order-btn:hover,
  .no-wishlist:hover {
    background-color: var(--color-ButtonBackHover);
    color: var(--color-ButtonTextHover);
  }
  
  /*
  * Breadcrumbs CSS
  */
  .yt-mb-80 {
    margin-bottom: 80px;
  }
  .profile-pg-breadcrumbs {
    color: var(--color-dark-grey);
    font-size: 12px;
    line-height: 1.17;
  }
  .profile-pg-home {
    color: var(--color-dark-grey);
    font-size: 12px;
    line-height: 1.17;
    font-family: "GTWalsheimPro-Medium";
  }
  .profile-pg-home:hover {
    color: var(--color-sky-blue) !important;
    font-weight: 500 !important;
  }
  .profile-pg-current {
    color: var(--color-dark-grey) !important;
    font-size: 12px;
    font-weight: bold !important;
    line-height: 1.17;
    font-family: "GTWalsheimPro-Medium";
  }
  .profile-pg-beckfrom-ct-page {
    margin-top: 50px;
    color: var(--color-black);
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
  }
  .profile-pg-mb-10 {
    margin-bottom: 10px;
  }
  .profile-pg-mb-30 {
    margin-bottom: 30px;
  }
  .profile-pg-mt-30 {
    margin-bottom: 30px;
  }
  .profile-pg-mb-50 {
    margin-bottom: 50px;
  }
  .radius-10 {
    border-radius: 10px;
  }
  .list-style-none {
    list-style: none;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li:first-child {
    padding-top: 0;
  }
  .profile-pg-address-list {
    border: 1px solid #e6e6e6;
    padding: 40px;
    position: relative;
  }
  /*
  * Left Col CSS
  */
  .profile-pg-inner-contnet .profile-pg-tabs-name li {
    padding: 12.5px 0;
    border-bottom: 2px solid #cae2fe;
    font-size: 18px;
    line-height: 1.11;
    color: var(--color-black);
    cursor: pointer;
    position: relative;
    font-weight: 500;
  }
  .profile-pg-inner-contnet .pg-profile-box li:first-child.active::after {
    opacity: 0;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
    content: "";
    height: 75%;
    width: 3px;
    background: var(--color-primary);
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li:last-child {
    border: none;
    padding-bottom: 0;
  }
  .profile-pg-inner-contnet .profile-pg-tabs-name li.active {
    font-weight: 500;
    color: var(--color-primary);
  }
  
  /*
  * Right Col CSS
  */
  .order-data-scroll {
    max-height: 145vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .order-data-scroll::-webkit-scrollbar {
    width: 4px;
  }
  .order-data-scroll::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    width: 2px;
  }
  .order-data-scroll::-webkit-scrollbar-thumb {
    background: #888;
  }
  .order-data-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .loadMoreBtn {
    text-align: center !important;
    margin-top: 20px;
  }
  
  .loadMoreBtn .btn-outline-secondary:hover {
    color: #fff;
    background-color: #3e454f;
    border: 1px solid #3e454f;
  }
  .profile-pg-tab-main-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  .profile-pg-tab-sub-title {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-black);
    line-height: 1.15;
  }
  .ht-tab-description {
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.18px;
    color: var(--color-dark-grey);
  }
  
  /*
  */
  .img-upload svg {
    font-size: 34px;
    color: var(--color-black);
  }
  .img-upload {
    width: 109px;
    height: 109px;
    background-color: #f6f8fa;
    border-radius: 6px;
  }
  .profile-name {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  .profile-email {
    font-size: 18px;
    line-height: 1.11;
    color: var(--color-dark-grey);
  }
  .profile-item-wrap {
    position: relative;
  }
  .profile-notiy {
    position: absolute;
    background-color: var(--color-primary);
    font-size: 13px;
    line-height: 1.15;
    color: var(--color-white);
    padding: 5px;
    border-radius: 50px;
    top: -12px;
    left: 30px;
    width: 20px;
    text-align: center;
    height: 23px;
  }
  .notifications-notiy {
    position: absolute;
    background-color: var(--color-primary);
    font-size: 13px;
    line-height: 1.15;
    color: var(--color-white);
    padding: 5px;
    border-radius: 50px;
    top: -9.5px;
    left: 30px;
    width: 20px;
    text-align: center;
    height: 23px;
  }
  .profile-pg-title {
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 1.13 !important;
    color: var(--color-black) !important;
  }
  .profile-data-tag {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-dark-grey);
  }
  .profile-user-name {
    font-size: 18px;
    line-height: 1.11;
    color: var(--color-black);
    border-bottom: 2px solid #f6f8fa;
  }
  .profile-edit-btn {
    border-radius: 6px;
    border-color: var(--color-ButtonBackRegular);
    background-color: var(--color-ButtonBackRegular);
    color: var(--color-ButtonTextRegular);
    width: 100%;
    max-width: 180px;
    font-size: 18px !important;
    line-height: 1.11 !important;
    font-weight: bold !important;
    font-family: "GTWalsheimPro-Medium-" !important;
  }
  
  .profile-edit-btn:hover {
    background-color: var(--color-ButtonBackHover);
    color: var(--color-ButtonTextHover);
  }
  
  .profile-edit-pass {
    /* color: var(--color-primary); */
    color: var(--color-ButtonTextRegular);
  }
  
  .profile-edit-pass,
  .profile-edit-btn {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.11;
    padding: 15px 15px;
  }
  
  .yt-cm-lt-col {
    padding-right: 7px;
    /* max-width: 480px; */
  }
  .yt-cm-rt-col {
    padding-left: 7px;
  }
  @media (min-width: 768px) {
    .yt-profile-mb-ttl {
      display: none;
    }
  }
  @media (min-width: 1280.88px) {
    .yt-cm-lt-col {
      padding-right: 10px;
    }
    .yt-cm-rt-col {
      padding-left: 10px;
    }
  }
  @media (min-width: 1900px) {
    .yt-cm-lt-col {
      padding-right: 20px;
    }
    .yt-cm-rt-col {
      padding-left: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1900px) {
    .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
      right: -30px !important;
    }
    .pg-profile-box .img-upload {
      width: 80px;
      height: 80px;
    }
    .yt-cm-lt-col {
      padding-right: 15px;
    }
    .yt-cm-rt-col {
      padding-left: 15px;
    }
    .yt-cm-ptc-row.yt-btm-inf.row {
      margin-top: 30px;
    }
    .yt-cm-rt-col .profile-pg-inner-wrap {
      padding: 40px 30px 35px !important;
    }
    .profile-name {
      font-size: 21px;
      line-height: 1.14;
    }
    .profile-email {
      line-height: 1.13;
      font-size: 16px;
    }
    .profile-pg-title {
      font-size: 26px;
      line-height: 1.12;
    }
    .profile-pg-inner-wrap.profile-inner-tab-wrap {
      padding: 20px;
    }
    .profile-pg-inner-wrap.profile-inner-tab-content {
      padding: 30px;
    }
    .profile-item-icn {
      max-width: 35px;
    }
    .profile-notiy {
      font-size: 12px;
      line-height: 1.17;
      padding: 3px 0px;
      border-radius: 50px;
      width: 20px;
      text-align: center;
      left: 20px;
    }
    .profile-pg-inner-contnet .profile-pg-tabs-name li {
      font-weight: normal;
    }
    .profile-edit-pass,
    .profile-edit-btn {
      font-size: 16px;
      line-height: 1.13;
    }
    .profile-pg-breadcrumbs {
      margin-bottom: 30px;
    }
    .profile-pg-address-list {
      margin-bottom: 20px !important;
      padding: 30px;
    }
  }
  /*
  * Screen for under 1280 Resolution 
  */
  @media (max-width: 1279.92px) {
    .profile-item-wrap .profile-item-name {
      font-size: 14px;
      line-height: 1.14;
    }
    .profile-item-wrap .profile-item-icn {
      max-width: 46px;
      margin-right: 17px !important;
    }
    .profile-pg-inner-wrap,
    .tab-content .profile-pg-inner-wrap {
      padding: 15px !important;
    }
    .profile-inner-tab-content {
      padding: 18px 15px 15px !important;
    }
    .profile-notiy {
      font-size: 12px;
      line-height: 1.17;
    }
    .yt-cm-lt-col {
      max-width: 250px;
      /* box-sizing: content-box; */
    }
    .img-upload {
      width: 43px;
      height: 43px;
    }
    .yt-profile-img-nm-wrap .img-upload {
      width: 90px;
      height: 90px;
    }
    .yt-profile-img-nm-wrap {
      margin-bottom: 20px !important;
    }
    .img-upload svg {
      font-size: 16px;
    }
    .profile-data-wrap {
      max-width: 330px;
    }
    .profile-data-tag {
      font-size: 12px;
      line-height: 1.17;
    }
  
    .profile-edit-btn {
      padding-top: 14px;
      padding-bottom: 14px;
      background-color: var(--button-primary);
      color: var(--button-text-primary);
      border-color: var(--button-primary);
    }
    .profile-edit-pass,
    .profile-edit-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
    }
    .profile-name {
      font-size: 20px;
      line-height: 1.15;
    }
    .profile-email {
      font-size: 12px;
      line-height: 1.17;
      max-width: 75%;
      overflow-x: auto;
    }
    .user-profileinfo {
      margin-left: 12px !important;
    }
    .profile-pg-inner-wrap.profile-inner-tab-wrap {
      margin-bottom: 10px;
    }
    .profile-pg-title {
      font-size: 24px;
      line-height: 1.17;
      margin-bottom: 14px !important;
    }
    .yt-cm-ptc-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .profile-pg-breadcrumbs {
      margin-bottom: 58px;
    }
    .yt-profile-img-nm-wrap .img-upload svg {
      font-size: 34px;
    }
    .profile-pg-inner-contnet .profile-pg-tabs-name li.active::after {
      right: -15px;
    }
    .profile-pg-address-list {
      padding: 20px;
      margin-bottom: 10px;
    }
  }
  
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767px) {
    .profile-pg-title {
      margin-bottom: 10px !important;
    }
    .user-review {
      width: 100%;
      margin-bottom: 30px;
    }
    .img-upload svg {
      font-size: 34px;
    }
    .profile-pg-title {
      font-size: 18px;
      line-height: 1.11;
      padding-left: 8px;
    }
    .profile-pg-breadcrumbs {
      margin-bottom: 10px;
      margin-top: 10px !important;
    }
    .profile-pg-inner-wrap,
    .tab-content .profile-pg-inner-wrap {
      padding: 20px !important;
    }
  
    .yt-profile-mb-ttl.profile-pg-title {
      display: block;
    }
    .yt-cm-row {
      display: block;
    }
    .yt-cm-row .yt-cm-rt-col,
    .yt-cm-row .yt-cm-lt-col {
      width: 100%;
      max-width: 100%;
    }
    .yt-cm-row.row .yt-cm-rt-col,
    .yt-cm-row.row .yt-cm-lt-col {
      padding-right: 6px;
      padding-left: 6px;
    }
  
    .profile-edit-pass {
      padding: 0;
      margin-right: auto !important;
    }
    .profile-edit-btn {
      padding-top: 14px;
      padding-bottom: 14px;
      background-color: var(--button-primary);
      color: var(--button-text-primary);
      border-color: var(--button-primary);
    }
    .profile-edit-pass,
    .profile-edit-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
    }
    .profile-user-name {
      font-size: 18px !important;
      line-height: 1.11;
      margin-bottom: 24px;
      font-family: "GTWalsheimPro-Regular-" !important;
      color: var(--color-black) !important;
    }
    .profile-data-tag {
      font-size: 13px;
      line-height: 1.15;
    }
    .yt-profile-img-nm-wrap {
      margin-bottom: 24px !important;
    }
    .yt-cm-row .bb-cm-lt-col {
      display: none;
    }
  }
  
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.98px) {
    .profile-pg-address-list {
      padding: 20px;
      margin-bottom: 14px;
    }
  }
  
/*Order Detail css*/
.text-right {
  text-align: right !important;
}
.text-right-wishlist svg {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
}
.pp-order-product-ttl {
  font-size: 24px;
  font-weight: 500;
  color: var(--color-black);
  cursor: pointer;
  line-height: 1.17;
}
.order-ul-list-none {
  list-style: none;
}
.profile-pg-order-main-wrap {
  padding: 145px 0;
}
.radius-10 {
  border-radius: 10px;
}
.profile-pg-inner-wrap.yt-my-order-wrap {
  padding: 30px;
}
.profile-pg-inner-wrap {
  border: 1px solid #e6e6e6;
}
.op-order-quantity {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.op-order-product-price {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-green);
  padding-top: 41px;
}
.op-order-product-total-price {
  font-size: 16px;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-black);
  /* padding-top: 41px; */
  padding-top: 2.85%;
  text-align: end;
}

.order-prodict-type-container {
  max-width: 30vw;
  overflow-x: auto;
}
@media (max-width: 767.88px) {
  .order-prodict-type-container {
    max-width: 52vw;
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .order-prodict-type-container {
    max-width: 38vw;
    margin-bottom: 10px;
  }
}

/* width */
.order-prodict-type-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.order-prodict-type-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.order-prodict-type-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.order-prodict-type-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.order-prodict-type th,
.order-prodict-type td {
  padding: 3px;
}
.order-prodict-type th:first-child,
.order-prodict-type tr td:first-child {
  padding-left: 0 !important;
}
.order-prodict-type th,
.order-prodict-type tr td {
  padding-left: 44px !important;
  white-space: nowrap;
}
.order-prodict-type th {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}
.order-prodict-type td {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}

.od-product-img {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  max-width: 133px;
}
.order-product-info {
  padding-left: 20px;
}
.yt-order-wrapper-box .order-product-quanity {
  position: absolute;
  right: 0;
  top: 30px;
  bottom: 15px;
}
.yt-order-wrapper-box {
  position: relative;
}
.order-number-wrap .order-tag,
.order-date-wrap .order-tag {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.order-number-wrap .order-tag-val,
.order-date-wrap .order-tag-val {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: var(--color-black);
}
.order-bdr-between {
  width: 2px;
  background-color: #f6f8fa;
  margin: 0 15px;
  height: 18px;
}
.order-write-review {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: var(--button-primary) !important;
  text-decoration: underline;
  padding: 0 !important;
}
.order-cancel-btn {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  text-align: left;
  color: var(--color-orange);
  position: absolute;
  bottom: -20px;
  right: 0;
}
.pp-od-no-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-od-no-text.mb-0 {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-order-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.profile-pg-inner-wrapper .yt-order-wrapper-box:last-child {
  padding-bottom: 0 !important;
}
.order-product-info {
  padding-left: 40px;
}

.profile-pg-order-main-wrap img {
  max-height: 217px;
}

/**/
.yt-order-wrapper-box .order-product-quanity .yt-qt-prc li {
  width: 100%;
  text-align: right;
}
.yt-order-wrapper-box .order-product-quanity .yt-qt-prc {
  height: 100%;
}
/**/
@media (min-width: 1900px) {
  .pp-order-product-ttl {
    line-height: 1.67;
  }
  .profile-pg-inner-wrap.yt-my-order-wrap {
    padding: 40px;
  }

  .order-main-wrapper .profile-mo-dt-wrap {
    padding-left: 173px;
  }
}
@media (max-width: 1899.98px) {
  .pp-order-product-ttl {
    max-width: 300px;
  }
}

@media (max-width: 1900px) and (min-width: 1366px) {
  .order-number-wrap .order-tag,
  .order-date-wrap .order-tag,
  .order-number-wrap .order-tag-val,
  .order-date-wrap .order-tag-val {
    font-size: 14px;
    line-height: 1.36;
  }
  .order-write-review {
    font-size: 16px;
    line-height: 1.19;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .order-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  .order-prodict-type td {
    font-size: 16px;
    line-height: 1.31;
  }
  .od-product-img {
    max-width: 95px;
    padding: 11px !important;
  }
  .od-product-img img {
    width: 72px;
    height: 72px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .order-product-info {
    padding-left: 20px;
  }
  .btn.od-cancel-btn {
    color: #e65e52;
    padding-right: 0 !important;
    padding-left: 0 !important;
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.69px;
    text-decoration: none !important;
  }
  .profile-pg-order-main-wrap {
    padding: 45px 0;
  }
  .profile-pg-order-main-wrap img {
    max-height: 180px;
    margin-bottom: 30px !important;
  }
  .pp-sa-order-wrap {
    margin-bottom: 30px !important;
  }
  .pp-no-order-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-mo-dt-wrap {
    padding-left: 115px;
  }
  .yt-order-wrapper-box.yt-border-order {
    border-top: solid 1px #cae2fe;
    padding-top: 30px !important;
    margin-top: 15px;
  }
  .profile-pg-inner-wrap.yt-my-order-wrap {
    margin-bottom: 20px !important;
  }
}
/*
    * Screen for under 1280 Resolution 
    */
@media (max-width: 1279.92px) {
  .profile-pg-order-main-wrap img {
    margin-bottom: 40px !important;
  }
  .pp-sa-order-wrap {
    margin-bottom: 40px !important;
  }
  .pp-od-no-ttl {
    font-size: 24px;
    line-height: 1.17;
  }
  .pp-od-no-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .pp-no-order-btn {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .profile-pg-order-main-wrap {
    padding: 40px 0;
  }
  .od-product-img {
    max-width: 70px;
    align-self: flex-start;
  }
  .order-subscription {
    max-width: 70px !important;
    font-size: 8px !important;
  }
  .order-product-info {
    padding-left: 10px;
  }
  .pp-order-product-ttl {
    font-size: 16px;
    line-height: 1.13;
    max-width: 200px;
  }
  .yt-my-order-wrap .order-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.38px;
  }
  .yt-my-order-wrap .order-prodict-type td {
    font-size: 16px;
    line-height: 1.31;
  }
  .yt-my-order-wrap .op-order-quantity {
    font-size: 14px;
    line-height: 1.36;
  }
  .yt-my-order-wrap .op-order-product-price .op-order-product-total-price {
    font-size: 20px;
    line-height: 1.15;
  }
  .op-order-product-total-price {
    padding-top: 5%;
  }
  .yt-order-wrapper-box .order-product-quanity {
    top: 15px;
  }
  .yt-my-order-wrap .order-number-wrap .order-tag,
  .yt-my-order-wrap .order-date-wrap .order-tag {
    font-size: 12px;
    line-height: 1.58;
  }
  .yt-my-order-wrap .order-number-wrap .order-tag-val,
  .yt-my-order-wrap .order-date-wrap .order-tag-val {
    font-size: 12px;
    line-height: 1.58;
  }
  .order-write-review {
    font-size: 14px;
    line-height: 1.36;
    padding: 0;
  }
  .profile-pg-inner-wrap.yt-my-order-wrap {
    padding: 18px 15px;
  }
  .profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord {
    padding-bottom: 40px;
  }
  .order-bdr-between {
    margin: 0 7.5px;
  }
}
@media (max-width: 850px) and (min-width: 768px) {
  .yt-my-order-wrap .order-number-wrap .order-tag-val,
  .yt-my-order-wrap .order-date-wrap .order-tag-val {
    display: block;
  }
}
/*
    * Screen for under 768 Resolution 
    */
@media (max-width: 767.92px) {
  .profile-pg-inner-wrap.profile-pg-inner-no-order {
    padding: 20px 9px;
  }
  .profile-pg-order-main-wrap {
    padding: 0;
  }
  .profile-pg-order-main-wrap img {
    max-height: 165px;
    margin-bottom: 16px !important;
  }
  .pp-od-no-ttl {
    font-size: 17px;
    line-height: 1.12;
  }
  .pp-od-no-text {
    font-size: 15px !important;
    line-height: 1.2 !important;
    max-width: 233px;
    margin: 0 auto;
  }
  .pp-no-order-btn {
    font-size: 14px;
    line-height: 1.14;
    width: 100%;
    max-width: 100%;
  }
  .pp-sa-order-wrap {
    margin-bottom: 30px !important;
  }
  /* yt-my-order-wrap */
  .profile-pg-inner-wrap.yt-my-order-wrap {
    padding: 15px;
    position: relative;
    margin: 67px 0;
  }
  .yt-my-order-wrap .od-product-img {
    max-width: 70px;
  }
  .yt-my-order-wrap .order-product-info {
    padding-left: 16px;
  }
  .yt-my-order-wrap .pp-order-product-ttl {
    font-size: 14px;
    line-height: 1.36;
    max-width: 160px;
  }
  .yt-my-order-wrap .order-prodict-type th {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.38px;
  }
  .od-cancel-btn {
    padding: 0;
  }
  .order-prodict-type th,
  .order-prodict-type td {
    padding: 5px;
  }
  .yt-my-order-wrap .order-prodict-type td {
    font-size: 14px;
    line-height: 1.5;
  }
  .yt-my-order-wrap .op-order-quantity {
    font-size: 10px;
    line-height: 1.9;
  }
  .yt-my-order-wrap .op-order-product-price .op-order-product-total-price {
    font-size: 15px;
    line-height: 1.2;
  }
  .op-order-product-total-price {
    padding-top: 10%;
  }
  .profile-pg-inner-wrap .yt-my-order-tdet-wrap {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 10px 0px;
    align-items: flex-start !important;
  }
  .yt-my-order-wrap .order-number-wrap .order-tag,
  .yt-my-order-wrap .order-date-wrap .order-tag {
    font-size: 12px;
    line-height: 1.58;
  }
  .yt-my-order-wrap .order-number-wrap .order-tag-val,
  .yt-my-order-wrap .order-date-wrap .order-tag-val {
    font-size: 12px;
    line-height: 1.58;
  }
  .yt-my-order-wrap .order-bdr-between {
    display: none !important;
  }
  .yt-my-order-wrap .profile-mo-dt-wrap {
    max-width: 60%;
  }
  .yt-my-order-wrap .order-write-review {
    padding: 0 !important;
    font-size: 14px;
    line-height: 1.36;
  }
  .yt-my-order-wrap .order-cancel-btn {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
    position: absolute;
    bottom: -25px;
    right: 0;
    padding: 0;
  }
  .yt-order-wrapper-box {
    padding-top: 0 !important;
  }
  .yt-order-wrapper-box .order-product-quanity {
    top: 0;
    bottom: 5px;
  }
  .profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord {
    padding-bottom: 40px;
  }
}

.pr-body-title {
    font-size: 16px;
    line-height: 1.13;
    text-align: left;
    color: var(--color-dark-grey);
    margin-top: 22px;
  }
  #product-review-description {
    min-height: 160px;
    border-radius: 10px;
    margin-top: 30px;
  }
  .pr-rate-submit-btn {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    color: var(--color-black) !important;
    border: 2px solid #fff !important;
    box-shadow: none !important;
  }
  .yt-rating-wrapper.react-stars svg {
    font-size: 29px;
  }
  .pr-rate-submit-btn {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .pr-rate-submit-btn:hover {
    border: 2px solid #fff !important;
    box-shadow: none !important;
    color: var(--color-black);
  }
  .pr-title-bar span {
    /* position: absolute; */
    top: 27px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--color-black);
  }
  .cm-product-star-rating span:last-child {
    margin-right: 0;
  }
  .cm-product-star-rating span:first-child {
    margin-left: 0;
  }
  .cm-product-star-rating span {
    margin: 0 5px;
  }
  .cm-modal-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .pr-bottom-bar {
    margin-top: 5px;
  }
  
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.92px) {
    .cm-modal-body {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .yt-rating-wrapper {
      margin: 0 auto;
    }
    .pr-body-title {
      text-align: center;
    }
  }
  
/*Order Detail css*/
.pp-order-product-ttl {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-black);
    cursor: pointer;
    line-height: 1.17;
  }
  .order-ul-list-none {
    list-style: none;
  }
  .profile-pg-order-main-wrap {
    padding: 145px 0;
  }
  .radius-10 {
    border-radius: 10px;
  }
  .profile-pg-inner-wrap.yt-my-order-wrap {
    padding: 30px;
  }
  .op-order-quantity {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    color: var(--color-dark-grey);
  }
  .profile-pg-inner-wrap {
    border: 1px solid #e6e6e6;
  }
  .op-order-product-price {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 1.08px;
    color: var(--color-green);
  }
  .order-prodict-type th,
  .order-prodict-type td {
    padding: 3px;
  }
  .order-prodict-type th:first-child,
  .order-prodict-type tr td:first-child {
    padding-left: 0 !important;
  }
  .order-prodict-type th,
  .order-prodict-type tr td {
    padding-left: 44px !important;
  }
  .order-prodict-type th {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
  }
  .order-prodict-type td {
    font-size: 17px;
    line-height: 1.24;
    color: var(--color-black);
  }
  
  .od-product-img {
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    max-width: 133px;
  }
  .order-product-info {
    padding-left: 20px;
  }
  .yt-order-wrapper-box .order-product-quanity {
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 15px;
  }
  .yt-order-wrapper-box {
    position: relative;
  }
  .order-number-wrap .order-tag,
  .order-date-wrap .order-tag {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    color: var(--color-dark-grey);
  }
  .order-number-wrap .order-tag-val,
  .order-date-wrap .order-tag-val {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: var(--color-black);
  }
  .order-bdr-between {
    width: 2px;
    background-color: #f6f8fa;
    margin: 0 15px;
    height: 18px;
  }
  .order-write-review {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: right;
    color: var(--color-blue);
    text-decoration: underline;
  }
  .order-cancel-btn {
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.69px;
    text-align: left;
    color: var(--color-orange);
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .pp-od-no-ttl {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  .pp-od-no-text.mb-0 {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey);
  }
  .pp-no-order-btn {
    opacity: 0.99;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    border-color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: #ffffff;
    width: 100%;
    max-width: 200px;
  }
  .profile-pg-inner-wrapper .yt-order-wrapper-box:last-child {
    padding-bottom: 0 !important;
  }
  .order-product-info {
    padding-left: 40px;
  }
  
  .profile-pg-order-main-wrap img {
    max-height: 217px;
  }
  
  /**/
  .yt-order-wrapper-box .order-product-quanity .yt-qt-prc li {
    width: 100%;
    text-align: right;
  }
  .yt-order-wrapper-box .order-product-quanity .yt-qt-prc {
    height: 100%;
  }
  /**/
  @media (min-width: 1900px) {
    .pp-order-product-ttl {
      line-height: 1.67;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap {
      padding: 40px;
    }
  
    .order-main-wrapper .profile-mo-dt-wrap {
      padding-left: 173px;
    }
  }
  @media (max-width: 1899.98px) {
    .pp-order-product-ttl {
      max-width: 300px;
    }
  }
  
  @media (max-width: 1900px) and (min-width: 1366px) {
    .order-number-wrap .order-tag,
    .order-date-wrap .order-tag,
    .order-number-wrap .order-tag-val,
    .order-date-wrap .order-tag-val {
      font-size: 14px;
      line-height: 1.36;
    }
    .order-write-review {
      font-size: 16px;
      line-height: 1.19;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .order-prodict-type th {
      font-size: 12px;
      line-height: 1.17;
      letter-spacing: 0.46px;
    }
    .order-prodict-type td {
      font-size: 16px;
      line-height: 1.31;
    }
    .od-product-img {
      max-width: 95px;
      padding: 11px !important;
    }
    .od-product-img img {
      width: 72px;
      height: 72px;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: top;
         object-position: top;
    }
    .order-product-info {
      padding-left: 20px;
    }
    .order-main-wrapper .profile-pg-inner-wrapper .btn.od-cancel-btn {
      color: #e65e52;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
  /*
  * Screen for under 1280 Resolution 
  */
  @media (max-width: 1279.92px) {
    .profile-pg-order-main-wrap img {
      margin-bottom: 40px !important;
    }
    .pp-sa-order-wrap {
      margin-bottom: 40px !important;
    }
    .pp-od-no-ttl {
      font-size: 24px;
      line-height: 1.17;
    }
    .pp-od-no-text {
      font-size: 14px;
      line-height: 1.14;
    }
    .pp-no-order-btn {
      font-size: 16px;
      line-height: 1.13;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .profile-pg-order-main-wrap {
      padding: 40px 0;
    }
    .od-product-img {
      max-width: 70px;
      align-self: flex-start;
    }
    .order-product-info {
      padding-left: 10px;
    }
    .pp-order-product-ttl {
      font-size: 16px;
      line-height: 1.13;
      max-width: 200px;
    }
    .yt-my-order-wrap .order-prodict-type th {
      font-size: 12px;
      line-height: 1.17;
      letter-spacing: 0.38px;
    }
    .yt-my-order-wrap .order-prodict-type td {
      font-size: 16px;
      line-height: 1.31;
    }
    .yt-my-order-wrap .op-order-quantity {
      font-size: 14px;
      line-height: 1.36;
    }
    .yt-my-order-wrap .op-order-product-price {
      font-size: 20px;
      line-height: 1.15;
    }
    .yt-order-wrapper-box .order-product-quanity {
      top: 15px;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag,
    .yt-my-order-wrap .order-date-wrap .order-tag {
      font-size: 12px;
      line-height: 1.58;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag-val,
    .yt-my-order-wrap .order-date-wrap .order-tag-val {
      font-size: 12px;
      line-height: 1.58;
    }
    .order-write-review {
      font-size: 14px;
      line-height: 1.36;
      padding: 0;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap {
      padding: 18px 15px;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord {
      padding-bottom: 40px;
    }
    .order-bdr-between {
      margin: 0 7.5px;
    }
  }
  
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.92px) {
    .profile-pg-inner-wrap.profile-pg-inner-no-order {
      padding: 20px 9px;
    }
    .profile-pg-order-main-wrap {
      padding: 0;
    }
    .profile-pg-order-main-wrap img {
      max-height: 165px;
      margin-bottom: 16px !important;
    }
    .pp-od-no-ttl {
      font-size: 17px;
      line-height: 1.12;
    }
    .pp-od-no-text {
      font-size: 15px;
      line-height: 1.2;
      max-width: 233px;
      margin: 0 auto;
    }
    .pp-no-order-btn {
      font-size: 14px;
      line-height: 1.14;
      width: 100%;
      max-width: 100%;
    }
    .pp-sa-order-wrap {
      margin-bottom: 30px !important;
    }
    /* yt-my-order-wrap */
    .profile-pg-inner-wrap.yt-my-order-wrap {
      padding: 15px;
      position: relative;
      margin: 67px 0;
    }
    .yt-my-order-wrap .od-product-img {
      max-width: 70px;
    }
    .yt-my-order-wrap .order-product-info {
      padding-left: 16px;
    }
    .yt-my-order-wrap .pp-order-product-ttl {
      font-size: 14px;
      line-height: 1.36;
      max-width: 160px;
    }
    .yt-my-order-wrap .order-prodict-type th {
      font-size: 10px;
      line-height: 1.1;
      letter-spacing: 0.38px;
    }
    .yt-my-order-wrap .order-prodict-type td {
      font-size: 14px;
      line-height: 1.5;
    }
    .yt-my-order-wrap .op-order-quantity {
      font-size: 10px;
      line-height: 1.9;
    }
    .yt-my-order-wrap .op-order-product-price {
      font-size: 15px;
      line-height: 1.2;
    }
    .profile-pg-inner-wrap .yt-my-order-tdet-wrap {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      margin: 10px 0px;
      align-items: flex-start !important;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag,
    .yt-my-order-wrap .order-date-wrap .order-tag {
      font-size: 12px;
      line-height: 1.58;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag-val,
    .yt-my-order-wrap .order-date-wrap .order-tag-val {
      font-size: 12px;
      line-height: 1.58;
    }
    .yt-my-order-wrap .order-bdr-between {
      display: none !important;
    }
    .yt-my-order-wrap .profile-mo-dt-wrap {
      max-width: 60%;
    }
    .yt-my-order-wrap .order-write-review {
      padding: 0 !important;
      font-size: 14px;
      line-height: 1.36;
    }
    .yt-my-order-wrap .order-cancel-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.54px;
      position: absolute;
      bottom: -25px;
      right: 0;
      padding: 0;
    }
    .yt-order-wrapper-box {
      padding-top: 0 !important;
    }
    .yt-order-wrapper-box .order-product-quanity {
      top: 0;
      bottom: 5px;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord {
      padding-bottom: 40px;
    }
  }
  
/*
* YT Product Slider CSS
*/
.profile-pg-mb-30 {
  margin-bottom: 30px;
}
.profile-pg-mt-30 {
  margin-bottom: 30px;
}
.radius-10 {
  border-radius: 10px;
}
.profile-p-30 {
  padding: 30px;
}
.profile-pg-wl-sgl-product-fav {
  width: 24px;
}
.profile-pg-wl-sglproduct-fav {
  color: var(--color-orange);
  cursor: pointer;
  font-size: 24px;
}
.profile-pg-wl-sgl-product-off {
  background-color: var(--color-primary);
  color: #ffffff;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-pg-wl-sgl-product-cpnt {
  border: 1px solid #cae2fe;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding-bottom: 46px;
  position: relative;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.profile-pg-wl-sgl-product-cpnt:hover {
  box-shadow: 0 10px 50px -12px hsl(0deg 0% 67% / 90%);
  transform: translateY(-10px);
  transition: all 0.55s ease;
}
.profile-pg-wl-sgl-component-wrapper {
  border-radius: 10px;
  position: relative;
}

.profile-pg-inner-wrap {
  border: 1px solid #e6e6e6;
}

.rec-item-wrapper {
  outline: none !important;
  box-shadow: none;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: var(--color-dark-black);
}
.profile-pg-wl-sgl-product-title {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-black);
  margin: 25px 0 14px;
}
.profile-pg-wl-sgl-price-wrap {
  margin-bottom: 23px;
}
.profile-pg-wl-sgl-product-img {
  width: 184px;
  height: 184px;
  background-size: cover;
  background-position: 100%;
  margin: 5px auto 0;
}
.profile-pg-wl-sgl-component-more {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.profile-pg-wl-sgl-comonent-top-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.profile-pg-wl-cm-row-margin {
  margin: 0 -0.5rem;
}

.profile-pg-wl-sgl-product-off {
  background-color: var(--color-primary);
  font-size: 18px !important;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-pg-wl-sgl-product-sale-price {
  color: var(--color-blue);
}
.profile-pg-wl-sgl-product-reg-price2 {
  padding-left: 8px;
  color: var(--color-dark-grey);
  text-decoration: line-through;
}

.profile-pg-wl-sgl-product-add-btn.buttoncart {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: var(--color-ButtonTextRegular);
  /* background: transparent; */
  background: var(--color-ButtonBackRegular);
  border: 0;
  border-top: 1px solid #e2e2e2;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart:hover {
  color: var(--color-ButtonTextHover);
  background-color: var(--color-ButtonBackHover);
  border-top: 1px solid #30458c;
}
.profile-pg-wl-sgl-product-sale-price,
.profile-pg-wl-sgl-product-reg-price2 {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.profile-pg-wl-sgl-product-sale-price {
  color: var(--color-blue);
}
.yt-product-bg-image {
  width: 180px;
  height: 180px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}

.product-details .ratings {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.product-details .ratings span {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}
.product-details .ratings .product-rating {
  color: var(--color-dark-grey);
}
.product-details .ratings .rating-star-icon {
  margin: 0 5px;
}
.yt-product-off {
  /* background-color: yellow; */
  background-color: var(--color-red);
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-primary);
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}

@media (max-width: 1900px) and (min-width: 1366px) {
  .profile-pg-wl-sgl-product-title {
    font-size: 18px;
    line-height: 1.06;
  }
  .profile-pg-wl-sgl-product-add-btn.buttoncart {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
  }
  .profile-pg-wl-sgl-product-cpnt {
    margin-bottom: 30px !important;
  }
  .yt-product-off {
    font-size: 16px;
    min-width: 50px;
  }
}
/*
    * Screen for under 1280 Resolution 
    */
@media (max-width: 1279.92px) {
  .profile-pg-wl-sgl-product-off {
    font-size: 12px !important;
    line-height: 1.17;
    min-width: 35px;
  }
  .profile-pg-wl-sgl-product-title {
    font-size: 14px;
    line-height: 0.71;
    margin: 15px 0 8px;
  }
  .profile-pg-wl-sgl-product-sale-price,
  .profile-pg-wl-sgl-product-reg-price2 {
    font-size: 15px;
    line-height: 1.2;
  }
  .profile-pg-wl-sgl-product-add-btn {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 12px;
    line-height: 1.17;
  }
  .profile-pg-wl-sgl-price-wrap {
    margin-bottom: 16px;
  }
  .profile-pg-wl-sgl-product-cpnt {
    margin-bottom: 15px !important;
  }
  .profile-pg-whish-lt-inner-wrap.profile-pg-inner-wrap {
    padding: 20px 20px 5px;
  }
}
/*
    * Screen for under 768 Resolution 
    */
@media (max-width: 767.92px) {
  .profile-pg-wl-sgl-product-add-btn.buttoncart {
    font-size: 13px;
    line-height: 1.15;
    color: var(--color-white) !important;
  }
  .profile-pg-whish-lt-inner-wrap.profile-pg-inner-wrap {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
  }
  .profile-pg-wl-cm-row-margin {
    margin: 0 -11px;
  }
  .profile-pg-wl-sgl-product-cpnt {
    max-width: 100%;
    margin-bottom: 10px !important;
  }
  .yt-cm-wl-col {
    max-width: 50%;
  }
  .profile-pg-wl-cm-row-margin .yt-cm-wl-col:nth-child(2n) {
    padding-left: 6.5px !important;
    padding-right: 15px !important;
  }
  .profile-pg-wl-cm-row-margin .yt-cm-wl-col:nth-child(2n + 1) {
    padding-right: 6.5px !important;
    padding-left: 15px !important;
  }
  .profile-pg-wl-sgl-product-title {
    font-size: 16px;
    line-height: 1.19;
    margin: 20px 0 23px;
  }
  .profile-pg-wl-sgl-product-sale-price,
  .profile-pg-wl-sgl-product-reg-price2 {
    font-size: 15px;
    line-height: 1.2;
  }
  .profile-pg-wl-sgl-product-add-btn {
    font-size: 13px;
    line-height: 1.15;
  }
  .profile-pg-wl-sgl-price-wrap {
    margin-bottom: 18px;
  }
}

.remove-wh-lst-title-bar .modal-title {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
  }
  .remove-wh-lst-body-text {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: var(--color-orange);
  }
  .remove-wh-lst-title-bar span {
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    line-height: 1.11;
    color: var(--color-black);
    font-size: 18px;
    font-weight: 500;
  }
  .pp-remove-wh-lst-btn-modal {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: white;
    border-color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
  }
  .pp-remove-wh-lst-btn-modal:hover {
    border-color: #fff;
  }
  .pp-remove-wh-lst-btn-light-grey {
    color: var(--color-dark-grey);
  }
  .pp-remove-wh-lst-btn-dark-grey {
    color: var(--color-black);
  }
  
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.92px) {
    .remove-wh-lst-body-text {
      font-size: 15px;
      line-height: 1.2;
      padding: 0 !important;
    }
  }
  
  @media (max-width: 1900px) and (min-width: 1366px) {
  }
  
.delete-addr-title-bar .modal-title {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
  }
  .delete-addr-body-text {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey);
  }
  .delete-addr-title-bar span {
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    top: 27px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--color-black);
  }
  .delete-addr-bottom-bar {
    border-top: 2px solid #dbdbdb !important;
    position: relative;
  }
  .yt-form-spacer {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    display: block;
    bottom: 10px;
    width: 2px;
    top: 10px;
    background: #dbdbdb;
  }
  .pp-delete-addr-btn-modal {
    width: 48%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent !important;
    border-color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
  }
  .pp-delete-addr-btn-modal:hover {
    border-color: #fff !important;
  }
  .pp-delete-addr-btn-light-grey {
    color: var(--color-dark-grey) !important;
  }
  .pp-delete-addr-btn-dark-grey {
    color: var(--color-black) !important;
  }
  .pp-add-edit-btn {
    box-shadow: none !important;
  }
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.92px) {
    .delete-addr-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .delete-addr-body-text {
      font-size: 16px;
      line-height: 1.13;
    }
  }
  
  /*
  * Screen for under 1280 Resolution 
  */
  @media (max-width: 1279.92px) {
    .delete-addr-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .delete-addr-body-text {
      font-size: 15px;
      line-height: 1.2;
      padding-top: 0 !important;
    }
  }
  
.read-delete-buttons {
  float: right;
  margin-top: -5%;
  margin-right: 1%;
}
.read-delete-btn {
  background: none !important;
  /* border: none; */
  background-color: transparent !important;
  border: none !important;
  color: var(--color-black) !important;
}
.notification-title {
  font-weight: 500;
  color: #3e454f;
}
.notification-sub-title {
  color: #a3a3a3;
}
.pp-sa-list-notify {
  list-style: none;
  max-height: 140vh;
  overflow: auto;
  width: 100%;
}
.pp-sa-list-notify::-webkit-scrollbar {
  width: 4px;
}
.pp-sa-list-notify::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 2px;
}
.pp-sa-list-notify::-webkit-scrollbar-thumb {
  background: #888;
}
.pp-sa-list-notify::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.delete-btn {
  width: 17.8px;
  height: 22px;
  /* margin: 0.2px 0 0 566.2px; */
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 8%;
  cursor: pointer !important;
}
.delete-btn:hover {
  cursor: pointer !important;
}

.pp-na-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-na-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
  width: 100%;
  max-width: 200px;
}
.pp-no-addr-btn:hover {
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.pp-sa-list-none {
  list-style: none;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  padding-left: 32px;
}

.profile-pg-inner-no-add img {
  max-height: 217px;
}
.profile-pg-address-list {
  border: 1px solid #e6e6e6;
}
.profile-pg-inner-wrap {
  border: 1px solid #e6e6e6;
}
.pp-sa-action-wrap {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.profile-pg-address-list {
  padding: 40px;
  position: relative;
}
.profile-pg-sa-no-address-main-wrap {
  padding: 165px 0;
}
.pp-sa-type {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.pp-sa-address {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.pp-sa-action-wrap .btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: darkgray;
}
.pp-sa-action-wrap .pp-add-delete-btn {
  color: var(--color-orange);
}
.radius-10 {
  border-radius: 10px;
}
.profile-pg-mb-30 {
  margin-bottom: 30px;
}
.pp-sa-all-addres-list li {
  cursor: pointer;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap {
  max-width: 100%;
}

.loadMoreBtn {
  text-align: center !important;
  margin-top: 20px;
}

.loadMoreBtn .btn-outline-secondary:hover {
  color: #fff;
  background-color: #3e454f;
  border: 1px solid #3e454f;
}

.yt-load-more {
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-black);
}
.pp-sa-list-notify li:hover {
  box-shadow: 0 10px 50px -12px hsl(0deg 0% 67% / 90%);
  transform: translateY(-6px);
  transition: all 0.55s ease;
  /* transform: translateY(-3px);
    transition: all 0.55s ease; */
}
.pp-sa-address,
.pp-no-addr-btn {
  font-weight: bold;
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .pp-sa-address,
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 45px 0;
  }
  .profile-pg-inner-no-add img {
    max-height: 185px;
    margin-bottom: 30px !important;
  }
  .pp-sa-img-wrap img {
    max-width: 50px;
    height: 50px;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .profile-pg-address-list {
    margin-bottom: 20px !important;
    padding: 30px;
  }
}
/*
    * Screen for under 1280 Resolution 
    */
@media (max-width: 1279.92px) {
  .profile-pg-sa-no-address-main-wrap {
    padding: 39px 0;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 39px !important;
  }
  .pp-na-ttl {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 40px !important;
  }
  .pp-no-addr-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 10px;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    padding-left: 14px;
    margin-top: 7px;
    padding-right: 125px;
  }
  .pp-sa-type {
    font-size: 16px;
    line-height: 1.19;
  }
  .pp-sa-address {
    line-height: 1.36;
    font-size: 14px;
  }
  .pp-sa-img-wrap {
    max-width: 80%;
  }
  .pp-sa-action-wrap .btn {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 0;
    padding-right: 0;
  }
  .read-delete-buttons {
    float: none;
  }
}

/*
    * Screen for under 768 Resolution 
    */
@media (max-width: 767.98px) {
  .pp-sa-info-wrap {
    margin: 20px 0;
  }
  .profile-pg-sa-no-address-main-wrap {
    padding: 18px 0 5px;
  }
  .pp-na-ttl {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
  }
  .pp-na-text {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-pg-inner-no-add img {
    margin-bottom: 34px !important;
  }
  .pp-sa-no-add-wrap {
    margin-bottom: 30px !important;
  }
  .pp-no-addr-btn {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
  }
  .profile-pg-address-list {
    padding: 20px;
    margin-bottom: 14px;
  }
  .pp-sa-action-wrap {
    position: initial !important;
    transform: none !important;
    width: 100%;
  }
  .pp-sa-action-wrap .pp-sa-delet img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .pp-sa-action-wrap .pp-sa-edit,
  .pp-sa-action-wrap .pp-sa-delet {
    width: 50%;
    text-align: center !important;
  }
  .pp-sa-all-addres-list li .pp-sa-info-wrap {
    margin: 0 0 16px !important;
    padding: 0 20px 0 15px;
  }
  .pp-sa-action-wrap {
    padding-top: 10px;
    border-top: solid 1px #f2f6f8;
    margin-top: 16px;
  }
  .pp-sa-type {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.2;
  }
  .pp-sa-address {
    font-size: 15px;
    line-height: 1.2;
  }
  .pp-sa-action-wrap .btn {
    font-size: 15px;
    line-height: 1.2;
  }
  .read-delete-buttons {
    float: none;
  }
}

/*Order Detail css*/
.text-right {
    text-align: right !important;
  }
  .text-right-wishlist svg {
    width: 25px;
    height: 25px;
    margin-right: 1rem;
  }
  .pp-order-product-ttl {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-black);
    cursor: pointer;
    line-height: 1.17;
  }
  .order-ul-list-none {
    list-style: none;
  }
  .profile-pg-order-main-wrap {
    padding: 145px 0;
  }
  .radius-10 {
    border-radius: 10px;
  }
  .profile-pg-inner-wrap.yt-my-order-wrap {
    padding: 30px;
  }
  .profile-pg-inner-wrap {
    border: 1px solid #e6e6e6;
  }
  .op-order-quantity {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    color: var(--color-dark-grey);
  }
  .op-order-product-price {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 1.08px;
    color: var(--color-green);
    padding-top: 41px;
  }
  .op-order-product-total-price {
    font-size: 16px;
    line-height: 1.14;
    letter-spacing: 1.08px;
    color: var(--color-black);
    /* padding-top: 41px; */
    padding-top: 2.85%;
    text-align: end;
  }
  
  .order-prodict-type-container {
    max-width: 30vw;
    overflow-x: auto;
  }
  @media (max-width: 767.88px) {
    .order-prodict-type-container {
      max-width: 52vw;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 576px) {
    .order-prodict-type-container {
      max-width: 38vw;
      margin-bottom: 10px;
    }
  }
  
  /* width */
  .order-prodict-type-container::-webkit-scrollbar {
    height: 5px;
  }
  
  /* Track */
  .order-prodict-type-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .order-prodict-type-container::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .order-prodict-type-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .order-prodict-type th,
  .order-prodict-type td {
    padding: 3px;
  }
  .order-prodict-type th:first-child,
  .order-prodict-type tr td:first-child {
    padding-left: 0 !important;
  }
  .order-prodict-type th,
  .order-prodict-type tr td {
    padding-left: 44px !important;
    white-space: nowrap;
  }
  .order-prodict-type th {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
  }
  .order-prodict-type td {
    font-size: 17px;
    line-height: 1.24;
    color: var(--color-black);
  }
  
  .od-product-img {
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    max-width: 133px;
  }
  .order-product-info {
    padding-left: 20px;
  }
  .yt-order-wrapper-box .order-product-quanity {
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 15px;
  }
  .yt-order-wrapper-box {
    position: relative;
  }
  .order-number-wrap .order-tag,
  .order-date-wrap .order-tag {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    color: var(--color-dark-grey);
  }
  .order-number-wrap .order-tag-val,
  .order-date-wrap .order-tag-val {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: var(--color-black);
  }
  .order-bdr-between {
    width: 2px;
    background-color: #f6f8fa;
    margin: 0 15px;
    height: 18px;
  }
  .order-write-review {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: right;
    color: var(--button-primary) !important;
    text-decoration: underline;
    padding: 0 !important;
  }
  .order-cancel-btn {
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.69px;
    text-align: left;
    color: var(--color-orange);
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .pp-od-no-ttl {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-black);
  }
  .pp-od-no-text.mb-0 {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey);
  }
  .pp-no-order-btn {
    opacity: 0.99;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    border-color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: #ffffff;
    width: 100%;
    max-width: 200px;
  }
  .profile-pg-inner-wrapper .yt-order-wrapper-box:last-child {
    padding-bottom: 0 !important;
  }
  .order-product-info {
    padding-left: 40px;
  }
  
  .profile-pg-order-main-wrap img {
    max-height: 217px;
  }
  
  /**/
  .yt-order-wrapper-box .order-product-quanity .yt-qt-prc li {
    width: 100%;
    text-align: right;
  }
  .yt-order-wrapper-box .order-product-quanity .yt-qt-prc {
    height: 100%;
  }
  /**/
  @media (min-width: 1900px) {
    .pp-order-product-ttl {
      line-height: 1.67;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap {
      padding: 40px;
    }
  
    .order-main-wrapper .profile-mo-dt-wrap {
      padding-left: 173px;
    }
  }
  @media (max-width: 1899.98px) {
    .pp-order-product-ttl {
      max-width: 300px;
    }
  }
  
  @media (max-width: 1900px) and (min-width: 1366px) {
    .order-number-wrap .order-tag,
    .order-date-wrap .order-tag,
    .order-number-wrap .order-tag-val,
    .order-date-wrap .order-tag-val {
      font-size: 14px;
      line-height: 1.36;
    }
    .order-write-review {
      font-size: 16px;
      line-height: 1.19;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .order-prodict-type th {
      font-size: 12px;
      line-height: 1.17;
      letter-spacing: 0.46px;
    }
    .order-prodict-type td {
      font-size: 16px;
      line-height: 1.31;
    }
    .od-product-img {
      max-width: 95px;
      padding: 11px !important;
    }
    .od-product-img img {
      width: 72px;
      height: 72px;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: top;
         object-position: top;
    }
    .order-product-info {
      padding-left: 20px;
    }
    .btn.od-cancel-btn {
      color: #e65e52;
      padding-right: 0 !important;
      padding-left: 0 !important;
      font-size: 18px;
      line-height: 1.11;
      letter-spacing: 0.69px;
      text-decoration: none !important;
    }
    .profile-pg-order-main-wrap {
      padding: 45px 0;
    }
    .profile-pg-order-main-wrap img {
      max-height: 180px;
      margin-bottom: 30px !important;
    }
    .pp-sa-order-wrap {
      margin-bottom: 30px !important;
    }
    .pp-no-order-btn {
      font-size: 16px;
      line-height: 1.13;
    }
    .profile-mo-dt-wrap {
      padding-left: 115px;
    }
    .yt-order-wrapper-box.yt-border-order {
      border-top: solid 1px #cae2fe;
      padding-top: 30px !important;
      margin-top: 15px;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap {
      margin-bottom: 20px !important;
    }
  }
  /*
    * Screen for under 1280 Resolution 
    */
  @media (max-width: 1279.92px) {
    .profile-pg-order-main-wrap img {
      margin-bottom: 40px !important;
    }
    .pp-sa-order-wrap {
      margin-bottom: 40px !important;
    }
    .pp-od-no-ttl {
      font-size: 24px;
      line-height: 1.17;
    }
    .pp-od-no-text {
      font-size: 14px;
      line-height: 1.14;
    }
    .pp-no-order-btn {
      font-size: 16px;
      line-height: 1.13;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .profile-pg-order-main-wrap {
      padding: 40px 0;
    }
    .od-product-img {
      max-width: 70px;
      align-self: flex-start;
    }
    .order-product-info {
      padding-left: 10px;
    }
    .pp-order-product-ttl {
      font-size: 16px;
      line-height: 1.13;
      max-width: 200px;
    }
    .yt-my-order-wrap .order-prodict-type th {
      font-size: 12px;
      line-height: 1.17;
      letter-spacing: 0.38px;
    }
    .yt-my-order-wrap .order-prodict-type td {
      font-size: 16px;
      line-height: 1.31;
    }
    .yt-my-order-wrap .op-order-quantity {
      font-size: 14px;
      line-height: 1.36;
    }
    .yt-my-order-wrap .op-order-product-price .op-order-product-total-price {
      font-size: 20px;
      line-height: 1.15;
    }
    .op-order-product-total-price {
      padding-top: 5%;
    }
    .yt-order-wrapper-box .order-product-quanity {
      top: 15px;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag,
    .yt-my-order-wrap .order-date-wrap .order-tag {
      font-size: 12px;
      line-height: 1.58;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag-val,
    .yt-my-order-wrap .order-date-wrap .order-tag-val {
      font-size: 12px;
      line-height: 1.58;
    }
    .order-write-review {
      font-size: 14px;
      line-height: 1.36;
      padding: 0;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap {
      padding: 18px 15px;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord {
      padding-bottom: 40px;
    }
    .order-bdr-between {
      margin: 0 7.5px;
    }
  }
  @media (max-width: 850px) and (min-width: 768px) {
    .yt-my-order-wrap .order-number-wrap .order-tag-val,
    .yt-my-order-wrap .order-date-wrap .order-tag-val {
      display: block;
    }
  }
  /*
    * Screen for under 768 Resolution 
    */
  @media (max-width: 767.92px) {
    .profile-pg-inner-wrap.profile-pg-inner-no-order {
      padding: 20px 9px;
    }
    .profile-pg-order-main-wrap {
      padding: 0;
    }
    .profile-pg-order-main-wrap img {
      max-height: 165px;
      margin-bottom: 16px !important;
    }
    .pp-od-no-ttl {
      font-size: 17px;
      line-height: 1.12;
    }
    .pp-od-no-text {
      font-size: 15px !important;
      line-height: 1.2 !important;
      max-width: 233px;
      margin: 0 auto;
    }
    .pp-no-order-btn {
      font-size: 14px;
      line-height: 1.14;
      width: 100%;
      max-width: 100%;
    }
    .pp-sa-order-wrap {
      margin-bottom: 30px !important;
    }
    /* yt-my-order-wrap */
    .profile-pg-inner-wrap.yt-my-order-wrap {
      padding: 15px;
      position: relative;
      margin: 67px 0;
    }
    .yt-my-order-wrap .od-product-img {
      max-width: 70px;
    }
    .yt-my-order-wrap .order-product-info {
      padding-left: 16px;
    }
    .yt-my-order-wrap .pp-order-product-ttl {
      font-size: 14px;
      line-height: 1.36;
      max-width: 160px;
    }
    .yt-my-order-wrap .order-prodict-type th {
      font-size: 10px;
      line-height: 1.1;
      letter-spacing: 0.38px;
    }
    .od-cancel-btn {
      padding: 0;
    }
    .order-prodict-type th,
    .order-prodict-type td {
      padding: 5px;
    }
    .yt-my-order-wrap .order-prodict-type td {
      font-size: 14px;
      line-height: 1.5;
    }
    .yt-my-order-wrap .op-order-quantity {
      font-size: 10px;
      line-height: 1.9;
    }
    .yt-my-order-wrap .op-order-product-price .op-order-product-total-price {
      font-size: 15px;
      line-height: 1.2;
    }
    .op-order-product-total-price {
      padding-top: 10%;
    }
    .profile-pg-inner-wrap .yt-my-order-tdet-wrap {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      margin: 10px 0px;
      align-items: flex-start !important;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag,
    .yt-my-order-wrap .order-date-wrap .order-tag {
      font-size: 12px;
      line-height: 1.58;
    }
    .yt-my-order-wrap .order-number-wrap .order-tag-val,
    .yt-my-order-wrap .order-date-wrap .order-tag-val {
      font-size: 12px;
      line-height: 1.58;
    }
    .yt-my-order-wrap .order-bdr-between {
      display: none !important;
    }
    .yt-my-order-wrap .profile-mo-dt-wrap {
      max-width: 60%;
    }
    .yt-my-order-wrap .order-write-review {
      padding: 0 !important;
      font-size: 14px;
      line-height: 1.36;
    }
    .yt-my-order-wrap .order-cancel-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.54px;
      position: absolute;
      bottom: -25px;
      right: 0;
      padding: 0;
    }
    .yt-order-wrapper-box {
      padding-top: 0 !important;
    }
    .yt-order-wrapper-box .order-product-quanity {
      top: 0;
      bottom: 5px;
    }
    .profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord {
      padding-bottom: 40px;
    }
  }
  
.dis-acc-title-bar .modal-title {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey) !important;
  }
  .dis-acc-body-text {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey) !important;
  }
  .dis-acc-title-bar span {
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--color-black);
  }
  .pp-dis-acc-btn-modal {
    width: 48%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent !important;
    border-color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
  }
  .dis-acc-bottom-bar {
    position: relative;
    border-top: 2px solid #dbdbdb !important;
  }
  .pp-dis-acc-btn-modal:hover {
    border-color: #fff;
  }
  .pp-dis-acc-btn-light-grey {
    color: var(--color-dark-grey) !important;
  }
  .pp-dis-acc-btn-dark-grey {
    color: var(--color-black) !important;
  }
  .yt-form-spacer {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    display: block;
    bottom: 10px;
    width: 2px;
    top: 10px;
    background: #dbdbdb;
  }
  
  /*
    * Screen for under 1280 Resolution 
    */
  @media (max-width: 1279.92px) {
    .dis-acc-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .dis-acc-body-text {
      line-height: 1.2;
      font-size: 15px;
      padding-top: 11px !important;
    }
    .pp-dis-acc-btn-modal {
      font-size: 15px;
      line-height: 1.2;
    }
  }
  /*
    * Screen for under 768 Resolution 
    */
  @media (max-width: 767.92px) {
    .dis-acc-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .dis-acc-body-text {
      line-height: 1.13;
      font-size: 16px;
      padding-top: 0 !important;
    }
    .pp-dis-acc-btn-modal {
      font-size: 15px;
      line-height: 1.2;
    }
  }
  
.yt-forgot-pw-wrap {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5 {
  margin-bottom: 50px;
}
.yt-fpp-mt-5 {
  margin-top: 50px;
}
.yt-fp-top-back-tag-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: black;
}
.yt-fp-back-icn {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}

.yt-fp-tag-line {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 30px;
  margin-top: 16px;
}
.yt-fp-tag-line-2 {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 10px;
}
.navigate {
  cursor: pointer;
}
.yt-fp-form .form-group {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2 {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control {
  padding-left: 65px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-dark-black);
}
.yt-fp-skip-btn {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer {
  font-size: 18px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn {
  font-size: 18px;
  line-height: 1.14;
  color: var(--color-orange);
  text-decoration: underline;
  box-shadow: none !important;
}
.yt-fp-form .error.form-group .form-control {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -18px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show {
  color: var(--color-green);
  display: block;
}
.error.form-group,
.success.form-group {
  margin-bottom: 43px !important;
}
.invalid {
  color: var(--color-orange);
}
.invalid-input {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}
.normal-input:active {
  border: 1px solid var(--color-blue) !important;
}

.yt-forgot-pass-vie-icn {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success {
  color: green;
}
.mt-20 {
  margin-top: 20px;
}
/**/
@media (min-width: 1400px) {
  .yt-forgot-pw-wrap {
    max-width: 600px;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

.yt-lp-mt-5 {
  margin-top: 50px;
}
.yt-lp-mt-4 {
  margin-top: 40px;
}

.yt-lp-mb-5 {
  margin-bottom: 50px;
}

.yt-login-wrap {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.yt-login-list-style-none {
  list-style-type: none;
}

.lp-tabs-name li {
  font-size: 18px;
  line-height: 0.94;
  color: var(--color-dark-grey);
  padding: 0 15px 15px;
  cursor: pointer;
}

.lp-tabs-name li:first-child {
  margin-right: 10px;
}

.lp-tabs-name li.active {
  color: var(--color-RegularActiveText);
  /* color: black; */
  font-weight: bold;
  border-bottom: 2px solid var(--color-RegularActiveText);
  line-height: 1.11;
}

.yt-signup-icn,
.yt-login-icn {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn2 {
  max-width: 24px;
  position: absolute;
  left: 20px;
}

.yt-login-icn3 {
  max-width: 24px;
  position: absolute;
  top: 18%;
  transform: translateY(-50%);
  left: 20px;
}

.yt-signup-form .form-group,
.yt-login-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.yt-signup-form .form-group .form-control,
.yt-login-form .form-group .form-control {
  padding-left: 65px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  box-shadow: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-blue);
  border: solid 1px #cae2fe;
}

.yt-signup-form .form-group .form-control:focus,
.yt-login-form .form-group .form-control:focus {
  border: solid 1px var(--color-blue);
}

.yt-sign-up-message-show,
.yt-login-message-show {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  position: absolute;
  bottom: -20px;
  display: none;
}

.yt-signup-form .form-group.error .yt-sign-up-message-show,
.yt-login-form .form-group.error .yt-login-message-show {
  display: block;
  color: var(--color-orange);
}

.yt-signup-form .form-group.error .form-control,
.yt-login-form .form-group.error .form-control {
  border-color: var(--color-orange);
}

.yt-signup-bottom-info,
.yt-login-bottom-info {
  margin-top: 15px;
}

.yt-signup-via-tag {
  margin: 20px 0;
}

.yt-login-via-tag {
  margin: 30px 0;
}

.yt-signup-pass-info,
.yt-login-pass-info {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  color: var(--color-black);
  position: absolute;
  bottom: -20px;
}

.yt-signup-btn,
.yt-login-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  /* color: var(--button-text-primary); */
  color: var(--color-ButtonTextRegular);
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  /* background-color: var(--button-primary); */
  background-color: var(--color-ButtonBackRegular);
  border-color: var(--button-primary);
}

.yt-signup-btn:hover,
.yt-login-btn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.yt-signup-tag-line,
.yt-login-tag-line {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 27px;
  margin-top: 23px;
}

.yt-sign-up-pass-vie-icn,
.yt-login-pass-vie-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-login-pass-vie-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-signup-via-tag,
.yt-login-via-tag {
  font-size: 14px;
  line-height: 1.14;
  color: silver;
}

.yt-signup-via-fb,
.yt-login-via-fb {
  background-color: white;
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-blue);
  line-height: 1.11;
  border: white;
}

.lp-bottom-links {
  margin-top: 17px;
}

.yt-signup-via-email,
.yt-login-via-email {
  padding: 15px 24px;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-orange);
  line-height: 1.11;
  background-color: white;
  border-color: white;
}

.yt-signup-aggre-tag,
.yt-login-aggre-tag {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--color-dark-grey);
  margin-bottom: 10px;
}

.yt-signup-term-privacy-link,
.yt-login-term-privacy-link {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}

.yt-signup-skip-btn,
.yt-login-skip-btn {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-login-bg-banner {
  /* background-image: url(../images/login-image.jpg); */
  background-size: cover;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}

.yt-signup-oi-tag-small,
.yt-login-oi-tag-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  margin-bottom: 22px;
}

.yt-signup-oi-tag-big,
.yt-login-oi-tag-big {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.3;
  color: #ffffff;
}

.yt-signup-sp-now,
.yt-login-sp-now {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  max-width: 180px;
  margin-top: 56px;
}

.yt-sign-other-info,
.yt-login-other-info {
  position: absolute;
  right: 0;
  bottom: 10%;
  padding: 0 40px;
}

.yt-signup-bdr,
.yt-login-bdr {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 80px;
  right: -40px;
}

.yt-login-forget-pass {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: var(--color-primary);
  display: inline-block;
  cursor: pointer;
}

.yt-login-inner-content.yt-login-bg-banner.yt-login-col {
  width: 45%;
}

.yt-login-inner-content.yt-login-form-wrapper {
  padding-left: 0px;
  width: 100%;
}

.yt-signup-form img,
.yt-login-form img,
.yt-fp-form img {
  top: 50% !important;
  transform: translateY(-50%);
}
/**/

@media (min-width: 1900px) {
  .yt-login-col {
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .yt-login-wrap {
    max-width: 600px;
  }
}

.login-on-cart-page .container {
  max-width: 100%;
  padding: 0;
}
/*
  * Cart Page Login Component CSS
  */

.login-on-cart-page .lp-bottom-links {
  display: none;
}

.login-on-cart-page .yt-sign-up-message-show {
  bottom: -20px;
}

.login-on-cart-page .yt-login-wrap {
  margin-top: 0 !important;
  max-width: 100%;
  margin-bottom: 18px;
}

@media (min-width: 1367px) {
  .login-on-cart-page.login-box-top {
    display: none;
  }
}

@media (max-width: 1367px) {
  .login-on-cart-page.login-box-bottom {
    display: none;
  }
}

.err_invalid {
  color: #dc3545;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
}
/*
  * Screen for under 1280 Resolution   Tab
  */

@media (max-width: 1279.92px) {
  .yt-login-forget-pass {
    padding: 0;
  }
  .yt-login-inner-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-via-tag {
    margin: 20px 0;
  }
  .yt-login-wrap {
    margin-top: 37px;
  }
  .yt-login-inner-content.yt-login-col {
    width: 100% !important;
  }
  .yt-login-wrap {
    max-width: 588px;
    padding: 0;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 30px 30px;
    order: -1;
  }
  .yt-login-bg-banner {
    min-height: 210px;
    background-position: 100% 35%;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.15;
    font-size: 48px;
  }
  .yt-sign-other-info,
  .yt-login-other-info {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 30px;
    line-height: 1.13;
    font-size: 16px;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    max-width: 150px;
  }
  .yt-signup-bdr,
  .yt-login-bdr {
    bottom: 63px;
  }
  .lp-tabs-name li {
    font-size: 16px;
    line-height: 1.13;
    padding: 0 12px 7.5px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 14px;
    line-height: 1.14;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    margin-bottom: 10px !important;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-signup-btn {
    margin-top: 47px;
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .yt-signup-pass-info {
    bottom: -24px !important;
  }
  .yt-signup-form {
    margin-bottom: 20px !important;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 150px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 30px;
  }
  .yt-login-form {
    margin-bottom: 20px !important;
  }
  .yt-login-bottom-info {
    margin-top: 20px !important;
  }
  /* .yt-skip-wrap {
    display: none;
  } */
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 32px 100px 30px;
  }
}
/*
  * Screen for under 768 Resolution Mobile
  */

@media (max-width: 767.98px) {
  .yt-signup-form .invalid-feedback,
  .yt-login-form .invalid-feedback {
    margin: 6px 0 0;
  }
  .lp-tabs-name li {
    font-size: 14px;
    line-height: 1.21 !important;
  }
  .lp-tabs-name li.active {
    line-height: 1.14 !important;
  }
  .yt-login-wrap {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .yt-login-wrap {
    max-width: 335px;
  }
  .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }
  .yt-signup-oi-tag-small,
  .yt-login-oi-tag-small {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-signup-oi-tag-big,
  .yt-login-oi-tag-big {
    line-height: 1.13;
    font-size: 30px;
  }
  .yt-signup-sp-now,
  .yt-login-sp-now {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding: 14px 27px !important;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 11px;
  }
  .yt-signup-form .form-group .form-control,
  .yt-login-form .form-group .form-control {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 13px;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 13px;
    line-height: 1.15;
  }
  .yt-signup-btn {
    margin-top: 41px;
  }
  .yt-login-btn {
    margin-top: 20px !important;
  }
  .yt-signup-form {
    margin-bottom: 15px !important;
  }
  .yt-signup-skip-btn,
  .yt-login-skip-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-via-tag,
  .yt-login-via-tag {
    font-size: 11px;
    line-height: 1.18;
    margin: 15px 0;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb,
  .yt-signup-via-email,
  .yt-login-via-email {
    min-width: 110px;
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    padding-left: 10px;
  }
  .yt-signup-via-email,
  .yt-login-via-email {
    padding-left: 15px;
  }
  .yt-signup-wrap .lp-bottom-links {
    margin-top: 20px;
  }
  .yt-signup-aggre-tag,
  .yt-login-aggre-tag {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 14px;
    line-height: 1.14;
  }
  .login-on-cart-page
    .yt-login-inner-content.yt-login-col.yt-login-form-wrapper {
    padding: 15px 20px 20px;
  }

  .yt-signup-form .yt-form-err,
  .yt-login-form .yt-form-err {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .yt-login-wrap {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100%;
  }
  .yt-signup-pass-info {
    bottom: -32px !important;
  }
  .err_invalid {
    font-size: 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-signup-form .form-group .form-control {
    font-size: 16px;
  }
  .yt-signup-via-fb,
  .yt-login-via-fb {
    font-size: 16px;
  }
  .yt-signup-via-email {
    font-size: 16px;
  }
  .yt-login-wrap {
    padding: 30px 40px;
  }
  .yt-signup-tag-line,
  .yt-login-tag-line {
    margin-bottom: 35px;
    margin-top: 15px;
  }
  .yt-signup-pass-info,
  .yt-login-pass-info {
    bottom: -23px;
  }
  .err_invalid,
  .invalid-feedback {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-signup-btn,
  .yt-login-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-signup-form .form-group,
  .yt-login-form .form-group {
    margin-bottom: 20px;
  }
  .yt-login-bottom-info .yt-login-via-tag {
    margin-top: 19px;
  }
  .yt-signup-term-privacy-link,
  .yt-login-term-privacy-link {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-login-form.mb-4 {
    margin-bottom: 17px !important;
  }
  .yt-login-bottom-info.mt-4 {
    margin-top: 17px !important;
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.subscription-info {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.36;
  text-align: left;
  color: var(--color-green);
}
.head {
  color: var(--color-green);
  font-weight: 500;
}

.sub-head {
  color: var(--color-green);
  font-weight: 500;
}
.subscription_changeBtn {
  color: var(--color-dark-grey);
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent !important;
  border-color: transparent !important;
}
.subscription-tag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f67054;
  color: white;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 10px 4px 4px !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.radius-10 {
  border-radius: 10px;
}

.cart-pg-mb-30 {
  margin-bottom: 30px;
}
.yt-currency-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cart-pg-breadcrumbs {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 30px;
}
.cart-pg-breadcrumbs .cart-pg-home {
  color: var(--color-dark-grey);
}
.cart-pg-breadcrumbs a {
  cursor: pointer;
}
.cart-product {
  padding: 10px;
  border: solid 1px #dbdbdb;
  border-radius: 9px;
  max-width: 140px;
}
table.cart-prodict-type th {
  padding: 10px 0;
  padding-right: 60px;
}
table.cart-prodict-type tbody th {
  font-size: 17px;
  line-height: 1.05;
  color: var(--color-black);
  font-weight: 500;
  padding: 0;
}
.cartno-wrap {
  margin-top: 52px;
  margin-bottom: 40px;
}
.cart-action {
  display: flex;
  justify-content: center;
}
.cart-action .cart-product-delete {
  padding: 0 0 0 20px;
  margin: 0 0 0 20px;
  border-left: 1px solid #ccc;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 500;
  font-size: 16px;
}
.cart-action span {
  cursor: pointer;
  color: var(--color-blue);
  font-weight: 500;
  font-size: 16px;
  min-width: 130px;
}
.btn-link.cart-coupon-change-btn {
  color: var(--color-orange);
  text-decoration: none !important;
}
.btn-secondary.cart-coupon-btn {
  background-color: var(--color-orange);
  border: 2px solid var(--color-orange);
}
.cat-main-wrapper .btn-secondary {
  min-width: 150px;
}
.cart-pg-breadcrumbs .cart-pg-current {
  color: var(--color-black);
  font-size: 12px;
  /* font-weight: 500; */
  font-weight: bold !important;
  line-height: 1.17;
}

.empty-cartn-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 17px;
}

.empty-cart-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.empty-cart-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}

.cart-page-main-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 30px;
}

.cart-product-image {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.cart-product-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
  margin-bottom: 20px;
  max-width: 310px;
}

.cart-prodict-type-container {
  max-width: 17vw;
  overflow-x: auto;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .cart-prodict-type-container {
    max-width: 54vw;
    /* margin-bottom: 28px; */
  }
}

/* width */
.cart-prodict-type-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.cart-prodict-type-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cart-prodict-type-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.cart-prodict-type-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-prodict-type th {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}

.cart-prodict-type th,
.cart-prodict-type td {
  padding: 3px;
}

.cart-product-price {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-black);
  margin-top: 10px;
}

.cart-product-delete svg {
  font-size: 22px;
  color: #8d9096;
  cursor: pointer;
}

.cart-prodict-amount th {
  font-size: 20px !important;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-product-amount-ttl {
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-dark-grey);
  font-weight: normal;
}

.cart-product-amount-price {
  font-size: 18px;
  line-height: 1.11;
  text-align: right;
  color: var(--color-black);
  display: block;
}

.cart-prodict-amount thead th {
  padding-top: 0;
  padding-bottom: 18px;
  padding-left: 0;
  padding-right: 0;
  font-size: 22px;
}

.cart-prodict-amount tbody td,
.cart-prodict-total-amount tbody td,
.ccart-prodict-sub-total-amount tbody td,
.cart-prodict-sub-total-amount tbody td {
  padding: 10px 0;
}

.cart-divider {
  border-bottom: solid 1px #e2e2e2 !important;
  display: block;
  margin: 15px 0 20px;
}

.cart-coupon .form-group {
  position: relative;
}

.cart-coupon .form-control {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}

.cart-coupon-btn {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-orange);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: var(--color-orange);
}

.cart-proceed-to-checkput {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-dark-black);
}

.cart-checkout-tile {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-select-aar-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-blue);
}

.checkout-form-wrap .form-group .form-control {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb !important;
  border-radius: 0;
}

.checkout-form-label {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-dark-grey);
}

.checkout-checkbox .form-group,
.yt-chekout-radio .form-group {
  padding-left: 25px;
  position: relative;
  line-height: 25px;
}

.yt-empty-cart-wrap {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  margin: 0;
}

.cart-pg-inner-wrap .yt-checkout-form-chk-box,
.cart-pg-inner-wrap .yt-checkout-form-rado-box {
  position: absolute;
  cursor: pointer;
  margin: 0;
  left: 0;
  top: 0;
}

.cart-pg-inner-wrap .checkout-checkbox .yt-checkout-form-chk-box:before,
.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}

.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 5px;
}

.yt-checkout-chekbox-label {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-black);
}

.cart-pg-inner-wrap
  .checkout-checkbox
  .form-check-input:checked
  + label:before {
  background-color: var(--color-blue);
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  z-index: 5;
  background: var(--color-blue);
  border-radius: 25px;
  left: 4px;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label:before {
  border-color: var(--color-blue);
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
  bottom: 0;
  height: 100%;
}

.checkout-form-wrap .form-check-label {
  line-height: 1.5;
}

.cart-coupon .error .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}

.cart-coupon .success .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}

.cart-coupon-change-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}

.cart-coupon .cart-coupon-bottom-wrapper {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}

.cart-coupon .error .cart-coupon-bottom-wrapper,
.cart-coupon .success .cart-coupon-bottom-wrapper {
  opacity: 1;
}
form.yt-cart-disct-wrap .yt-form-cpn-err.form-group,
form.yt-cart-disct-wrap .success.form-group {
  margin-bottom: 20px !important;
}
.cart-coupon .success .cart-coupon-bottom-wrapper .error-message,
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message,
.login-on-cart-page .lp-bottom-links {
  display: none;
}

.yt-login-wrap {
  margin-top: 0 !important;
}

.cart-prodict-amount thead th:last-child {
  text-align: right;
}

.cart-prodict-type tr td {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}

.cart-pg-list-image {
  width: 20%;
}

.cart-pg-list-prdt-info {
  width: 75%;
}

.cart-list-other-act {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.cart-quantity-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.checkout-form-wrap .yt-remove-arrow::-webkit-outer-spin-button,
.checkout-form-wrap .yt-remove-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.checkout-form-wrap .yt-remove-arrow[type="number"] {
  -moz-appearance: textfield;
}

.cart-produt-list-wrap {
  padding: 39px 19px 38px;
  /* padding: 40px 40px 28px; */
}

.yt-cart-price-lister {
  padding: 40px 30px 56px;
  margin-bottom: 30px;
}

.checkout-form-wrap .cart-pg-inner-wrap {
  padding: 40px 30px 30px;
}

.yt-cm-lt-col {
  padding-right: 7px;
}

.yt-cm-rt-col {
  padding-left: 7px;
}

@media (min-width: 768px) {
  .cart-proceed-to-checkput.cart-btn-bill-pg {
    display: none;
  }
  .cart-pg-list-image .cart-product-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .cart-pg-list-image .cart-product-image {
    width: 100%;
    height: 133px;
  }
}

@media (min-width: 1280px) {
  .empty-main-ttl {
    display: none;
  }
  .cart-prodict-type th,
  .cart-prodict-type td {
    padding: 3.5px 0;
    padding-right: 74px;
  }
  .cart-prodict-type th:last-child,
  .cart-prodict-type td:last-child {
    padding-right: 0;
  }
}

@media (min-width: 1280.88px) {
  .yt-cm-lt-col {
    padding-right: 10px;
  }
  .yt-cm-rt-col {
    padding-left: 10px;
  }
}

@media (min-width: 1900px) {
  .yt-cm-lt-col {
    padding-right: 20px;
  }
  .yt-cm-rt-col {
    padding-left: 20px;
  }
  .cart-product-title {
    line-height: 1.67;
    max-width: 100%;
  }
  .cart-produt-list-wrap {
    padding: 40px 40px 37px;
  }
}

/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  .empty-cartn-ttl {
    font-size: 24px;
    line-height: 1.17;
  }
  .empty-cart-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .empty-cart-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .yt-empty-cart-wrap.cart-pg-inner-wrap {
    margin-top: 10px !important;
  }
  .cart-page-main-title {
    margin-bottom: 10px !important;
    font-size: 24px;
    line-height: 1.17;
  }
  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
    padding-bottom: 34px;

  }
  .yt-cart-price-lister {
    padding: 20px 15px;
  }
  .cart-product-title {
    font-size: 16px;
    line-height: 1.13;
  }
  .cart-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  .cart-prodict-type tr td {
    font-size: 16px;
    line-height: 1.31;
  }
  .cart-product-price {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
  }
  .cart-prodict-amount thead th:first-child {
    font-size: 18px;
    line-height: 1.11;
  }
  .cart-prodict-amount thead th:last-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
  }
  .cart-product-amount-ttl {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .cart-product-amount-price {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 7.5px 0;
  }
  .cart-divider {
    margin: 15px 0;
  }
  .cart-proceed-to-checkput {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .cart-coupon .form-control {
    /* padding: 12px 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }
  .cart-coupon .error .cart-coupon-code-message {
    font-size: 12px;
    line-height: 1.58;
  }
  .cart-coupon-change-btn {
    font-size: 14px;
    line-height: 1.14;
  }
  .checkout-form-wrap .yt-chekout-radio .yt-currency-wrap.form-group {
    padding-left: 0 !important;
  }
}

/*
* Screen for under 768 Resolution 
*/

@media (max-width: 767.92px) {
  .cart-pg-inner-wrap
    .yt-chekout-radio
    .form-check-input:checked
    + label:after {
    top: 4px !important;
  }
  .cart-pg-list-image .cart-product-image img {
    width: 57px;
    height: 57px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .cart-pg-list-image .cart-product-image {
    width: 70px;
    height: 70 px;
  }
  .cart-checkout-wrap .cart-proceed-to-checkput {
    margin-bottom: 15px;
  }
  .yt-cm-row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .yt-cm-lt-col {
    padding-right: 11px;
    padding-left: 11px;
  }
  .yt-cm-rt-col {
    padding: 0;
  }
  .empty-cartn-ttl {
    font-size: 17px;
    line-height: 1.12;
  }
  .empty-cart-text {
    font-size: 15px;
    line-height: 1.2;
  }
  .empty-cart-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .cart-page-main-title {
    font-size: 18px;
    line-height: 1.11;
  }
  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
    padding-bottom: 34px;
  }
  .cart-product-title {
    font-size: 14px;
    line-height: 1.36;
  }
  .cart-prodict-type th {
    /* font-size: 10px; */
    line-height: 1.1;
    letter-spacing: 0.38px;
  }
  .cart-prodict-type tr td {
    font-size: 14px;
    line-height: 1.5;
  }
  .cart-product-price {
    font-size: 15px;
    line-height: 1.2;
  }
  .cart-product-delete svg {
    font-size: 15px;
  }
  .cart-page-main-title {
    margin-bottom: 10px !important;
  }
  .yt-cart-price-lister {
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
  }
  .cart-prodict-amount thead th:first-child {
    font-size: 16px;
    line-height: 1.19;
  }
  .cart-prodict-amount thead th:last-child {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
  }
  .cart-product-amount-ttl {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
  }
  .cart-product-amount-price {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
  }
  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 8px 0;
  }
  .cart-divider {
    margin: 3px 0 7px;
  }
  .cart-coupon .form-control {
    /* padding: 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }
  .cart-proceed-to-checkput {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .checkout-form-wrap .cart-pg-inner-wrap {
    padding: 20px;
    margin-bottom: 10px;
  }
  .cart-checkout-tile {
    font-size: 15px;
    line-height: 1.2;
  }
  .cart-select-aar-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .checkout-form-label {
    font-size: 12px;
    line-height: 1.17;
  }
  .cart-pg-inner-wrap .checkout-checkbox .form-check-input {
    font-size: 10px;
    line-height: 1.1;
  }
  .checkout-form-wrap .yt-chekout-radio .form-check-label {
    font-size: 12px;
    line-height: 1.17;
  }
  .checkout-form-wrap .yt-chekout-radio .form-check-label .form-check-input {
    margin-top: 0px;
  }
  /* .checkout-form-wrap .yt-chekout-radio > div {
    width: 100%;
    margin-bottom: 10px;
  } */
  .cart-pg-inner-wrap .yt-checkout-form-rado-box {
    top: 6px;
  }
}

@media (max-width: 575.98px) {
  .empty-cart-text {
    max-width: 233px;
    margin-left: auto;
    margin-right: auto;
  }
  .empty-cart-btn {
    width: 100%;
  }
  .cart-pg-list-prdt-info {
    flex-wrap: wrap;
  }
  .cart-list-other-act {
    position: relative;
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .cart-pg-product-list-row {
    flex-direction: column;
  }
  .cart-pg-list-prdt-info {
    width: 100% !important;
    margin-top: 20px;
  }
  .cart-action .cart-product-delete {
    padding: 0 0 0 10px;
    margin: 0 0 0 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-chekout-radio .checkout-form-label {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-inner-wrap .yt-items.item {
    padding-top: 30px;
  }
  .empty-cart-btn {
    font-size: 16px;
  }
  .cart-page-main-title {
    font-size: 26px;
  }
  .cart-proceed-to-checkput {
    font-size: 16px;
  }
  .cart-produt-list-wrap {
    padding: 30px;
    margin-bottom: 20px !important;
  }
  .yt-cart-price-lister {
    padding: 30px;
  }
  .cart-divider {
    margin: 10px 0 !important;
  }
  form.yt-cart-disct-wrap {
    padding: 10px 0 !important;
  }
  .cart-prodict-amount tbody tr:last-child td {
    padding-bottom: 20px;
  }
  table.yt-sub-ttl-tbl-wrap.table tr:first-child td {
    padding-top: 20px;
  }
  table.yt-sub-ttl-tbl-wrap.table {
    margin: 0;
  }
}

.yt-main-contact-us-pg .contactform {
  padding: 25px 20px;
}

.contact-border {
  border: 1px solid #e6e6e6;
}

.yt-main-contact-us-pg .title {
  margin-bottom: 30px;
  color: var(--color-black);
  font-family: "GTWalsheimPro-Medium" !important;
  font-size: 30px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13 !important; 
  letter-spacing: normal;
  text-align: left;
}

.yt-main-contact-us-pg .form {
  background-color: white;
  padding: 50px 40px;
}

.yt-main-contact-us-pg textarea {
  border-radius: 8px;
  padding: 12px;
  margin: 0px 20px;
  width: 100%;
  border-color: #c0bebe;
  font-size: 15px;
  height: 100px;
}

.yt-main-contact-us-pg textarea::-moz-placeholder {
  color: #999;
}

.yt-main-contact-us-pg textarea:-ms-input-placeholder {
  color: #999;
}

.yt-main-contact-us-pg textarea::placeholder {
  color: #999;
}

.yt-main-contact-us-pg .send-button {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
/* form starting stylings ------------------------------- */

.yt-main-contact-us-pg .group {
  position: relative;
  margin-bottom: 30px;
}

.yt-main-contact-us-pg input {
  font-size: 16px;
  padding: 10px 10px 16px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0bebe;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-main-contact-us-pg input:focus {
  outline: none;
}
/* LABEL ======================================= */

.yt-main-contact-us-pg label {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */

.yt-main-contact-us-pg input:focus ~ label,
.yt-main-contact-us-pg input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: black;
}
/* BOTTOM BARS ================================= */

.yt-main-contact-us-pg .bar {
  position: relative;
  display: block;
  width: 100%;
}

.yt-main-contact-us-pg .bar:before,
.yt-main-contact-us-pg .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.yt-main-contact-us-pg .yt-main-contact-us-pg .bar:before {
  left: 50%;
}

.yt-main-contact-us-pg .bar:after {
  right: unset;
}
/* active state */

.yt-main-contact-us-pg input:focus ~ .bar:before,
.yt-main-contact-us-pg input:focus ~ .bar:after {
  width: 100%;
}
/* HIGHLIGHTER ================================== */

.yt-main-contact-us-pg .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */

.yt-main-contact-us-pg input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
/* ANIMATIONS ================ */

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.yt-main-wrapper2 {
  max-width: 770px;
  margin: 0 auto;
  position: relative;
}

.yt-main-contact-us-pg {
  margin-top: 60px;
  margin-bottom: 160px;
}

.yt-contact-send-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  /* color: var(--button-text-primary); */
  color:var(--color-ButtonTextRegular);
  padding: 15px 70px;
  border-radius: 6px;
  background-color: var(--color-ButtonBackRegular);
  /* background-color: var(--button-primary) !important; */
  border-color: var(--button-primary);
  position: absolute;
  bottom: -82px;
  right: 0;
}
.yt-contact-send-btn:hover{
  background-color: var(--color-ButtonBackHover);
  color:var(--color-ButtonTextHover);
}

@media (min-width: 1900px) {
  .yt-main-contact-us-pg {
    margin-top: 80px;
  }
}

@media (min-width: 768px) {
  .yt-cm-mobile-bread {
    display: none;
  }
}
/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  .yt-main-contact-us-pg {
    margin-top: 44px;
    margin-bottom: 110px;
  }
  .yt-main-contact-us-pg .title {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 20px;
  }
  .yt-main-contact-us-pg .form {
    padding: 20px 15px;
  }
  .yt-main-contact-us-pg label {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-contact-send-btn {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    letter-spacing: 0.32px;
  }
  .yt-main-contact-us-pg input {
    padding: 10px 10px 16px 6px;
  }
}
/*
* Screen for under 768 Resolution 
*/

@media (max-width: 767.92px) {
  .pageroute {
    color: gray;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1.17;
  }
  .yt-profile-mb-ttl.profile-pg-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-cm-mobile-bread {
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .yt-main-contact-us-pg {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .yt-main-contact-us-pg .title {
    font-size: 18px;
    line-height: 1.11;
    margin-bottom: 10px;
  }
  .yt-main-contact-us-pg .group {
    padding: 30px 20px;
  }
  .yt-main-contact-us-pg label {
    font-size: 13px;
    line-height: 1.15;
    padding-left: 20px;
  }
  .yt-main-contact-us-pg input {
    padding: 7px 10px 7px 0px;
  }
  .yt-contact-send-btn {
    position: initial;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    margin-top: 20px;
  }
  .yt-main-contact-us-pg .group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-main-contact-us-pg .title {
    font-size: 26px;
  }
}

.radius-10 {
  border-radius: 10px;
}
.orderplc-pg-mb-30 {
  margin-bottom: 30px;
}
/* .orderplc-pg-inner-wrap  */
.conatct .orderplc-pg-success-main-wrap  {
  background: var(--color-green) !important;
}
.yt-order-placed-icn {
  max-height: 135px;
  width: auto;
}
/* .orderplc-wrap  */
.head .orderplc-ttll {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-white) !important;
}
/* .orderplc-wrap  */
.head .orderplc-text1 {
  font-size: 16px;
  line-height: 1.13;
  /* color: white !important; */
  color: var(--color-white) !important;
}
.orderplc-pg-success-main-wrap .orderplc-btn {
  opacity: 0.99;
  border-radius: 6px;
  /* background-color: var(--button-primary) !important; */
  background-color: var(--color-ButtonBackRegular);
  border-color: var(--button-primary) !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  /* color: #ffffff; */
  color: var(--color-ButtonTextRegular);
  width: 100%;
  max-width: 180px;
}
.orderplc-pg-success-main-wrap .orderplc-btn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.orderplc-pg-inner-wrap {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1900px) {
  .orderplc-pg-mb-80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 1280px) {
  .orderplc-pg-inner-wrap {
    margin-top: 60px !important;
    margin-bottom: 60px;
  }
}
/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .orderplc-pg-inner-wrap {
    margin-top: 20px !important;
    margin-bottom: 60px;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .orderplc-pg-inner-wrap {
    margin-top: 15px !important;
    margin-bottom: 15px;
    padding: 20px !important;
  }
  .orderplc-pg-success-main-wrap {
    padding-bottom: 0 !important;
  }
  .yt-order-placed-icn {
    max-height: 85px;
  }
  .orderplc-ttl {
    font-size: 16px;
    line-height: 1.13;
  }
  .orderplc-text {
    line-height: 1.14;
    font-size: 14px;
  }
  .orderplc-btn {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
  }
}

.item-slider {
  padding: 15.7px 0 0 1px;
  border-radius: 4px;
  background-color: transparent;
  height: 100%;
  /* margin-right: 30px; */
  max-width: 284px;
  width: 100%;
}
.yt-component-wrapper {
  position: relative;
  display: flex;
}
.item-slider .product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.product-details {
  text-align: center;
  margin-top: 20px;
}
.product-details .product-title {
  font-size: 18px;
  font-weight: 400;
  color: #1c1d1f;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
}
.product-details .ellises {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-details .price-wrap {
  padding: 6px 0;
}
.product-details .product-sale-price {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #30458c;
}
.product-off {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #038b57;
  margin-left: 10px;
}
.product-details .product-reg-price2 {
  font-size: 16px;
  line-height: 1.13;
  text-decoration: line-through;
  color: #f33;
  margin-right: 10px;
}
.product-details .ratings {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-details .ratings span {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}
.product-details .ratings .product-rating {
  color: #8b8f95;
}
.product-details .ratings .rating-star-icon {
  margin: 0 5px;
}

.product-details .button-cart {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  /* color: #30458c; */
  color: var(--color-ButtonTextRegular);
  /* background: transparent; */
  background: var(--color-ButtonBackRegular);
  border: 0;
  border-top: 1px solid #e2e2e2;
  border-radius: 0 0 4px 4px;
  padding: 15.7px 15px 15px 30px;
  width: 100%;
  margin-top: 20px;
}

.product-details .button-cart:hover,
.product-details .button-cart:active,
.product-details .button-cart:visited {
  /* color: #fff; */
  color: var(--color-ButtonTextHover);
  /* background: #30458c; */
  background-color: var(--color-ButtonBackHover);
  border-top: 1px solid #30458c;
}
.rec-item-wrapper {
  height: 100%;
}

.product-descrip {
  /* width: 1920px; */
  height: auto;
  /* padding: 0 0 18px; */
  padding-right: 50px;
  padding-left: 50px;
  background-color: #f6f8fa;
}

.Like-btn {
  cursor: pointer;
  margin-right: 10px;
}
.Like-btn img {
  width: 25px;
}
.btn-link {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline !important;
  color: var(--color-primary);
}

/*
* YT Product Slider CSS
*/

.yt-product-fav {
  font-size: 24px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-product-off {
  /* background-color: yellow; */
  background-color: var(--color-red);
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-primary);
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}

.yt-product-slider-cpnt {
  border: 1px solid white;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  /* padding-bottom: 50px; */
  transition: all 0.55s ease;
}

.product-slider-cpnt:hover {
  /* box-shadow: 0 10px 50px -12px hsl(0deg 0% 67% / 90%);
  transform: translateY(-10px); */
  transition: all 0.55s ease;
}

.yt-component-wrapper {
  border-radius: 10px;
  position: relative;
}

.rec-item-wrapper {
  outline: none !important;
  box-shadow: none;
}

.yt-slider-left,
.yt-slider-right {
  position: absolute;
  font-size: 35px;
  cursor: pointer;
  color: var(--slider-arrow);
}

.yt-item-slider {
  position: relative;
  width: 100%;
}

.slider-container {
  width: 100%;
  margin: 0px 10px;
  padding-top: 20px;
}

.box-carousal .item-slider {
  padding: 15.7px 0 0 1px;
  border-radius: 4px;
  background-color: transparent;
  height: 100%;
  /* margin-right: 30px; */
}

.yt-product-title {
  font-size: 18px;
  line-height: 0.95;
  font-weight: bold;
  color: var(--color-black);
}

.yt-product-code {
  color: silver;
}

.yt-product-rating {
  color: dimgray;
}

.rating-star-icon {
  color: yellow;
  vertical-align: text-top;
}

.yt-component-more {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}

.yt-comonent-top-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}

.yt-td-product-img {
  cursor: pointer;
  width: 184px;
  height: 184px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  margin: 0 auto;
}

.yt-product-sale-price {
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

.yt-product-reg-price2 {
  padding-left: 8px;
  color: darkgray;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-decoration: line-through;
}

.yt-product-add-btn {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.13;
  letter-spacing: 0.36px;
  color: dimgray;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border: 0px;
  border-top: 1.5px solid lightgray;
  background-color: white;
}
.yt-item-slider:hover .yt-product-add-btn {
  background-color: var(--color-blue);
  color: var(--color-white);
  border: 0px;
}

.yt-produstslider-info {
  padding-bottom: 0px;
}

.yt-top-offer-whl {
  min-height: 42px;
}
.hp-product-slider {
  margin: 60px 20px;
}
.yt-head-mb-search-panel {
  display: none;
}
.list-group-flush {
  max-height: 500px;
  overflow: auto;
}

/* width */
.list-group-flush::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.list-group-flush::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 2px;
}

/* Handle */
.list-group-flush::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.list-group-flush::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .yt-head-mb-search-panel .yt-recent-search-wrap {
  display: none;
} */
@media (min-width: 1900px) {
  .yt-product-add-btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
  }
  .yt-item-slider {
    margin: 0 15px;
  }
}

@media (min-width: 1281px) {
  .yt-slider-left,
  .yt-slider-right {
    top: 50%;
    transform: translateY(-50%);
  }
  .yt-slider-left {
    left: -45px;
  }
  .yt-slider-right {
    right: -45px;
  }
}

@media (max-width: 1280px) {
  .yt-slider-left,
  .yt-slider-right {
    top: -56px;
  }
  .yt-slider-left {
    right: 43px;
  }
  .yt-slider-right {
    right: 0;
  }
  .yt-comonent-link {
    padding-right: 97px;
  }
}

.yty-bg-3banner {
  min-height: 250px;
  background-size: cover;
  background-position: 100%;
  position: relative;
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-product-title {
    font-size: 18px;
    padding-top: 5px;
  }
  .yt-product-slider-cpnt .price.yt-price-wrap {
    margin-bottom: 7px;
  }
  .yt-product-off {
    font-size: 16px;
    min-width: 50px;
  }
  .yt-comonent-top-title {
    font-size: 26px;
  }
}

@media (max-width: 1279.92px) {
  .yty-bg-3banner {
    /* min-height: 150px; */
    height: 100%;
    background-position: center;
  }
}

.product-inner {
  height: 400px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 15px 18px rgb(0 0 0 / 7%);
}
.product-inner > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0px !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-inner > div {
  z-index: 11;
  position: relative;
}
@media(max-width: 570px){
  .rec-carousel{
    justify-content: center;
  }
  .box-carousal .item-slider{
    max-width: 100% !important;
  }
  .item-slider,
  .product-slider .slider-container{
    max-width: 100% !important;
    width: 100% !important;
  }
  .filtertop{
    margin-top: 0px !important
  }
}
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Bold.8ee87a5a.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* regular */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Regular.550c7b05.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* light */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Light.3745e321.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* medium */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Medium.f2f36c73.ttf) format('truetype');
    font-style: normal;
    font-display: swap;
}

/* thin */
@font-face {
    font-family: 'GTWalsheimPro';
    src: url(/static/media/GTWalsheimPro-Thin.da1833ee.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}




/*
* Hero Banner CSS
*/
/* body * {
  font-family: GTWalsheimPro, Arial, Helvetica, sans-serif !important;
} */
body {
  font-family: "GTWalsheimPro" !important;
}
.pp-product-details-overflow {
  max-height: 40vh;
  overflow-y: scroll;
}
.pp-product-details-overflow::-webkit-scrollbar {
  width: 5px;
}

.pp-product-details-overflow:-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 2px;
}

.pp-product-details-overflow::-webkit-scrollbar-thumb {
  background: #888;
}

.pp-product-details-overflow::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main-container {
  max-width: 1680px;
  width: 100%;
  padding-right: 55px;
  padding-left: 55px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}
.notify-disabled {
  opacity: 0.5 !important;
}
.container-flex {
  max-width: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}

.radius-10 {
  border-radius: 10px;
}

.list-style-none {
  list-style: none;
}

.border-top-grey {
  border-top: 2px solid #dbdbdb;
}
.sp-description-title {
  font-size: 15px;
  line-height: 1.13;
  color: #8b8f95;
}

.sp-breadcrumbs {
  margin-bottom: 46px;
}

.pageroute.sp-breadcrumbs span {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}

.pageroute.sp-breadcrumbs .sp-current {
  color: var(--color-black);
  /* font-weight: 500; */
  font-weight: bold;
}

.pageroute.sp-breadcrumbs .intialPage:hover {
  color: var(--color-sky-blue) !important;
  font-weight: bold !important;
}

.yt-product-code {
  color: silver;
  font-weight: 300;
}

.yt-product-rating {
  color: dimgray;
}

.rating-star-icon {
  color: #c20683;
  vertical-align: text-top;
}
.user-review {
  display: flex;
  flex-direction: column;
  margin-right: 27px;
  align-items: flex-start;
}
.user-review .feedback {
  max-width: 100px;
  max-height: 50px;
}

.review-date {
  font-size: 12px;
  color: #8b8f95;
  text-align: right;
}

.variant-dropdown-title {
  margin-top: 8px;
  margin-bottom: 4px;
}

.variant-dropdown {
  color: #b2b3b3;
  border-color: #b2b3b3;
  min-width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.variant-dropdown-icon {
  font-size: 18px;
  font-weight: 700;
}

/*Review module*/

.feedback-container {
  padding: 0;
}

.rating {
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  flex-direction: row-reverse;
  height: 40px;
  position: relative;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating > input {
  display: none;
}

.rating > label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: 0.3s;
}
.star-image {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.total-data {
  display: flex;
  align-items: center;
}
.total-review {
  width: 30%;
}
.rating-number {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.13;
  color: #3e454f;
}
.user-comments {
  padding: 30px 0 0 0;
  margin: 30px 0 0 0;
  border-top: 1px solid #dbdbdb;
}
.user-comments-yscroll {
  padding: 30px 0 0 0;
  margin: 30px 0 0 0;
  border-top: 1px solid #dbdbdb;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
  width: auto;
}
.user-comments-yscroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.user-comments-yscroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 2px;
}

/* Handle */
.user-comments-yscroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.user-comments-yscroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.user-data img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.user-data {
  display: flex;
  padding-bottom: 30px;
  align-items: center;
}

.user-data .user-name {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.15;
  color: #3e454f;
  text-transform: capitalize;
}

.user-data .user-name span.comment {
  font-size: 13px;
  margin-top: 5px;
  color: #8b8f95;
}

.rating-based {
  font-size: 13px;
  line-height: 1.14;
  margin-top: 0;
  color: #8b8f95;
}

.rate-product {
  width: 50%;
  text-align: center;
  margin-left: 40px;
  /* border-left: 1px solid #ccc; */
}

.total-review span {
  font-size: 16px;
  line-height: 1.75;
  color: #3e454f;
}
button.out-stock {
  color: #ccc;
  pointer-events: none;
  position: relative;
}

button.out-stock:after {
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  left: -5px;
  transform: rotate(34deg);
  border-bottom: 1px solid lightgray;
  height: 1px;
  width: 58px;
}
.rating-range {
  background: #ccc;
  width: 80%;
  max-width: 250px;
  height: 4px;
  border-radius: 6px;
  position: relative;
}
.rating-range span {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  height: 4px;
}
.rating-range.yellow-bg span {
  background-color: #e88a0c;
}

.rating-range.blue-bg span {
  background-color: var(--color-primary);
}
.rating-range.red-bg span {
  background-color: #f67054;
}
.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.feedback {
  max-width: 130px;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  width: 12.4px;
  height: 12.4px;
  margin: 7.8px 15.5px 10.8px 1.2px;
}
.rate-review h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: #3e454f;
}
h3.small-heading {
  font-size: 14px;
  line-height: 1.13;
  color: #8b8f95;
}
.rating-textbox {
  width: 100%;
  min-height: 150px;
  border: solid 1px #dbdbdb;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 22.8px 14px 51px 15px;
}
/* width: 355px;
height: 167px;
margin: 27.7px 15px 35.8px 30px;
padding: 22.8px 14px 51px 15px;
border-radius: 10px;
border: solid 1px #dbdbdb; */

.MuiTypography-h6 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: #3e454f;
}
button.border-btn {
  width: 100%;
  height: 50px;
  border: 0;
  /* background: transparent; */
  border-top: 1px solid #ccc;
  font-size: 14px;
  font-weight: 600;
  /* color: #3e454f; */
}

.MuiDialog-paper {
  min-width: 350px;
}

@media (min-width: 1900px) {
  .sp-breadcrumbs {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0;
  }
}
/*
* Single Product Left Section CSS
*/

.sp-image-slide img {
  border: 1px solid #dbdbdb;
}

.sp-description-title {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
  font-weight: 500;
  margin-bottom: 27px;
}

.sp-description-text {
  font-size: 16px;
  color: var(--color-dark-grey);
  line-height: 1.9;
}

.yt-based-on {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}

.sp-rating-content {
  font-size: 38px;
  line-height: 1.13;
  color: var(--color-black);
}

.sp-rating-view span:first-child {
  font-size: 20px;
  color: var(--color-black);
  line-height: 1.75;
}

span.sp-rating-area {
  min-width: 100px;
  height: 3px;
  background-color: #b2b3b3;
}

.sp-rating-wrap .sp-rating-inner-wrap {
  width: 50%;
}

.sp-give-review-btn {
  min-width: 145px;
  border-radius: 5px;
  background-color: var(--color-primary);
}
/* 
.hc-hero-btn {
  opacity: 0.99;
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-primary);
  line-height: 1.11;
}

.sp-rating-area {
  position: relative;
}

.sp-rating-area::after {
  content: "";
  height: 2px;
  position: absolute;
}

.sp-width-80::after {
  width: 80%;
  background-color: var(--color-primary);
}

.sp-width-60::after {
  width: 60%;
  background-color: var(--color-primary);
}

.sp-width-40::after {
  width: 40%;
  background-color: #15aa71;
}

.sp-width-20::after {
  width: 20%;
  background-color: #15aa71;
}

.sp-width-10::after {
  width: 10%;
  background-color: #f67054;
}

.sp-rating-date {
  font-size: 12px;
  line-height: 1.17;
  color: var(--color-dark-grey);
}

.sp-user-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}

.sp-usermessage {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}

.sp-user-rated-meta img {
  max-width: 11px;
}

.yt-rating-single-prdt,
.yt-rating-val-wrapper {
  pointer-events: none;
}

.yt-rating-val-wrapper {
  margin: 15px 0 30px;
}
/*
* Single Product Right Section CSS
*/

.added.sp-favorite-wrap {
  background-color: red;
}

.added.sp-favorite-wrap .sp-fav-icn {
  color: #fff;
}

.sp-fav-icn {
  width: 19px;
  height: 19px;
}

.product-title {
  color: var(--color-black);
  font-size: 24px;
  font-weight: 400;
  line-height: 1.67;
  margin-top: 3px;
}

.sp-favorite-wrap {
  box-shadow: 0 1px 4px 0 rgba(199, 214, 227, 0.5);
  border-radius: 100%;
}

.sp-price-tag-name {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}
.sp-size-chart-tag-name {
  color: var(--color-dark-grey);
  line-height: 1.13;
  font-size: 16px;
}
.yt-prdt-size-chart {
  cursor: pointer;
}
.sp-price-tag-value {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: 0.77px;
  color: #3e454f;
}
.price-quontity-sec {
  display: flex;
  justify-content: space-between;
}

.sp-small-tag-value {
  font-size: 28px;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-green);
}

.sp-size-wrap {
  padding: 25px 0;
  border-top: 1px solid #e2e2e2;
}

.sp-other-wrap {
  border-top: 1px solid #e2e2e2;
  margin: 30px 0px;
}

.sp-quantity-tag-name {
  font-size: 14px;
  color: var(--color-dark-grey);
  line-height: 1.13;
  letter-spacing: normal;
}

.sp-verify-icn-wrap {
  line-height: 14px;
  margin-right: 5px;
}

.sp-verify-icn-wrap img {
  max-height: 15px;
  width: auto;
}

.product-stock-message {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-black);
}

.sp-small-tag-name {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}

.sp-out-quantity-tag-name {
  font-size: 16px;
  color: var(--color-orange);
  line-height: 1.13;
  letter-spacing: normal;
}

.sp-ccolor-box {
  width: 33px;
  height: 33px;
  border-radius: 27px;
  position: relative;
  cursor: pointer;
  border: 1px solid #dee2e6;
}
.for-mobile {
  display: none;
}

.active.sp-ccolor-box::after {
  content: "";
  height: 11px;
  width: 11px;
  /* background-image: url("../images/verify-icn.png"); */
  position: absolute;
  background-size: cover;
  background-color: #fff;
  right: -5px;
  top: -5px;
  border-radius: 100%;
}

.sp-color-1 {
  background-color: var(--color-black);
}

.sp-color-2 {
  background-color: #f6f8fa;
}

.sp-color-3 {
  background-color: var(--color-green);
}

.sp-color-4 {
  background-color: var(--color-blue);
}

.sp-color-5 {
  background-color: var(--color-dark-grey);
}

.sp-border-top-bottom {
  border-top: 2px solid #cae2fe;
}
.right-inner-content-wrap.for-desktop {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.sp-size-details {
  min-width: 50px;
  height: 37px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  font-size: 17px;
  color: var(--color-black);
  line-height: 1.24;
  cursor: pointer;
}

.active.sp-size-details {
  background-color: var(--color-primary);

  color: #fff;
}

.sp-addto-cart-btn,
.sp-buy-now-btn {
  min-width: 125px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  opacity: 0.99;
  border-radius: 5px;
}

.sp-addto-cart-btn {
  background-color: var(--color-primary);
  color: var(--color-text-primary);
}

.sp-buy-now-btn {
  background-color: var(--color-dark-black);
}

.yt-cm-lt-col {
  padding-right: 7px;
}

.yt-cm-rt-col {
  padding-left: 7px;
}

.sp-inner-wrap {
  padding: 40px 30px 30px;
}

.sp-product-add-action-wrap {
  margin-top: 30px;
}
.sp-inner-content-wrap {
  padding: 0 30px;
  margin: 0 auto;
  /* padding: 30px 0px 35px 30px;
  margin-top: 20px; */
}
.yt-inner-col {
  display: flex;
  justify-content: space-between;
}
.yt-inner-col .sp-thumb-wrap {
  display: flex;
  flex-direction: column;
}
.Like-btn img {
  width: 25px;
  height: 25px;
  margin-top: 10px;
}
.yt-inner-col .sp-thumb-wrap .sp-thumb-inner {
  max-width: 110px;
  padding: 10px;
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  margin-bottom: 30px;
}
@media (min-width: 1280px) {
  .yt-tab-mob {
    display: none;
  }
}

@media (min-width: 1280.88px) {
  .yt-cm-lt-col {
    padding-right: 10px;
  }
  .yt-cm-rt-col {
    padding-left: 10px;
  }
}

@media (min-width: 1900px) {
  .yt-cm-lt-col {
    padding-right: 20px;
  }
  .yt-cm-rt-col {
    padding-left: 20px;
  }
}

@media (min-width: 769px) {
  .sp-price-left-content {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .sp-addto-cart-btn,
  .sp-buy-now-btn {
    min-width: 180px;
  }
  .sp-image-slide li.navigation-up {
    transform: rotate(180deg);
  }
}

@media (min-width: 1280px) {
  .sp-border-md-right {
    border-right: 2px solid #f6f8fa;
    padding-right: 15px;
  }
}
/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279px) {
  .rate-product {
    margin-left: 20px;
  }
  .for-mobile {
    display: block;
    margin-bottom: 15px;
  }
  .for-desktop {
    display: none;
  }
  .yt-product-img-inner-row .sp-inner-content-wrap {
    display: flex;
  }
  .yt-product-img-inner-row .sp-inner-content-wrap .vert-slider-item {
    margin: 0 10px 0 0;
  }
  .right-inner-content-wrap {
    margin-top: 15px;
  }
  .sp-price-left-content {
    width: 100%;
  }
  .sp-breadcrumbs {
    margin-bottom: 20px;
  }
  .sp-inner-wrap,
  .sp-inner-content-wrap {
    padding: 20px 15px 20px 0;
    margin-left: 0;
  }
  .product-title {
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: 7px;
  }
  .sp-price-wrap,
  .sp-size-wrap {
    padding: 20px 0;
  }

  .sp-small-tag-name {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  /* .sp-price-tag-value {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
  } */
  .sp-quantity-tag-name {
    font-size: 12px;
    line-height: 2.33;
  }
  .sp-price-right-content .sp-quantity-tag-name {
    font-size: 11px;
    line-height: 1.18;
  }
  .sp-addto-cart-btn,
  .sp-buy-now-btn {
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    min-width: 150px;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
  }
  .sp-description-title {
    font-size: 16px;
    line-height: 1.13;
    margin-bottom: 9px;
  }
  .sp-description-text {
    font-size: 14px;
    line-height: 1.71;
  }
  .sp-rating-content {
    font-size: 20px;
    line-height: 1.15;
  }
  .yt-rating-val-wrapper {
    margin: 8px 0;
  }
  .sp-rating-view span:first-child {
    font-size: 12px;
    line-height: 1.75;
  }
  .yt-rating-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .product-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .sp-size-details {
    height: 30px;
    font-size: 12px;
  }
  .sp-inner-write-review {
    border-bottom: 2px solid #cae2fe;
    border-top: 2px solid #cae2fe;
    padding: 15px 0;
    margin-bottom: 5px;
  }
  .sp-user-name {
    font-size: 16px;
    line-height: 1.13;
  }
  .sp-rating-wrap {
    border: none;
    padding: 0 !important;
  }
  .sp-inner-write-review .sp-description-title {
    font-size: 14px;
    line-height: 1.14;
  }
  .sp-user-rated-meta ul {
    display: none !important;
  }
  .sp-user-info .yt-tab-mob ul li img {
    max-width: 12px;
  }
  .sp-rating-date {
    padding-top: 10px;
  }
  span.sp-rating-area {
    width: 80%;
  }
  .yt-rating-inner-row {
    flex-wrap: nowrap !important;
  }
  .yt-rating-inner-row .sp-rating-inner-wrap:last-child {
    width: 100%;
    padding-left: 15px;
  }
  .yt-rating-inner-row .sp-rating-inner-wrap:first-child {
    width: 255px !important;
  }
  .yt-rating-inner-row
    .sp-rating-inner-wrap:last-child
    ul
    li
    span:nth-child(2) {
    padding: 0 7px;
  }
  .yt-rating-inner-row .sp-rating-inner-wrap:last-child ul li span:first-child {
    width: 8px;
  }
  .sp-inner-write-review .sp-give-review-btn {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
  .sp-rating-wrap .sp-user-info {
    padding-left: 8px !important;
  }
  .sp-user-rated-meta {
    width: 70px;
  }
  #sp-size-data li {
    margin-right: 4px !important;
  }
  .sp-product-add-action-wrap {
    margin-top: 20px;
  }
  .sp-out-quantity-tag-name {
    font-size: 12px;
    line-height: 2.33;
  }
  .yt-product-img-inner-row .sp-inner-content-wrap {
    text-align: center;
    width: 70%;
    margin: auto;
  }
  .yt-product-img-inner-row
    .sp-inner-content-wrap.sp-spp-inner-image-wrap
    .sp-spimg-item {
    min-height: 350px !important;
  }
  .yt-product-img-inner-row .sp-inner-content-wrap.sp-spp-inner-image-wrap {
    min-height: 350px !important;
  }
}
/*
* Screen for under 768 Resolution 
*/

@media (max-width: 767px) {
  .d-flex-wrap-mobile {
    flex-wrap: wrap;
  }
  .rate-product {
    margin-left: 0;
    border: 0;
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
  .total-review {
    width: auto;
  }
  .rating-range {
    width: 80%;
    min-width: 150px;
  }
  .yt-cm-lt-col,
  .yt-cm-rt-col,
  .yt-sp-description-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .yt-product-img-inner-row .sp-inner-content-wrap {
    text-align: center;
    width: 100%;
  }
  .yt-cm-lt-col {
    padding-right: 15px;
  }
  .yt-cm-rt-col {
    padding-left: 15px;
  }

  .right-inner-content-wrap .sp-price-wrap {
    border: none;
  }
  .right-inner-content-wrap h1.product-title {
    font-size: 20px;
    font-weight: 400;
    color: #3e454f;
    line-height: 2;
  }
  .product-title {
    max-width: 100%;
    width: 100%;
  }
  .sp-price-wrap.yt-mb-space-border {
    padding-bottom: 0;
  }
  .yt-mb-space-border {
    position: relative;
  }
  .yt-mb-space-border::after {
    content: "";
    width: 150%;
    height: 4px;
    background: #f2f6f8;
    position: absolute;
    left: -20px;
    top: 0;
  }

  .sp-description-text {
    font-size: 17px;
    color: var(--color-black);
    line-height: 1.24;
  }
  .sp-small-tag-name,
  .sp-description-title {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
  }
  .sp-product-add-action-wrap {
    width: 100%;
  }
  .sp-product-add-action-wrap li {
    width: 50%;
  }
  .sp-description-text {
    max-height: 44px;
    overflow: hidden;
  }
  .sp-description-wrap.active-desc .sp-description-text {
    max-height: 100%;
  }
  .yt-read-more-des {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    color: #3bc490;
  }
  .sp-inner-write-review {
    border: none;
  }
  .sp-rating-wrap .yt-sp-user-rate-box:last-child {
    border-bottom: none;
  }
  .sp-rating-wrap .yt-sp-user-rate-box:first-child {
    border-top: solid 2px #f2f6f8;
  }
  .sp-rating-wrap .yt-sp-user-rate-box {
    border-bottom: solid 2px #f2f6f8;
  }

  .sp-favorite-wrap {
    margin-left: auto;
    margin-bottom: 10px;
  }
  .sp-price-right-content .sp-quantity-tag-name {
    display: none;
  }
  .sp-addto-cart-btn,
  .sp-buy-now-btn {
    width: 100%;
  }
  .sp-out-quantity-tag-name {
    line-height: 1.75;
  }
  .sp-other-wrap {
    padding: 20px 0;
    border: 0;
    margin: 0;
  }
  .sp-size-wrap .sp-size-col {
    margin-left: 0 !important;
  }

  .yt-product-img-inner-row .sp-inner-content-wrap.sp-spp-inner-image-wrap {
    min-height: 280px;
    padding: 0;
  }
}

@media (max-width: 575.98px) {
  .sp-rating-wrap .sp-rating-inner-wrap {
    width: 100%;
  }
  .sp-image-slide li.navigation-down {
    transform: rotate(270deg);
  }
  .sp-image-slide li.navigation-up {
    transform: rotate(90deg);
  }
  .sp-image-slide li {
    margin: 0 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .sp-inner-wrap {
    padding: 30px;
  }
  .product-title {
    line-height: 1.25;
  }
  .sp-small-tag-name {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  .sp-price-right-content .sp-quantity-tag-name {
    font-size: 16px;
    line-height: 1.13;
  }
  .sp-price-wrap ul.list-style-none {
    margin-bottom: 0;
  }
  /* .sp-price-tag-value {
    font-size: 20px;
    line-height: 1.17;
    letter-spacing: 0.92px;
  } */
  .sp-quantity-tag-name {
    font-size: 14px;
  }
  .sp-addto-cart-btn,
  .sp-buy-now-btn {
    font-size: 16px;
  }
  .sp-give-review-btn {
    font-size: 16px;
  }
  .sp-product-add-action-wrap {
    margin-top: 20px;
  }
  .sp-out-quantity-tag-name {
    font-size: 14px;
    line-height: 2;
  }
}

/* New Csss */
.bg-banner {
  /* background-image: url("../assets/Desktop-2.jpg"); */
  background-size: 100% 100%;
  background-position: 100% 100%;
  min-height: 418px;
  max-height: 418px;
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 100%;
}
.banner-title {
  font-size: 108px;
  font-weight: bold;
  line-height: 1.14;
  color: #3e454f;
}

.banner-sub-title {
  font-size: 18px;
  line-height: 1.14;
  font-weight: 300;
  padding-bottom: 10px;
  color: #3e454f;
}
.primary-btn {
  padding: 10px 45px;
  opacity: 0.99;
  border-radius: 6px;
  /* background-color: var(--color-primary); */
  background-color: var(--color-ButtonBackRegular);
  border: 0;
  /* color: #fff; */
  color: var(--color-ButtonTextRegular);
  font-size: 18px;
  font-weight: 500;
  border: 2px solid var(--color-primary);
  transition: all 0.3s ease-in-out;
}
.primary-btn:hover {
  opacity: 0.7;
  /* border: 2px solid #fff; */
  border: 2px soild var(--color-primary);
  /* color: #fff; */
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

/*Content section*/
.content-part {
  background-color: #f6f8fa;
  position: relative;
  /* padding: 80px 0; */
  padding: 50px 0;
}
/*service-steps*/
.service-steps {
  background-color: #fff;
  border-radius: 10px;
  padding: 9px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.service-steps .step {
  display: flex;
  width: 25%;
  justify-content: center;
  border-right: 1px solid #e2e2e2;
}
.service-steps .step img {
  margin-right: 20px;
}
.service-steps .step p {
  padding: 0;
  margin: 0;
  color: #8b8f95;
  font-size: 18px;
}
.service-steps .step p strong {
  font-size: 20px;
  font-weight: 500;
  color: #3e454f;
}

/*Collections Caraousal section*/
.collections {
  /* margin-top: 80px; */
}
.carousal {
  /* padding: 60.5px 60.5px 60px;
  border-radius: 10px;
  background-color: #ffffff; */
  position: relative;
}
.carousal button {
  position: absolute;
  top: calc(50% - 17px);
}
.carousal button.carousel__back-button,
.carousal button.carousel__next-button {
  width: 40px;
}
.collections h2 {
  font-size: 30px;
  font-weight: 500;
  color: #3e454f;
  line-height: 1.13;
  margin-bottom: 20px;
}
.carousal .card-body .card-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.15;
  text-align: center;
  color: #3e454f;
}

.carousal .carousel__slider ul {
  display: flex;
}

.carousal .carousel__slider ul li {
  margin-right: 49px;
  min-width: 130px;
}
.carousal .carousel button {
  position: absolute;
  top: calc(50% - 17px);
}
.carousal .carousel button:disabled {
  opacity: 0.5;
}
.carousal button.carousel__back-button .slider-left,
.carousal button.carousel__next-button .slider-right {
  width: 40px;
  max-width: none;
}
.carousal .rec-item-wrapper {
  text-align: center;
}

.carousal .rec-item-wrapper img {
  max-width: 262px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0px 12px 30px rgb(0 0 0 / 9%);
}

.carousal button.carousel__back-button {
  left: -60px;
}
.carousal button.carousel__next-button {
  right: -50px;
}

/*Box carousal*/
.new-collection {
  /* margin-top: 80px; */
  margin-top: 45px;
}
.new-collection h2 {
  font-size: 30px;
  font-weight: 500;
  color: #3e454f;
  line-height: 1.13;
  margin-bottom: 20px;
}

.box-carousal .item-slider {
  padding: 15.7px 0 0 1px;
  border-radius: 4px;
  background-color: transparent;
  height: 100%;
  /* margin-right: 30px; */
  max-width: 284px;
  width: 100%;
}
.box-carousal .yt-component-wrapper {
  position: relative;
  display: flex;
}
.box-carousal .item-slider .product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.box-carousal .product-details {
  text-align: center;
}
.box-carousal .product-details .product-title {
  font-size: 18px;
  color: #000;
  font-family: 'Avenir Next LT Pro', sans-serif;
  font-weight: 400;
}
.box-carousal .product-details .price-wrap {
  padding: 6px 0;
}
.box-carousal .product-details .product-sale-price {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.13;
  color: #000;
}
.box-carousal .product-details .product-reg-price2 {
  font-size: 16px;
  line-height: 1.13;
  text-decoration: line-through;
  color: red;
  margin-right: 10px;
}
.box-carousal .product-details .ratings {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-carousal .product-details .ratings span {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}
.box-carousal .product-details .ratings .product-rating {
  color: #8b8f95;
}
.box-carousal .product-details .ratings .rating-star-icon {
  margin: 0 5px;
}

.box-carousal .product-details .button-cart {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  /* color: #30458c; */
  color: var(--color-ButtonTextRegular);
  /* background: transparent; */
  background: var(--color-ButtonBackRegular);
  border: 0;
  border-top: 1px solid #e2e2e2;
  border-radius: 0 0 4px 4px;
  padding: 15.7px 15px 15px 30px;
  width: 100%;
  margin-top: 20px;
}

.box-carousal .product-details .button-cart:hover,
.box-carousal .product-details .button-cart:active,
.box-carousal .product-details .button-cart:visited {
  /* color: #fff; */
  color: var(--color-ButtonTextHover);
  /* background: #30458c; */
  background-color: var(--color-ButtonBackHover);
  border-top: 1px solid #30458c;
}
.box-carousal .rec-item-wrapper {
  height: 100%;
}

.product-descrip {
  /* width: 1920px; */
  height: auto;
  /* padding: 0 0 18px; */
  padding-right: 50px;
  padding-left: 50px;
  background-color: #f6f8fa;
}

.box-carousal .Like-btn {
  cursor: pointer;
}
.box-carousal .Like-btn img {
  width: 25px;
}
.box-carousal .btn-link {
  font-size: 16px;
  font-weight: 500;
  /* text-decoration: underline !important; */
  color: var(--color-primary);
}
.product-slider button:disabled {
  opacity: 0.5;
}
.product-slider button .slider-left,
.product-slider button .slider-right {
  width: 40px;
}
.product-slider button.carousel__back-button,
.product-slider button.carousel__next-button {
  position: absolute;
  top: calc(50% - 17px);
}
.product-slider button.carousel__back-button {
  left: -60px;
}
.product-slider button.carousel__next-button {
  right: -60px;
}
.product-slider .rec-slider-container {
  margin: 0 -10px;
}
.product-slider .slider-container {
  /* margin: 0; */
  max-width: 320px;
}
/* Add Banners*/

.offer-banner-wrap {
  display: flex;
  margin: 80px 0;
  cursor: pointer;
}
.offer-banner {
  min-width: 50%;
  overflow: hidden;
}
.offer-banner {
  max-height: 820px;
  overflow: hidden;
}
.full-size-banner {
  max-height: 500px;
}

.offer-banner .banner-text {
  position: relative;
}
.offer-banner .banner-text img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  max-width: 100%;
}

.full-size-banner .banner-text img {
  height: auto;
  width: 100%;
}
.offer-banner .add-text {
  position: absolute;
  max-width: 60%;
}
.offer-banner .add1 {
  right: 10px;
  top: 20px;
}
.offer-banner .add2 {
  left: 56px;
  top: 83px;
}
.offer-banner .add3 {
  right: 90px;
  top: 105px;
}
.offer-banner .line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 340px;
  position: absolute;
  bottom: 75px;
}
/*----- Typography ----*/

.offer-banner .blue-text {
  color: var(--color-primary);
}
.offer-banner .white-text {
  color: #fff;
}
.offer-banner .dark-text {
  color: #3e454f;
}
.offer-banner .font-180 {
  font-size: 180px;
  font-weight: bold;
}
.offer-banner .font-79 {
  font-size: 79px;
  font-weight: bold;
}
.offer-banner .font-60 {
  font-size: 60px;
  font-weight: 500;
}
.offer-banner .font-40 {
  font-size: 40px;
  font-weight: 500;
}

.offer-banner .font-26 {
  font-size: 26px;
  font-weight: 500;
}
.offer-banner .font-22 {
  font-size: 22px;
  font-weight: normal;
}

/*Shopping cards*/

.shop-card-wrap {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.shop-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.shop-card::before {
  content: "";
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(36, 39, 36, 0.92),
    rgba(36, 39, 36, 0) 59%
  );
}
.shop-card img {
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.shop-card.middle-card {
  width: 50%;
  margin: 0 30px;
}
.shop-card .card-text {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  padding: 20px;
  align-items: center;
  margin-bottom: 30px;
}
.shop-card .card-text p {
  font-size: 26px;
  line-height: 1.13;
  color: #fff;
}
.shop-card .card-text .primary-btn {
  min-width: 170px;
}

/*Offer card*/
.offer-banner-wrap2 {
  margin: 80px 0 0 0;
  cursor: pointer;
}
.offer-banner-wrap2 .offer-banner {
  display: flex;
}
.offer-banner-wrap2 .offer-banner .banner-text {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}
.offer-banner .banner-text .pos1 {
  left: 50px;
  max-width: 400px;
}
.offer-banner .banner-text .pos2 {
  right: 50px;
  max-width: 400px;
}

.offer-banner .banner-text .pos3 {
  left: 150px;
}

/* quantity css */

.cart-quantity-field .form-group {
  position: relative;
  border-radius: 2px;
  border: solid 1px #cae2fe;
}
.cart-quantity-icn {
  position: absolute;
  top: 1px;
  bottom: 1px;
  background: #f6f8fa;
  cursor: pointer;
}
.quantity-icn-left {
  left: 1px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.quantity-icn-right {
  right: 1px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.cart-quantity-field .form-control {
  border-radius: 0;
  box-shadow: none !important;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}
.cart-quantity-field input::-webkit-outer-spin-button,
.cart-quantity-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pro-review {
  width: 400px;
  height: 435px;
  margin: 112px 240px 68px 478px;
  padding: 15.8px 0 7px;
  border-radius: 10px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

/* Firefox */
.cart-quantity-field input[type="number"] {
  -moz-appearance: textfield;
}
.cart-quantity-field {
  max-width: 120px;
}
/*Quantity CSS END*/
.product-rating .sp-inner-content-wrap {
  padding: 30px;
}
.product-description .sp-inner-content-wrap {
  padding: 30px;
  margin-bottom: 20px;
}
.yt-cart-price-lister {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1440px) {
  .service-steps {
    padding: 9px 20px;
  }
  .service-steps .step p {
    font-size: 16px;
  }
}
@media (max-width: 1279px) {
  .content-part {
    padding: 40px 0;
  }
  .yt-product-img-inner-row {
    flex-direction: column;
  }
  .yt-product-img-inner-row .yt-inner-col {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .shop-card .card-text {
    flex-direction: column;
  }
  .yt-slider-right {
    right: 20px;
  }
  .yt-comonent-top-title {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-component-more {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-slider-left,
  .yt-slider-right {
    font-size: 21px;
    top: -41px;
  }
  .yt-slider-left {
    right: 42px;
  }
  .yt-comonent-link {
    padding-right: 55px;
  }
  .yt-product-off {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-sglproduct-fav {
    font-size: 15px;
  }
  .yt-product-title {
    font-size: 14px;
    line-height: 0.71;
    margin-top: 15px !important;
  }
  .yt-price-wrap {
    padding: 8px 0px 16px !important;
  }
  .yt-product-reg-price1,
  .yt-product-reg-price2 {
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-product-slider-cpnt {
    padding-bottom: 40px;
  }
  .yt-top-offer-whl {
    margin-top: 10px !important;
  }
  .yt-product-add-btn {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .yt-produstslider-info {
    padding-bottom: 15px;
  }
  .yt-component-wrapper.yt-slider-component {
    /* margin-left: -20px;
    margin-right: -20px; */
    border-radius: 0;
  }

  section.hp-product-slider {
    margin: 20px 0 !important;
  }
  .yt-td-product-img {
    width: 117px;
    height: 117px;
    max-width: 117px;
  }

  .cart-quantity-field {
    max-width: 100px;
    margin-left: auto;
  }
  .service-steps .step {
    width: 50%;
    padding: 10px 0;
  }
  .service-steps .step:nth-child(2) {
    border: 0;
  }
  .service-steps {
    flex-wrap: wrap;
  }
  .carousal {
    padding: 30px;
  }
  .carousal .carousel__slider ul li {
    margin-right: 35px;
  }
  .horizontalSlider___281Ls {
    min-height: 200px;
  }
  .carousal .carousel__slider ul {
    height: 100%;
  }
  .card-body {
    padding: 15px 10px !important;
  }
  .carousal .carousel__slider .card-body .card-text {
    font-size: 16px;
  }
  .carousel button.carousel__back-button {
    left: -80px;
  }
  .carousel button.carousel__next-button {
    right: -80px;
  }
  .product-slider button.carousel__back-button {
    left: -50px;
  }
  .product-slider button.carousel__next-button {
    right: -50px;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 991px) {
  .bg-banner {
    min-height: 300px;
    max-height: 300px;
  }
  .main-container {
    padding-right: 35px;
    padding-left: 35px;
  }
  .shop-card .card-text {
    flex-direction: column;
  }
  .box-carousal .product-details .button-cart {
    color: #fff;
    background: #30458c;
    border-top: 1px solid #30458c;
    font-size: 16px;
  }
  .cart-quantity-field {
    max-width: 100px;
    margin-left: auto;
  }
  .service-steps .step {
    width: 100%;
    padding: 10px 0;
    border: 0;
    justify-content: flex-start;
    border-top: 1px solid #ccc !important;
  }
  .service-steps .step:first-child {
    border-top: none !important;
  }
  .collections {
    margin-top: 50px;
  }
  .banner-title {
    font-size: 72px;
  }
  .new-collection h2,
  .collections h2 {
    font-size: 26px;
  }
  .carousel button .slider-left,
  .carousel button .slider-right {
    width: 30px;
  }
  .product-slider button .slider-left,
  .product-slider button .slider-right {
    width: 30px;
  }
  .carousal button.carousel__back-button .slider-left,
  .carousal button.carousel__next-button .slider-right {
    width: 30px;
  }
  .carousel button.carousel__back-button {
    left: -70px;
  }
  .carousel button.carousel__next-button {
    right: -70px;
  }
  .product-slider button.carousel__back-button {
    left: -40px;
  }
  .product-slider button.carousel__next-button {
    right: -40px;
  }
  .carousal button.carousel__back-button {
    left: -40px;
  }
  .carousal button.carousel__next-button {
    right: -40px;
  }
  .offer-banner-wrap {
    flex-wrap: wrap;
  }
  .offer-banner {
    min-width: 100%;
    max-height: none;
  }
  .offer-banner .banner-text {
    max-height: 420px;
  }
  .offer-banner .banner-text img {
    -o-object-fit: contain;
       object-fit: contain;
    height: auto;
    max-width: 100%;
  }
  .shop-card-wrap {
    flex-wrap: wrap;
  }
  .shop-card,
  .shop-card.middle-card {
    width: 100%;
    margin: 15px 0;
  }

  .shop-card img {
    width: 100%;
    max-height: 420px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .offer-banner-wrap2 .offer-banner {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .right-inner-content-wrap .yt-sp-title-wrapper {
    margin-bottom: 10px !important;
  }
  .sp-inner-wrap {
    padding: 20px;
  }
  .content-part {
    padding: 40px 0;
  }
  .offer-banner .add-text,
  .offer-banner .banner-text .pos3,
  .offer-banner .banner-text .pos1 {
    left: 15px;
    top: 15px;
    right: auto;
    bottom: auto;
  }
  .offer-banner .add-text .banner-title {
    font-size: 34px;
  }
  .service-steps .step p strong {
    font-size: 18px;
  }
  .service-steps .step p {
    font-size: 14px;
  }
  .yt-td-product-img {
    width: 100px;
    height: 100px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .yt-product-add-btn {
    font-size: 13px;
    line-height: 1.15;
  }
  section.hp-product-slider {
    position: relative;
  }
  .yt-head-mb-search-panel {
    display: block;
  }
  /* .yt-component-wrapper.yt-slider-component {
    padding-top: 52px !important;
  } */

  /* .yt-produstslider-info {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 18px;
    z-index: 5;
  } */
  .yt-slider-left,
  .yt-slider-right {
    top: 17px;
    z-index: 10;
  }
  .yt-component-more {
    border-bottom: none;
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
    margin: 0 !important;
  }
  .yt-comonent-top-title {
    font-size: 17px;
    line-height: 1.12;
  }
  .yt-slider-left {
    right: 41px;
  }
  .yt-comonent-link {
    padding-right: 49px;
  }
  .yt-product-title {
    font-size: 16px;
    line-height: 1.19;
  }
  .yt-product-sale-price,
  .yt-product-reg-price {
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-product-off {
    min-width: 35px;
  }
  .yt-item-slider {
    margin: 0 5px;
  }
  .yt-top-offer-whl {
    margin-top: 0 !important;
  }
  section.hp-product-slider {
    margin: 10px 0 !important;
  }
}
@media (max-width: 479px) {
  .product-descrip {
    padding-right: 15px;
    padding-left: 15px;
  }
  .new-collection h2,
  .collections h2 {
    font-size: 22px;
  }
  .content-part {
    padding: 40px 0;
  }
  .primary-btn {
    padding: 7px 27px;
    font-size: 14px;
  }

  .bg-banner {
    min-height: 110px;
    max-height: 110px;
  }
  .price-quontity-sec .left-price-sec > .d-flex {
    display: flex;
    flex-direction: column;
  }
  .price-quontity-sec .left-price-sec > .d-flex > .d-flex {
    margin-left: 0 !important;
  }
  .cart-coupon-bottom-wrapper {
    flex-direction: column;
    bottom: -65px !important;
  }
  .cart-pg-list-prdt-info {
    width: 100% !important;
  }
}

.dashboard-carousel-section .dTGAUq {
  margin: 0px !important;
}

.radius-10 {
  border-radius: 10px;
}
.list-style-none {
  list-style: none;
}
.border-top-grey {
  border-top: 2px solid #dbdbdb;
}
.sp-current {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.sp-image-vert-slider {
  position: relative;
  max-height: 550px;
}

.yt-product-img-inner-row .sp-inner-content-wrap.sp-spp-inner-image-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid silver;
  border-radius: 10px;
  min-height: 450px;
}
.yt-slider-down,
.yt-slider-up {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}
.yt-slider-up {
  top: 0;
}
.yt-slider-down {
  bottom: 0;
}

.rec {
  background: transparent;
}
div {
  box-shadow: none;
  outline: none !important;
}
.sp-inner-wrap {
  padding: 40px 30px 30px;
}
.yt-product-img-inner-row
  .sp-inner-content-wrap.sp-spp-inner-image-wrap
  .sp-spimg-item {
  min-height: 450px;
  /* object-fit: cover; */
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  padding: 20px;
}
@media (min-width: 1900px) {
  .sp-inner-wrap {
    padding: 40px 40px 30px;
  }
}
@media (min-width: 1280px) {
  .sp-image-vert-slider {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .vert-slider-item img {
    height: 110px;
    width: 110px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .vert-slider-item {
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    height: 133px;
    width: 133px;
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .yt-inner-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .yt-slider-down,
  .yt-slider-up {
    top: 50%;
    transform: rotate(-90deg);
    margin: 0;
    z-index: 50;
  }
  .yt-slider-up {
    left: -10px;
  }
  .yt-slider-down {
    right: -10px;
    left: auto;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
  .vert-slider-item {
    height: 70px;
    width: 70px;
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
  }
  .vert-slider-item img {
    height: 57px;
    width: 57px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.vertical-slider {
  width: 210px;
}

/*
* Add Address Modal CSs
*/
.price {
  /* width: 53px; */
  height: 28px;
  margin: 5px 23px 22px 18px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.92px;
  text-align: left;
  color: var(--color-primary);
}
.Pcakages-subscription {
  font-size: 17px;
  line-height: 1.24;
  text-align: left;
  color: var(--color-black) !important;
}
.subscription-qty,
.subscribePackageText,
.subscriptionPeriod,
.subscription-timeslot {
  font-size: 16px;
  text-align: left;
  color: var(--color-dark-grey);
}
.subscribePackageText {
  text-align: left !important;
}
.sp-quantity-tag-name {
  color: #a3a3a3;
  font-size: 16px;
}
.select-input {
  width: 90%;
  margin: 5px 20px 1px 23px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #a3a3a3;
}
.edit-add-addr-title-bar {
  border-color: #dbdbdb;
  border-width: 2px;
}
.edit-add-addr-title-bar span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.radio-btn {
  width: 21px;
  height: 21px;
  margin: 0 10px 0 0;
  padding: 6px;
}

.radio-btn-text {
  color: var(--color-black);
  margin-left: 6%;
  font-size: 16px;
  line-height: 1.5;
}

.quantity {
  margin-top: 2% !important;
}

.content {
  margin-top: -7% !important;
}

.sp-price-right-content {
  height: auto;
}
/* .body-container {
    max-height: auto;
    overflow-y: auto;
  } */

.modal-footer {
  justify-content: center !important;
}

.add-to-cart {
  width: 45%;
  height: 50px;
  margin: 12px ​1.2px 10px 21px;
  padding: 15px 45px;
  opacity: 0.99;
  background-color: var(--color-primary);
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.buy-now {
  width: 45%;
  height: 50px;
  margin: 12px ​1.2px 10px 21px;
  padding: 15px 32px;
  opacity: 0.99;
  background-color: var(--color-ButtonBackRegular);
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

@media (min-width: 1366px) and (max-width: 1900px) {
}
/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .edit-add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-add-modal-body {
    padding: 30px 40px 20px !important;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .edit-add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }

  .yt-add-modal-body {
    padding: 20px 15px !important;
  }
}

/*
* Add Address Modal CSs
*/
.price {
  /* width: 53px; */
  height: 28px;
  margin: 5px 23px 22px 18px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.92px;
  text-align: left;
  color: var(--color-primary);
}
.Pcakages-subscription {
  font-size: 17px;
  line-height: 1.24;
  text-align: left;
  color: var(--color-black) !important;
}
.subscription-qty,
.subscribePackageText,
.subscriptionPeriod,
.subscription-timeslot {
  font-size: 16px;
  text-align: left;
  color: var(--color-dark-grey);
}
.subscribePackageText {
  text-align: left !important;
}
.sp-quantity-tag-name {
  color: #a3a3a3;
  font-size: 16px;
}
.select-input {
  width: 90%;
  margin: 5px 20px 1px 23px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #a3a3a3;
}
.edit-add-addr-title-bar {
  border-color: #dbdbdb;
  border-width: 2px;
}
.edit-add-addr-title-bar span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.radio-btn {
  width: 21px;
  height: 21px;
  margin: 0 10px 0 0;
  padding: 6px;
}

.radio-btn-text {
  color: var(--color-black);
  margin-left: 6%;
  font-size: 16px;
  line-height: 1.5;
}

.quantity {
  margin-top: 2% !important;
}

.content {
  margin-top: -7% !important;
}

.sp-price-right-content {
  height: auto;
}
/* .body-container {
      max-height: auto;
      overflow-y: auto;
    } */

.modal-footer {
  justify-content: center !important;
}

.add-to-cart {
  width: 45%;
  height: 50px;
  margin: 12px ​1.2px 10px 21px;
  padding: 15px 45px;
  opacity: 0.99;
  background-color: var(--color-primary);
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.buy-now {
  width: 45%;
  height: 50px;
  margin: 12px ​1.2px 10px 21px;
  padding: 15px 32px;
  opacity: 0.99;
  background-color: var(--color-ButtonBackRegular);
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}

@media (min-width: 1366px) and (max-width: 1900px) {
}
/*
    * Screen for under 1280 Resolution 
    */
@media (max-width: 1279.92px) {
  .edit-add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-add-modal-body {
    padding: 30px 40px 20px !important;
  }
}

/*
    * Screen for under 768 Resolution 
    */
@media (max-width: 767.92px) {
  .edit-add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }

  .yt-add-modal-body {
    padding: 20px 15px !important;
  }
}

/* Subscription Pagination css */
#subscriptionpagination > .pagination {
  justify-content: center;
  background-color: transparent;
  padding: 15px 0px;
}

#subscriptionpagination > .pagination > li {
  padding: 0px 20px;
  font-size: 18px;
  color: var(--color-black);
}
#subscriptionpagination > ul.pagination {
  margin-bottom: 0px;
}
#subscriptionpagination > .pagination > .next,
#subscriptionpagination > .pagination > .previous {
  color: var(--color-RegularActiveText);
  font-weight: 500;
}
#subscriptionpagination > .pagination > .disabled {
  opacity: 0;
}
#subscriptionpagination > .pagination > .active {
  border-bottom: 1px solid wheat;
  background-color: var(--color-RegularActiveText);
  border-radius: 50%;
  color: white;
}

/*Order Detail css*/
.order-page-prevpage {
  color: var(--color-black) !important;
  font-weight: 500 !important;
}
.order-sub-status .myDot.transit-me {
  width: 7px !important;
  height: 7px !important;
  background: var(--color-sky-blue) !important;
  border-radius: 100% !important;
  margin-right: 0.2rem !important;
}
.order-sub-status .myDot.success-me {
  width: 7px !important;
  height: 7px !important;
  background: var(--color-green) !important;
  border-radius: 100% !important;
  margin-right: 0.2rem !important;
}
.order-sub-status .myDot.cancel-me {
  width: 7px !important;
  height: 7px !important;
  background: var(--color-red) !important;
  border-radius: 100% !important;
  margin-right: 0.2rem !important;
}
.ml-1 {
  margin-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.subscription-timeslot1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  text-align: left;
  color: var(--color-green);
}
.subscrition-cancel-btn {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.62px;
  text-align: left;
  color: var(--color-lignt-orange);
}
.subscription-orderStatus {
  font-size: 12px;
  line-height: 1.58;
  text-align: right;
  color: var(--color-dark-grey);
}
.subscription-itemName {
  font-size: 18px;
  font-weight: 500;
  line-height: 2.22;
  text-align: left;
  color: var(--color-black);
}
.order-page-prevpage:hover {
  color: var(--color-sky-blue) !important;
  font-weight: bold !important;
}
.orderDetails-subscription {
  position: absolute;
  right: 0;
}

.hc-breadcrumbs {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}

.order-details-main-wrapper {
  padding: 40px 40px 42px;
}

.hc-current {
  color: var(--color-black);
  font-size: 12px;
  font-weight: bold !important;
  line-height: 1.17;
}

.hc-mb-30 {
  margin-bottom: 30px;
}

.hc-mb-80 {
  margin-bottom: 80px;
}

.radius-10 {
  border-radius: 10px;
}

.hc-beckfrom-ct-page {
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  cursor: pointer;
  display: inline-block;
}

.hcp-back-arrow {
  cursor: pointer;
}

.order-tracing-details {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-blue);
  text-decoration: underline;
}
.order-total-price {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}

.order-details-status {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  position: absolute;
  bottom: 20px;
  text-align: right;
  right: 0;
}

.order-details-sub-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}

.order-details-status-bar {
  border-top: 2px solid #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
}

.order-step-1 .order-status-date {
  color: var(--color-dark-grey);
  padding-left: 10px;
}

.order-step-1 h4,
.order-details-address-type {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: var(--color-black);
}

.order-details-address-details {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
}

.order-details-status-bar .order-details-status-wrap {
  border-left: 2px solid #dbdbdb;
}

.order-details-status-bar .order-details-status-wrap li {
  position: relative;
  padding-left: 10px;
}

.order-details-status-icn {
  position: absolute;
  left: -17px;
  top: 0;
}

.order-details-contact {
  font-size: 15px;
  line-height: 1.2;
  color: var(--color-blue);
}

.order-details-message {
  font-size: 16px;
  line-height: 1.19;
  color: var(--color-dark-grey);
}

.order-details-status.on-the-way::after {
  content: "";
  width: 7px;
  height: 7px;
  background: var(--color-green);
  border-radius: 100%;
  display: block;
  position: absolute;
  bottom: 3px;
  left: -15px;
}

.pp-order-product-ttl {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.67;
  color: var(--color-black);
}

.order-ul-list-none {
  list-style: none;
}

.op-order-quantity {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}

.op-order-product-price1 {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-green);
}

.order-prodict-type-container {
  /* max-width: 59vw; */
  overflow-x: auto;
}

/* width */
.order-prodict-type-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.order-prodict-type-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.order-prodict-type-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.order-prodict-type-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.order-prodict-type th,
.order-prodict-type td {
  padding: 3px;
}

.order-prodict-type th {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
  text-transform: uppercase;
}

.order-prodict-type td {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}

.od-product-img {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  max-width: 133px;
  width: 133px;
  height: 133px;
}
.od-product-img img {
  width: 117px;
  height: 117px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}

.order-number-wrap .order-tag,
.order-date-wrap .order-tag {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}

.order-number-wrap .order-tag-val,
.order-date-wrap .order-tag-val {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: var(--color-black);
}

.order-bdr-between {
  width: 2px;
  background-color: #f6f8fa;
  margin: 0 15px;
  height: 18px;
}

.order-write-review {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-blue);
  text-decoration: underline;
}

.order-cancel-btn {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  text-align: left;
  color: var(--color-orange);
}

.pp-od-no-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}

.pp-od-no-text.mb-0 {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.pp-no-order-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}

.order-product-info {
  padding-left: 40px;
}

.od-single-prd-details-wrap {
  position: relative;
}

.order-product-quanity {
  position: absolute;
  right: 0;
  top: 30px;
}

.order-details-main-wrapper .sinlge-mo-dt-wrap {
  padding-left: 173px;
}

@media (min-width: 1280px) {
  .od-cm-col-pad {
    padding: 0 40px;
  }
}

@media (min-width: 1900px) {
  .od-cm-col-pad {
    padding: 0 20px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .order-details-main-wrapper {
    padding: 35px 30px;
  }
  .hc-beckfrom-ct-page {
    font-size: 26px;
  }
  .order-details-sub-title {
    margin-bottom: 15px;
  }
  .order-details-status.on-the-way::after {
    bottom: 6px;
  }
  .order-details-status-bar {
    margin-top: 0 !important;
  }
  .order-prodict-type th:first-child,
  .order-prodict-type td:first-child {
    padding: 3px !important;
  }

  .order-prodict-type th,
  .order-prodict-type td {
    padding-left: 70px !important;
  }
  .pp-order-product-ttl {
    line-height: 1.25;
  }
  .od-single-prd-details-wrap .od-product-img.p-1 {
    align-self: flex-start;
  }
  .order-product-info {
    padding-left: 20px;
  }
  .order-details-main-wrapper .sinlge-mo-dt-wrap {
    padding-left: 115px;
  }
  .order-number-wrap .order-tag,
  .order-date-wrap .order-tag,
  .order-number-wrap .order-tag-val,
  .order-date-wrap .order-tag-val {
    font-size: 14px;
    line-height: 1.36;
  }
  .order-write-review {
    font-size: 16px;
    line-height: 1.19;
  }
  .order-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  .order-prodict-type td {
    font-size: 16px;
    line-height: 1.31;
  }
  .od-product-img {
    width: 95px;
    height: 95px;
  }
  .od-product-img img {
    width: 72px;
    height: 72px;
  }
}
/*
    * Screen for under 1280 Resolution 
    */

@media (max-width: 1279.92px) and (min-width: 768px) {
  .od-product-img {
    width: 70px;
    height: 70px;
  }
  .od-product-img img {
    width: 57px;
    height: 57px;
  }
  .od-single-prd-details-wrap {
    margin-bottom: 0 !important;
    padding-bottom: 5px !important;
    padding-top: 28px !important;
  }
}

@media (max-width: 1279.92px) {
  .orderDetails-subscription {
    top: -1.15rem;
    font-size: 9px;
  }
  .hc-beckfrom-ct-page {
    margin-bottom: 10px;
  }
  .order-tracing-details {
    font-size: 14px;
    line-height: 1.14;
  }
  .order-total-price {
    font-size: 14px;
    line-height: 1.14;
  }
  .order-details-sub-title {
    font-size: 18px;
    line-height: 1.11;
    margin: 5px 0 17px !important;
  }
  .order-details-address-details {
    color: var(--color-dark-grey);
    font-size: 14px;
    line-height: 1.36;
  }
  .order-details-main-wrapper {
    padding: 23px 20px 20px;
  }
  .order-details-main-wrapper .sinlge-mo-dt-wrap {
    padding-left: 80px;
  }
  .od-product-img {
    max-width: 70px;
    align-self: flex-start;
  }
  .order-product-info {
    padding-left: 10px;
  }
  .order-number-wrap .order-tag,
  .order-date-wrap .order-tag,
  .order-number-wrap .order-tag-val,
  .order-date-wrap .order-tag-val {
    font-size: 12px;
    line-height: 1.58;
  }
  .order-write-review {
    padding: 0;
    font-size: 14px;
    line-height: 1.36;
  }
  .pp-order-product-ttl {
    font-size: 16px;
    line-height: 1.13;
  }
  .order-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  .order-prodict-type td {
    font-size: 16px;
    line-height: 1.31;
  }
  .order-product-price {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
  }
  .order-details-contact {
    font-size: 14px;
    line-height: 1.14;
  }
  .order-cancel-btn {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
  }
}
/*
    * Screen for under 768 Resolution 
    */

@media (max-width: 767.92px) {
  .orderDetails-subscription {
    top: -1.15rem;
    font-size: 9px;
  }
  .order-details-main-wrapper {
    padding: 15px;
    position: relative;
    margin: 67px 0 !important;
    padding-bottom: 55px;
  }
  .order-details-main-wrapper .sinlge-mo-dt-wrap {
    padding: 0;
    max-width: 60%;
  }
  .order-bdr-between {
    display: none;
  }
  .order-write-review {
    font-size: 14px;
    line-height: 1.36;
    padding: 0;
    text-decoration: none;
  }
  .order-number-wrap .order-tag,
  .order-date-wrap .order-tag {
    font-size: 12px;
    line-height: 1.58;
  }
  .order-number-wrap .order-tag-val,
  .order-date-wrap .order-tag-val {
    font-size: 12px;
    line-height: 1.58;
  }
  .yt-sp-my-order-tdet-wrap {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 10px 0px;
  }
  /* .od-product-img {
        max-width: 70px;
        -ms-flex-item-align: start;
        align-self: flex-start;
      } */
  .od-product-img {
    width: 70px;
    height: 70px;
  }
  .od-product-img img {
    width: 57px;
    height: 57px;
  }
  .order-product-info {
    padding-left: 14px;
  }
  .pp-order-product-ttl {
    font-size: 14px;
    line-height: 1.36;
    max-width: 160px;
  }
  .order-product-quanity {
    top: 15px;
  }
  .order-prodict-type th {
    font-size: 10px;
    line-height: 1.1;
  }
  .order-prodict-type td {
    font-size: 14px;
    line-height: 1.5;
  }
  .op-order-product-price1 {
    width: 100%;
    padding-right: 0 !important;
    text-align: right;
  }
  .order-tracing-details {
    font-size: 12px;
    line-height: 1.17;
  }
  .order-total-price {
    font-size: 12px;
    line-height: 1.17;
  }
  .order-details-status {
    left: 100px;
    right: auto;
    bottom: 40px;
  }
  .order-details-status-bar {
    margin: 0 !important;
  }
  .order-details-sub-title {
    font-size: 14px;
    line-height: 1.36;
    margin-bottom: 26px;
    margin-top: 0;
  }
  .order-details-shipping-barmy-3 {
    padding-top: 15px;
  }
  .order-details-shipping-barmy-3 .order-details-address-type,
  .order-details-shipping-barmy-3 .order-details-address-details {
    font-size: 15px;
    line-height: 1.2;
  }
  .order-cancel-wrap {
    width: 100%;
    position: absolute;
    left: 15px;
    right: 0;
    bottom: -40px;
    text-align: left !important;
    padding: 0 !important;
  }
  .order-cancel-wrap .order-cancel-btn {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
  }
  .op-order-product-price1 {
    font-size: 15px;
    line-height: 1.2;
  }
  .op-order-product-price1 {
    position: absolute;
    right: 0;
    bottom: 40px;
  }
  .order-product-info .order-ul-list-none {
    padding-top: 25px !important;
  }
  .order-product-quanity .order-ul-list-none,
  .order-details-status-bar .order-ul-list-none {
    padding-top: 0 !important;
  }
}

.co-title-bar .modal-title {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.co-body-text {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.co-title-bar span {
  /* position: absolute; */
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.pp-co-btn-modal {
  width: 50%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  /* color: var(--color-ButtonTextRegular); */
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  border-color: transparent;
}
.pp-co-btn-modal:hover {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-black);
}
.pp-co-btn-light-grey {
  color: var(--color-dark-grey);
}
.pp-co-btn-dark-grey {
  color: var(--color-black);
}
.yt-form-spacer {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}
.co-bottom-bar {
  position: relative;
  border-top: 2px solid #dbdbdb;
}

.filter-close {
  display: none;
}
body {
  background-color: #f6f8fa !important;
  font: 15px HelveticaNeue, Arial, Helvetica, sans-serif !important;
  padding: unset !important;
  color: unset !important;
}

.mobile-filter {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  overflow: hidden;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
  background-color: #fff;
}

.mobile-nav {
  width: 120px;
  min-width: 120px;
  border: none;
  display: block;
  padding: 15px 0px;
  height: 100%;
}

.bb-mbtab-filter-ttl {
  font-size: 15px;
  line-height: 1.6;
  color: #3e454f;
}

.yt-filter-title {
  font-size: 18px;
  color: var(--color-black);
  line-height: 1.11;
  font-weight: 700;
}

.radius-10 {
  border-radius: 10px;
}

.yt-box-title {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
  margin-bottom: 25px;
}

.yt-ul-list-none {
  list-style: none;
}

.yt-cat-name,
.yt-color-name,
.yt-size-name {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-main-filter-box ul li:last-child {
  margin-bottom: 0 !important;
  /*padding-bottom: 0 !important;*/
}

.yt-filter-divider {
  display: block;
  height: 2px;
  background: #f6f8fa;
  margin: 7.5px 0;
}

.yt-filter-search-wrap {
  position: relative;
}

.yt-search-icn {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 22px;
  padding: 11px;
  box-sizing: content-box;
  background: #fff;
  bottom: 2px;
  border-radius: 7px;
  color: #a1a4a9;
}

.yt-color-search-bar {
  border-radius: 7px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  width: 100%;
  padding-left: 54px;
  text-transform: capitalize;
}

.yt-filter-checkbox-wrap .yt-filter-label {
  position: relative;
  cursor: pointer;
  margin: 0;
}

.yt-filter-checkbox-wrap .yt-filter-label:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}

.yt-filter-checkbox-wrap input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}

.yt-filter-checkbox-wrap input:checked + label:before {
  background-color: var(--color-primary);
  border: var(--color-primary);
}

.yt-filter-checkbox-wrap input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}

.yt-filter-checkbox-wrap {
  position: relative;
  text-align: right;
}

.yt-color-name {
  position: relative;
}

.yt-color-name::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.yt-color-name.Baby.Pink::after {
  background-color: #f4c2c2;
}

.yt-color-name.Beig::after {
  background-color: #f5f5dd;
}

.yt-color-name.Black::after {
  background-color: #000000;
}

.yt-color-name.Blue::after {
  background-color: #0000fe;
}

.yt-color-name.Bottle.Green::after {
  background-color: #006a4e;
}

.yt-color-name.Brown::after {
  background-color: #d3691f;
}

.yt-color-name.Green::after {
  background-color: #008001;
}

.yt-color-name.Indigo::after {
  background-color: #4c0182;
}

.yt-color-black {
  color: var(--color-black) !important;
}
.yt-filter-inner-wrap .yt-ul-list-none {
  max-height: 285px;
  overflow: auto;
  margin-right: -10px !important;
  padding-right: 10px !important;
}
/* width */
.yt-filter-inner-wrap .yt-ul-list-none::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.yt-filter-inner-wrap .yt-ul-list-none::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 2px;
}

/* Handle */
.yt-filter-inner-wrap .yt-ul-list-none::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.yt-filter-inner-wrap .yt-ul-list-none::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) and (min-width: 768px) {
  /* .yt-filter-inner-wrap.yt-price-filter-wrap {
    width: 33.3333333333%;
    float: left;
  } */
  .yt-filter-inner-wrap.yt-discount-wrapper .yt-ul-list-none li {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .yt-tb-filter-apply .yt-filte-btn-tb {
    width: 150px;
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 6px;
    border: none;
  }
  .yt-tb-filter-apply .yt-filter-apply {
    background-color: #d4c96d;
  }
  .yt-tb-filter-apply .yt-filter-cancel {
    background-color: #8b8f95;
    margin: 0 15px 0 0;
  }
  .yt-main-filter-box .yt-filter-inner-wrap {
    padding-bottom: 0 !important;
  }
  .yt-main-filter-box .yt-filter-inner-wrap:last-child {
    padding: 1.5rem !important;
  }
}
/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  .filter-close {
    cursor: pointer;
    display: inline;
    position: absolute;
    top: 2%;
    right: 3%;
  }
  .yt-filter-title {
    display: none;
  }
  .yt-filter-inner-wrap .yt-ul-list-none {
    list-style: none;
    margin: 0 -15px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .yt-filter-inner-wrap .yt-ul-list-none li {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 15px;
  }
  .yt-main-filter-box {
    border-radius: 10px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19) !important;
    /* border: solid 1px var(--color-dark-black); */
    border: solid 1px #242724 !important;
  }
  .yt-filter-divider {
    display: none;
  }
  .yt-product-filter-wrap .yt-main-filter-box:after {
    display: block;
    clear: both;
    content: "";
  }
  .yt-mb-filter-apply {
    position: absolute;
    bottom: 0;
    right: 24px;
    padding: 8px 20px;
  }
  .yt-mb-filter-apply .yt-filte-btn-mb {
    width: 150px;
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 6px;
    border: none;
  }
  .yt-mb-filter-apply .yt-filter-apply {
    background-color: #d4c96d;
  }
  .yt-mb-filter-apply .yt-filter-reset {
    background-color: #8b8f95;
    margin: 0 15px 0 0;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
  .filter-close {
    right: 6%;
  }
  .yt-mbtab-filter-wrap .tab-content {
    width: 100%;
  }
  .yt-mbtab-filter-wrap .nav-tabs {
    width: 120px;
    min-width: 120px;
    border: none;
    display: block;
  }
  .yt-mbtab-filter-wrap .nav-tabs li {
    width: 100%;
    border: none !important;
  }
  .yt-mbtab-filter-wrap .nav-tabs li a {
    border: none !important;
  }
  .yt-product-filter-wrap .yt-mbtab-filter-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #f9f9f9;
    border: solid 0.5px #dbdbdb;
    padding: 15px 0;
  }
  .yt-product-filter-wrap .yt-tab-filte-content-mb {
    position: absolute;
    right: 0;
    left: 120px;
    padding: 25px 30px 25px 20px;
  }
  .yt-mbtab-filter-wrap .nav-tabs li a.active {
    margin-right: -0.5px;
  }
  .yt-mbtab-filter-wrap .yt-mbtab-filter-ttl {
    font-size: 15px;
    line-height: 1.6;
    color: #3e454f;
  }
  .yt-mb-filter-apply {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 20px;
    background: #fff;
    box-shadow: 0 1px 20px 0 rgba(152, 165, 183, 0.5);
  }
  .yt-mb-filter-apply .yt-filte-btn-mb {
    width: 100%;
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 6px;
    border: none;
  }
  .yt-mb-filter-apply .yt-filter-apply {
    background-color: #d4c96d;
  }
  .yt-mb-filter-apply .yt-filter-cancel {
    background-color: #8b8f95;
    margin: 0 15px 0 0;
  }
  .yt-cat-name,
  .yt-color-name,
  .yt-size-name {
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-cmyt-mb-filter-search-bar {
    position: relative;
    margin-bottom: 22px;
  }
  .yt-cmyt-mb-filter-search-bar .yt-mb-filter-search-icn {
    position: absolute;
    max-width: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .yt-cmyt-mb-filter-search-bar .yt-mb-filter-search-filed {
    border: none !important;
    width: 100%;
    padding: 15px 15px 15px 35px;
    border-bottom: solid 1px #dbdbdb !important;
    outline: none !important;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-box-title {
    font-size: 18px;
    line-height: 1.11;
    font-weight: 700;
  }
  .yt-product-filter-wrap .yt-filter-inner-wrap {
    padding: 30px !important;
  }
}



.input-range__slider {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: var(--color-primary) !important;
  border: 2px solid #fff;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(80, 88, 103, 0.45);
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: "#324688";
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}
.input-range__label.input-range__label--min,
.input-range__label.input-range__label--max,
.input-range__label--value {
  display: none;
}
.yt-min-price,
.yt-max-price {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
}
/*# sourceMappingURL=index.css.map */

.radius-10 {
    border-radius: 10px;
  }
  .trans-fl-pg-mb-30 {
    margin-bottom: 30px;
  }
  .trans-fl-pg-mb-80 {
    margin-bottom: 80px;
  }
  .trans-fl-ttl {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.13;
    color: var(--color-orange);
  }
  .trans-fl-text {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey);
  }
  .trans-fl-btn {
    opacity: 0.99;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    border-color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: #ffffff;
  }
  .trans-fl-cancel-btn {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: var(--color-dark-grey);
    box-shadow: none !important;
  }
  .yt-transaction-cl-icn {
    max-height: 135px;
    width: auto;
  }
  .trans-fl-pg-inner-wrap {
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1280px) {
    .trans-fl-pg-inner-wrap {
      margin-top: 0px !important;
      margin-bottom: 60px;
    }
  }
  @media (min-width: 1900px) {
    .trans-fl-pg-inner-wrap {
      margin-top: 0px !important;
      margin-bottom: 80px;
    }
  }
  /*
  * Screen for under 1280 Resolution 
  */
  @media (max-width: 1279.92px) {
    .trans-fl-pg-inner-wrap {
      margin-top: 20px !important;
      margin-bottom: 20px;
    }
    .trans-fl-wrap {
      margin-top: 75px;
      margin-bottom: 40px;
    }
  }
  /*
  * Screen for under 768 Resolution 
  */
  @media (max-width: 767.92px) {
    .trans-fl-pg-inner-wrap {
      margin-top: 15px !important;
      margin-bottom: 15px;
      padding: 11px 20px 20px !important;
    }
    .trans-fl-btn {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      line-height: 1.14;
      font-size: 14px;
      letter-spacing: 0.4px;
      max-width: 100%;
    }
    .cart-pg-empty-main-wrap {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
    .trans-fl-cancel-btn {
      font-size: 12px;
      line-height: 1.17;
    }
    .trans-fl-ttl {
      font-size: 16px;
      line-height: 1.13;
    }
    .trans-fl-text {
      font-size: 14px;
      line-height: 1.14;
    }
    .trans-fl-wrap {
      margin-bottom: 22px !important;
      margin-top: 20px !important;
    }
  }
  
#sort-filter {
  display: none;
}

.deals {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}

.products {
  background-color: white;
  padding: 30px;
  margin-bottom: 20px;
}

.more {
  float: right;
  font-size: 13px;
  cursor: pointer;
}

.one {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}

.two {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}

.three {
  width: 100%;
  height: 100%;
  margin-left: 0;
  float: left;
  margin-right: 0;
  padding-right: 0;
}

.fullscreen {
  width: 100%;
}

.oneintwo {
  margin-top: 20px;
  margin-bottom: 20px;
}

.shopnowbutton {
  width: 140px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}

.savetext {
  color: black;
  font-weight: 600;
  font-size: 20px;
}

.textblock1 {
  margin-top: -150px;
  margin-left: 80px;
}

.welcometext {
  color: black;
  font-weight: 600;
  font-size: 40px;
}

.textblock2 {
  margin-top: -350px;
  margin-left: 80px;
}

.headtop {
  color: white;
  font-weight: 200px;
}

.uptooff {
  color: white;
  font-weight: 600;
  font-size: 60px;
}

.textblock3 {
  margin-top: -300px;
  margin-left: 350px;
}

.logohead1 {
  margin-left: 30px;
  font-weight: 500px;
  margin-top: -60px;
  font-size: 20px;
  color: white;
}

.shopnowgoldenbutton {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: -70px;
  float: right;
  margin-right: 10px;
}

.shopnowgoldenbutton1 {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 20px;
}

.dontmiss {
  font-size: 30px;
}

.offthirty {
  font-size: 70px;
  font-weight: 600;
}

.textbox4 {
  margin-left: 120px;
  margin-top: -500px;
}

.hurryup {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}

.dailydeal {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}

.lowprice {
  color: white;
}

.textbox5 {
  margin-left: 50px;
  margin-top: -200px;
}

.happytimes {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}

.flat {
  font-size: 40px;
  color: white;
  font-weight: 700px;
}

.textbox7 {
  margin-top: -230px;
  margin-left: auto;
  margin-right: auto;
}

.stores {
  width: 30%;
  cursor: pointer;
  margin-top: 70px;
}

.playbuttons {
  margin-left: 190px;
  margin-right: auto;
}

.download {
  font-size: 40px;
  font-weight: 400;
  color: black;
  margin-left: 100px;
}

.imglast {
  width: 100%;
  height: 100%;
}

.prodcluster {
  margin-bottom: 10px;
  margin-top: 10px;
}

.filterpage {
  margin-bottom: 100px;
  margin-top: 30px;
}

.filterpanel {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
}

.abayas {
  font-size: 20px;
  font-weight: 500;
}

.yt-shop-category-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
  color: var(--color-black);
}

.filter_abayas {
  margin-top: 35px;
}

.recommeded {
  float: right;
  margin-right: -23px;
}

.recomdrop {
  margin-right: 20px;
  margin-left: 10px;
}

.selectedopt {
  /* width: 90px; */
  /* height: 40px; */
  border-radius: 24px;
  border: solid 1px #cae2fe;
  background-color: #ffffff;
  padding: 5px;
  margin-left: 5px;
  font-size: 13px;
}

.showingnum {
  float: right;
}

.yt-product-showing-total-item {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-first,
.yt-second {
  color: var(--color-black);
}

.yt-selected-filter-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pageroute {
  color: gray;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 1.17;
}

.currpage {
  color: black;
}

.filter_view {
  margin-bottom: 5px;
  color: grey;
}

.cat_name {
  margin-bottom: 10px;
  padding-left: 20px;
}

.cat_name_top {
  padding-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.listing {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
}

.checkbox_div {
  margin-right: 20px;
  padding-top: 5px;
}

.cat_item {
  color: gray;
  margin-top: 2px;
  margin-bottom: 2px;
}

.color_opt {
  height: 10px;
  width: 10px;
  background-color: pink;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.searchcontainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.searchinput {
  border-radius: 6px;
  border: solid 1px #cae2fe;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: 90%;
  padding-left: 48px;
}

.searchicon {
  height: auto;
  width: 14px;
  position: absolute;
  left: 50px;
}

.cat_color {
  color: gray;
}

.slider {
  width: 90%;
  height: 20px;
  background: var(--color-primary);
  opacity: 0.7;
  transition: opacity 0.2s;
}

.yt-product-page-recommend-btn:active,
.yt-product-page-recommend-btn:focus,
.yt-product-page-recommend-btn:hover {
  color: var(--button-text-primary) !important;
}

.yt-product-page-recommend-btn:active,
.yt-product-page-recommend-btn:focus,
.yt-product-page-recommend-btn:hover {
  color: var(--color-black) !important;
}

.view-toggle-icon {
  font-size: 34px;
  padding-right: 10px;
}

.view-toggle-icon.active {
  color: var(--color-primary);
}

.loadMoreBtn {
  text-align: center !important;
  margin-top: 20px;
}

.loadMoreBtn .btn-outline-secondary:hover {
  color: #fff;
  background-color: #3e454f;
  border: 1px solid #3e454f;
}
.pageroute .cart-pg-home {
  color: var(--color-black) !important;
  font-weight: 500 !important;
}
.pageroute .cart-pg-home:hover {
  color: var(--color-sky-blue) !important;
  font-weight: bold !important;
}

/* for mobile */

@media only screen and (max-width: 992px) {
  .shopnowbutton {
    width: 100px;
    padding: 5px;
    height: 30px;
  }
  .shopnowgoldenbutton1 {
    width: 100px;
    padding: 5px;
    height: 30px;
  }
  .savetext {
    font-size: 15px;
  }
  .textblock1 {
    margin-top: -90px;
    margin-left: 50px;
  }
  .welcometext {
    color: black;
    font-weight: 600;
    font-size: 20px;
  }
  .textblock2 {
    margin-top: -200px;
    margin-left: 40px;
    width: 50%;
  }
  .headtop {
    font-weight: 200px;
  }
  .uptooff {
    color: white;
    font-weight: 600;
    font-size: 30px;
  }
  .textblock3 {
    margin-top: -250px;
    margin-left: 200px;
    width: 50%;
  }
  .three {
    padding-bottom: 10px;
  }
  .logohead1 {
    width: 100%;
  }
  .hurryup {
    font-size: 20px;
    color: white;
    font-weight: 600px;
  }
  .dailydeal {
    font-size: 15px;
    color: white;
    font-weight: 600px;
  }
  .lowprice {
    color: white;
    font-size: 10px;
  }
  .textbox5 {
    margin-left: 30px;
    margin-top: -160px;
    width: 50%;
  }
  .happytimes {
    font-size: 20px;
    color: white;
    font-weight: 600px;
  }
  .flat {
    font-size: 25px;
    color: white;
    font-weight: 700px;
  }
  .stores {
    width: 30%;
    margin-top: 0px;
  }
  .playbuttons {
    margin-left: 120px;
    margin-right: auto;
  }
  .download {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 120px;
  }
  .textbox7 {
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
  }
  .imglast {
    width: 100%;
    height: 100%;
  }
  .dontmiss {
    font-size: 15px;
  }
  .offthirty {
    font-size: 25px;
    font-weight: 600;
  }
  .textbox4 {
    margin-left: 40px;
    margin-top: -150px;
    width: 50%;
  }
}
/* for mobile */

@media only screen and (max-width: 992px) {
  .filtertop {
    margin-top: 60px;
  }
  .selectedprops {
    margin-bottom: 10px;
  }
  .selectedopt {
    margin-top: 5px;
  }
}

.dropcustom {
  background-color: skyblue;
}

.removefilter {
  cursor: pointer;
}

.clearall {
  padding-top: 5px;
  margin-left: 5px;
  color: red;
  font-size: 13px;
  cursor: pointer;
}

.all-prod-sort-tag-name {
  line-height: 2.4em;
  font-size: 18px;
  color: var(--color-dark-grey);
}

.yt-recommend-wrapper {
  width: 100%;
  box-shadow: none;
  border: none;
  margin: 0;
  border-radius: 0;
  border: solid 1px #cae2fe;
  border-top: none !important;
}

.yt-recommend-inner .show .yt-product-page-recommend-btn {
  box-shadow: 0px -20px 20px 0 rgba(0, 0, 0, 0.05);
}

.yt-recommend-inner .show .yt-recommend-wrapper {
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/**/

.yt-close-icn {
  cursor: pointer;
  margin-left: 13px;
}

.yt-product-page-recommend-btn {
  border-radius: 10px !important;
  border: solid 1px#e2e2e2 !important;
  background-color: #fff !important;
  min-width: 228px;
  text-align: left;
  box-shadow: none !important;
  position: relative;
  color: var(--color-black);
  font-size: 18px !important;
  font-weight: 500;
  line-height: 1.11 !important;
  padding: 14px !important;
}

.yt-load-more {
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-black);
}

.yt-product-page-recommend-btn::after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-black);
}

.current-active-filter {
  position: relative;
}

.current-active-filter::after {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  background-size: cover;
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABHNCSVQICAgIfAhkiAAAAYNJREFUOE+tlEFuglAQhv+BJsKqHkFPUI4ANmmiK11qF7UnaD2BegNvULtQl7jSxKSKN/AIvUF1BRuY5r0IQXkgacpuwrwv8///vEf4x4/yWPbOrZLvPxCRLXqY6MCVyt5zOse8MxmYgGi+PySid9UhBqaRYQxU0AuYvZlZWqi5BNSK1DPzMbpjx3t6PqT7EpiYSPf9HYisMjZKoGnW0xMmsMZqPiGitzKgVM/nV7Pbj2sJk1MFwc8tEAPjswUvcW8I1L1m91vUEva4mrdB5Bb6BLxGhrG8toKZB9tWb5LAGuvFiIDhGXZiYJKqwTkguTLAeNvsjtQw5kNomo4WBG0CPopASlhG5hmIIKjCMI5FKWdkKgMQQKKOzuwWrUsmABnCejEFkKR0K1kpkXm/bfXkdUs8S62HiPi+DAjAKQSseC0uYBK4mVl6qHklgKdQj+zc6xRPY68XNR0QUSslC2kRUT89UUbmtTQB1ZjFMlflP3EXiZYqyE1YSd8u2nIfx7/AfgE25rsU8uIr8gAAAABJRU5ErkJggg==);
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.yt-filter-selected .yt-flt-tag {
  border-radius: 5px;
  border: solid 1px #e2e2e2;
  background-color: #ffffff;
  padding: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.13;
  font-size: 16px;
  color: var(--color-black);
}

.yt-recommend-inner .show .yt-product-page-recommend-btn {
  background: #fff !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: inherit !important;
  border: solid 1px #cae2fe !important;
  border-bottom: none !important;
}

.yt-recommend-wrapper .dropdown-item {
  padding: 10px 14px;
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  font-weight: 300;
}

.yt-recommend-wrapper .dropdown-item.active,
.yt-recommend-wrapper .dropdown-item:active {
  background-color: white;
  color: var(--color-primary);
  font-weight: 500;
}

.yt-clear-all {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-orange);
}

.yt-shop-pg-cm-lft-col-pd {
  padding-right: 10px;
}

.yt-shop-pg-cm-rgt-col-pd {
  padding-left: 10px;
}

.yt-cm-lt-col {
  padding-right: 10px;
}

.yt-cm-rt-col {
  padding-left: 10px;
}

.yt-product-page-recommend-btn .btn-secondary:hover {
  color: #212529 !important;
}

button.yt-product-page-recommend-btn.dropdown-toggle.btn.btn-secondary {
  text-align: left !important;
  position: relative;
}

.dropdown-toggle {
  color: #212529 !important;
}

@media (min-width: 1900px) {
  #sort-filter {
    display: flex;
  }
  .yt-cm-lt-col {
    padding-right: 20px;
  }
  .yt-cm-rt-col {
    padding-left: 20px;
  }
  .yt-shop-pg-cm-lft-col-pd {
    padding-right: 20px;
  }
  .yt-shop-pg-cm-rgt-col-pd {
    padding-left: 20px;
  }
  .yt-filter-selected .yt-flt-tag,
  .yt-product-showing-total-item {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-selected-filter-wrap {
    margin-top: 22px;
  }
  .yt-shop-category-name {
    font-size: 30px;
    line-height: 1.13;
  }
  .yt-filter-selected .yt-flt-tag {
    line-height: 1.11;
    font-size: 18px;
  }
}

@media (min-width: 1280px) {
  .yt-sp-mb-recomment-wrap,
  .yt-sp-mb-filter-wrapper {
    display: none;
  }
}

@media (max-width: 1279.92px) and (min-width: 768px) {
  .yt-cm-lt-col.ytMbfilteropen {
    display: block;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 10;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 20px;
  }
  .selected-sort-name {
    display: none;
  }
  #sort-filter {
    display: flex;
  }
}
@media (min-width: 768px) {
  .yt-mb-close-filter {
    display: none;
  }
  #sort-filter {
    display: flex;
  }
}
/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  #sort-filter {
    display: flex;
  }
  .yt-filter-selected {
    display: none !important;
  }
  #SingelnewProducts {
    padding-top: 35px;
  }
  .all-prod-sort-tag-name,
  .yt-product-page-recommend-btn span,
  .yt-product-page-recommend-btn::after {
    display: none;
    left: 0px;
    top: 70%;
  }

  .yt-product-page-recommend-btn {
    background-color: transparent !important;
    border: none !important;
    min-width: auto;
  }
  .yt-product-page-recommend-btn:active,
  .yt-product-page-recommend-btn:focus,
  .yt-product-page-recommend-btn:hover {
    color: var(--button-text-primary) !important;
  }
  .yt-recommend-wrapper .dropdown-item {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-product-page-recommend-btn .yt-recmnd-mb-txt,
  .yt-sp-mb-filter-wrapper .yt-recmnd-mb-txt {
    font-size: 15px;
    line-height: 1.14;
    padding-left: 5px;
  }
  .filter_abayas {
    margin-top: 0;
  }
  .filterpage .yt-cm-row.row {
    margin-right: -20px;
    margin-left: -20px;
    position: relative;
  }
  .filterpage .filtertop {
    margin-top: 0;
    position: absolute;
    top: -50px;
    left: 20px;
    right: 20px;
  }
  .yt-recommend-wrapper {
    left: auto !important;
    right: 0 !important;
    min-width: 200px;
    transform: translate3d(-0px, 48px, 0px) !important;
    border-radius: 10px;
    border: solid 1px var(--color-dark-black) !important;
    padding: 0;
    overflow: hidden;
  }
  .yt-recommend-inner .show .yt-product-page-recommend-btn {
    background: transparent !important;
    border: none !important;
  }
  .filterpage {
    margin-top: 0;
  }
  .yt-shop-category-name {
    font-size: 20px;
    line-height: 1.15;
  }
  .yt-sp-mb-filter-wrapper {
    cursor: pointer;
  }
}
/*
* Screen for under 768 Resolution 
*/

@media (max-width: 767.92px) {
  #sort-filter {
    display: flex;
  }
  .responsive-css {
    margin-left: 2px !important;
  }
  #SingelnewProducts {
    padding-top: 25px;
  }
  .yt-recommend-wrapper .dropdown-item {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-recommend-wrapper {
    min-width: 191px;
  }
  .yt-cm-lt-col.ytMbfilteropen {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    background-color: #fff;
  }
  .yt-cm-lt-col.ytMbfilteropen .yt-mb-close-filter {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 22px;
    z-index: 60;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  #sort-filter {
    display: flex;
  }
  .yt-product-page-recommend-btn {
    font-size: 16px !important;
  }
  .yt-filter-selected .yt-flt-tag {
    font-size: 14px;
  }
  .yt-clear-all {
    font-size: 14px;
  }
  .yt-recommend-wrapper .dropdown-item {
    font-size: 16px;
  }
  .all-prod-sort-tag-name {
    font-size: 16px;
    line-height: 3;
  }
}


#pagination > .pagination {
  justify-content: center;
      background-color: #242724;
      padding: 15px 0px;
      color: var(--color-primary);
  }

  #pagination > .pagination > li{
    padding: 0px 10px;
  }
  #pagination > ul.pagination {
    margin-bottom: 0px;
  }
  #pagination > .pagination > .active{
    border-bottom: 1px solid var(--color-primary);
    padding: 0px 10px;
}
.radius-10 {
  border-radius: 10px;
}
.mt-6 {
  margin-top: 3.8rem;
}
.cart-pg-mb-30 {
  margin-bottom: 30px;
}
.yt-currency-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cart-pg-breadcrumbs {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 30px;
}

.cartno-wrap {
  margin-top: 52px;
  margin-bottom: 40px;
}

.cart-pg-current {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}

.empty-cartn-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 17px;
}

.empty-cart-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.empty-cart-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
}

.cart-page-main-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 30px;
}

.cart-prodict-info {
  width: 100%;
}

.cart-product-image {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.cart-product-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
  margin-bottom: 0px;
  max-width: 310px;
  display: inline-block;
}

.cart-action-wrap {
  float: right;
}

.yt-product-code {
  color: silver;
  margin-bottom: 5px;
}

.yt-product-code {
  color: silver;
  margin-bottom: 5px;
  font-size: 11px;
}
.cart-action-separator {
  color: #b2b3b3;
  font-size: 17px;
  vertical-align: top;
}

.cart-prodict-type-container {
  max-width: 17vw;
  overflow-x: auto;
  margin-bottom: 20px;
}
.cart-product-head-container {
  display: flex;
}

@media (max-width: 768px) {
  .cart-prodict-type-container {
    max-width: 54vw;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .cart-prodict-type-container {
    display: inline;
  }

  .yt-product-code {
    font-size: 11px;
  }
}

/* width */
.cart-prodict-type-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.cart-prodict-type-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cart-prodict-type-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.cart-prodict-type-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-prodict-type th {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: #15aa71;
}

.cart-prodict-type th,
.cart-prodict-type td {
  padding: 3px;
}

.cart-product-price {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: #5b5b5b;
}

.cart-product-delete {
  font-size: 14px;
  color: var(--color-orange);
  cursor: pointer;
  display: inline;
}

.cart-product-wishlist {
  font-size: 14px;
  color: #30458c;
  cursor: pointer;
  display: inline;
}

.cart-prodict-amount th {
  font-size: 20px !important;
  font-weight: 700;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-product-amount-ttl {
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-black);
  font-weight: normal;
}

.cart-product-amount-qty {
  color: silver;
}

.cart-product-amount-price {
  font-size: 18px;
  line-height: 1.11;
  text-align: right;
  color: var(--color-dark-grey);
  display: block;
}

.cart-prodict-amount thead th {
  padding-top: 0;
  padding-bottom: 18px;
  padding-left: 0;
  padding-right: 0;
}

.cart-prodict-amount thead th:first-child {
  width: 60%;
}

.cart-prodict-amount tbody td,
.cart-prodict-total-amount tbody td,
.ccart-prodict-sub-total-amount tbody td,
.cart-prodict-sub-total-amount tbody td {
  padding: 10px 0;
}

.cart-divider {
  border-bottom: solid 1px #e2e2e2 !important;
  display: block;
  margin: 15px 0 20px;
}

.cart-coupon .form-group {
  position: relative;
}

.cart-coupon .form-control {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}

.cart-coupon-btn {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-orange);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: var(--color-orange);
}

.cart-proceed-to-checkput {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  opacity: 0.99;
  border-radius: 5px;
  /* background-color: var(--button-primary); */
  background-color: var(--color-ButtonBackRegular);
  border-color: var(--button-primary);
  /* color: var(--button-text-primary); */
  color: var(--color-ButtonTextRegular);
}
.cart-proceed-to-checkput:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.cart-checkout-tile {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-select-aar-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: black;
}

.checkout-form-wrap .form-group .form-control {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb !important;
  border-radius: 0;
}

.checkout-form-label {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-dark-grey);
}

.checkout-checkbox .form-group,
.yt-chekout-radio .form-group {
  padding-left: 25px;
  position: relative;
  line-height: 25px;
}

.yt-empty-cart-wrap {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  margin: 0;
}

.cart-pg-inner-wrap .yt-checkout-form-chk-box,
.cart-pg-inner-wrap .yt-checkout-form-rado-box {
  position: absolute;
  cursor: pointer;
  margin: 0;
  left: 0;
  top: 0;
}

.cart-pg-inner-wrap .checkout-checkbox .yt-checkout-form-chk-box:before,
.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}

.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 5px;
}

.yt-checkout-chekbox-label {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-black);
}

.cart-pg-inner-wrap
  .checkout-checkbox
  .form-check-input:checked
  + label:before {
  background-color: var(--color-primary);
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  z-index: 5;
  background: var(--color-primary);
  border-radius: 25px;
  left: 4px;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label:before {
  border-color: var(--color-primary);
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
  bottom: 0;
  height: 100%;
}

.checkout-form-wrap .form-check-label {
  line-height: 1.5;
}

.cart-coupon .error .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}

.cart-coupon .success .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}

.cart-coupon-change-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}

.cart-coupon .cart-coupon-bottom-wrapper {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}

.cart-coupon .error .cart-coupon-bottom-wrapper,
.cart-coupon .success .cart-coupon-bottom-wrapper {
  opacity: 1;
}
form.yt-cart-disct-wrap .yt-form-cpn-err.form-group,
form.yt-cart-disct-wrap .success.form-group {
  margin-bottom: 20px !important;
}
.cart-coupon .success .cart-coupon-bottom-wrapper .error-message,
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message,
.login-on-cart-page .lp-bottom-links {
  display: none;
}

.yt-login-wrap {
  margin-top: 0 !important;
}

.cart-prodict-amount thead th:last-child {
  text-align: right;
}

.cart-prodict-type tr td {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}

.cart-pg-list-image {
  width: 20%;
}

.cart-pg-list-prdt-info {
  width: 75%;
}

.cart-list-other-act {
  position: relative;
}

.cart-quantity-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 115px;
}

.checkout-form-wrap .yt-remove-arrow::-webkit-outer-spin-button,
.checkout-form-wrap .yt-remove-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.checkout-form-wrap .yt-remove-arrow[type="number"] {
  -moz-appearance: textfield;
}

.cart-produt-list-wrap {
  padding: 40px 40px 38px;
}

.yt-cart-price-lister {
  padding: 40px 30px 56px;
  margin-bottom: 30px;
}

.checkout-form-wrap .cart-pg-inner-wrap {
  padding: 40px 30px 30px;
}

.yt-cm-lt-col {
  padding-right: 7px;
}

.yt-cm-rt-col {
  padding-left: 7px;
}

@media (min-width: 768px) {
  .cart-proceed-to-checkput.cart-btn-bill-pg {
    display: none;
  }
  .cart-pg-list-image .cart-product-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .cart-pg-list-image .cart-product-image {
    width: 100%;
    height: 10vw;
  }
}

@media (max-width: 992px) {
  .cart-product-head-container {
    display: block;
  }
}

@media (min-width: 1280px) {
  .empty-main-ttl {
    display: none;
  }
  .cart-prodict-type th,
  .cart-prodict-type td {
    padding: 3.5px 0;
    padding-right: 74px;
  }
  .cart-prodict-type th:last-child,
  .cart-prodict-type td:last-child {
    padding-right: 0;
  }
}

@media (min-width: 1280.88px) {
  .yt-cm-lt-col {
    padding-right: 10px;
  }
  .yt-cm-rt-col {
    padding-left: 10px;
  }
}

@media (min-width: 1900px) {
  .yt-cm-lt-col {
    padding-right: 20px;
  }
  .yt-cm-rt-col {
    padding-left: 20px;
  }
  .cart-product-title {
    line-height: 1.67;
    max-width: 100%;
  }
  .cart-produt-list-wrap {
    padding: 40px 40px 37px;
  }
  .mt-6 {
    margin-top: 4.8rem;
  }
}

/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  .empty-cartn-ttl {
    font-size: 24px;
    line-height: 1.17;
  }
  .empty-cart-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .empty-cart-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .yt-empty-cart-wrap.cart-pg-inner-wrap {
    margin-top: 10px !important;
  }
  .cart-page-main-title {
    margin-bottom: 10px !important;
    font-size: 24px;
    line-height: 1.17;
  }
  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
    padding-bottom: 34px;
  }
  .yt-cart-price-lister {
    padding: 20px 15px;
  }
  .cart-product-title {
    font-size: 16px;
    line-height: 1.13;
  }
  .cart-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  .cart-prodict-type tr td {
    font-size: 16px;
    line-height: 1.31;
  }
  .cart-product-price {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
  }
  .cart-prodict-amount thead th:first-child {
    font-size: 18px;
    line-height: 1.11;
  }
  .cart-prodict-amount thead th:last-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
  }
  .cart-product-amount-ttl {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .cart-product-amount-price {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 7.5px 0;
  }
  .cart-divider {
    margin: 15px 0;
  }
  .cart-proceed-to-checkput {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .cart-coupon .form-control {
    /* padding: 12px 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }
  .cart-coupon .error .cart-coupon-code-message {
    font-size: 12px;
    line-height: 1.58;
  }
  .cart-coupon-change-btn {
    font-size: 14px;
    line-height: 1.14;
  }
  .checkout-form-wrap .yt-chekout-radio .yt-currency-wrap.form-group {
    padding-left: 0 !important;
  }
}

/*
* Screen for under 768 Resolution 
*/

@media (max-width: 767.92px) {
  .cart-pg-inner-wrap
    .yt-chekout-radio
    .form-check-input:checked
    + label:after {
    top: 4px !important;
  }
  .cart-pg-list-image .cart-product-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .cart-pg-list-image .cart-product-image {
    width: 70px;
    height: 70px;
  }
  .cart-checkout-wrap .cart-proceed-to-checkput {
    margin-bottom: 15px;
  }
  .yt-cm-row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .yt-cm-lt-col {
    padding-right: 11px;
    padding-left: 11px;
  }
  .yt-cm-rt-col {
    padding: 0;
  }
  .empty-cartn-ttl {
    font-size: 17px;
    line-height: 1.12;
  }
  .empty-cart-text {
    font-size: 15px;
    line-height: 1.2;
  }
  .empty-cart-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .cart-page-main-title {
    font-size: 18px;
    line-height: 1.11;
  }
  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
    padding-bottom: 34px;
  }
  .cart-product-title {
    font-size: 14px;
    line-height: 1.36;
  }
  .cart-prodict-type th {
    /* font-size: 10px; */
    line-height: 1.1;
    letter-spacing: 0.38px;
  }
  .cart-prodict-type tr td {
    font-size: 14px;
    line-height: 1.5;
  }
  .cart-product-price {
    font-size: 15px;
    line-height: 1.2;
  }
  .cart-product-delete svg {
    font-size: 15px;
  }
  .cart-page-main-title {
    margin-bottom: 10px !important;
  }
  .yt-cart-price-lister {
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
  }
  .cart-prodict-amount thead th:first-child {
    font-size: 16px;
    line-height: 1.19;
  }
  .cart-prodict-amount thead th:last-child {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
  }
  .cart-product-amount-ttl {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
  }
  .cart-product-amount-price {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
  }
  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 8px 0;
  }
  .cart-divider {
    margin: 3px 0 7px;
  }
  .cart-coupon .form-control {
    /* padding: 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }
  .cart-proceed-to-checkput {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .checkout-form-wrap .cart-pg-inner-wrap {
    padding: 20px;
    margin-bottom: 10px;
  }
  .cart-checkout-tile {
    font-size: 15px;
    line-height: 1.2;
  }
  .cart-select-aar-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .checkout-form-label {
    font-size: 12px;
    line-height: 1.17;
  }
  .cart-pg-inner-wrap .checkout-checkbox .form-check-input {
    font-size: 10px;
    line-height: 1.1;
  }
  .checkout-form-wrap .yt-chekout-radio .form-check-label {
    font-size: 12px;
    line-height: 1.17;
  }
  .checkout-form-wrap .yt-chekout-radio .form-check-label .form-check-input {
    margin-top: 0px;
  }
  /* .checkout-form-wrap .yt-chekout-radio > div {
    width: 100%;
    margin-bottom: 10px;
  } */
  .cart-pg-inner-wrap .yt-checkout-form-rado-box {
    top: 6px;
  }
}

@media (max-width: 575.98px) {
  .empty-cart-text {
    max-width: 233px;
    margin-left: auto;
    margin-right: auto;
  }
  .empty-cart-btn {
    width: 100%;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-chekout-radio .checkout-form-label {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-inner-wrap .yt-items.item {
    padding-top: 30px;
  }
  .empty-cart-btn {
    font-size: 16px;
  }
  .cart-page-main-title {
    font-size: 26px;
  }
  .cart-proceed-to-checkput {
    font-size: 16px;
  }
  .cart-produt-list-wrap {
    padding: 30px;
    margin-bottom: 20px !important;
  }
  .yt-cart-price-lister {
    padding: 30px;
  }
  .cart-divider {
    margin: 10px 0 !important;
  }
  form.yt-cart-disct-wrap {
    padding: 10px 0 !important;
  }
  .cart-prodict-amount tbody tr:last-child td {
    padding-bottom: 20px;
  }
  table.yt-sub-ttl-tbl-wrap.table tr:first-child td {
    padding-top: 20px;
  }
  table.yt-sub-ttl-tbl-wrap.table {
    margin: 0;
  }
}

/*
* Add Address Modal CSs
*/
.select-addr-title-bar span {
  position: absolute;
  left: 0;
  /* right: 0; */
  right: 6%;
  text-align: center;
  margin: 0 !important;
  top: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.select-addr-body-wrap .form-control {
  height: auto;
  border: none;
  border-bottom: 2px solid #f6f8fa;
  box-shadow: none;
  padding-left: 0;
}
.select-addr -btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.select-addr-body-wrap .pp-sa-all-addres-list li {
  border-radius: 10px;
  border: solid 2px #cae2fe;
}
.select-addr-body-wrap .pp-sa-all-addres-list li.active {
  border-radius: 10px;
  border: solid 2px #15aa71;
}
.select-addr-body-wrap .pp-na-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.select-addr-body-wrap .pp-na-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.select-addr-body-wrap .pp-no-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.select-addr-body-wrap .pp-sa-list-none {
  list-style: none;
}
.select-addr-body-wrap .pp-sa-type {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.select-addr-body-wrap .pp-sa-address {
  font-size: 16px !important;
  line-height: 1.15 !important;
  color: var(--color-dark-grey) !important;
  text-align: left;
  font-weight: bold;
}
.radius-10 {
  border-radius: 10px;
  position: relative;
}
.select-addr-body-wrap .profile-pg-mb-30 {
  margin-bottom: 30px;
}
.select-addr-body-wrap .pp-sa-all-addres-list li:last-child {
  margin-bottom: 40px;
}
.yt-cm-sadd-body {
  padding-top: 40px !important;
}
.pp-sa-all-addres-list li {
  margin-bottom: 20px;
}
.pp-sa-all-addres-list li {
  cursor: pointer;
}
.select-addr-modal-btn {
  border-radius: 6px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  /* color: #ffffff; */
  color: var(--color-ButtonTextRegular);
}
.select-addr-modal-btn:hover {
  color: var(--color-ButtonTextHover);
}
.select-addr-body-wrap .profile-pg-inner-wrapper {
  min-height: 420px;
}
.select-add-bg-blue {
  /* background-color: var(--color-dark-black); */
  background-color: var(--color-ButtonBackRegular);
}
.select-add-bg-blue:hover {
  background-color: var(--color-ButtonBackHover);
}
.select-add-bg-black {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mr-2.select-add-bg-blue.btn-block {
  /* background: var(--color-primary); */
  background-color: var(--color-ButtonBackRegular);
}
button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mr-2.select-add-bg-blue.btn-block:hover {
  background-color: var(--color-ButtonBackHover);
}

button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mt-0.select-add-bg-black.btn-block {
  /* background: var(--color-primary); */
  background-color: var(--color-ButtonBackRegular);
}
button.btn.btn-btn.btn-secondary.select-addr-modal-btn.py-3.mt-0.select-add-bg-black.btn-block:hover {
  background-color: var(--color-ButtonBackHover);
}
/*
* Add Address Modal CSs
*/
.add-addr-title-bar {
  border-color: #dbdbdb;
  border-width: 2px;
}
.add-addr-title-bar span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.ad-addr-body-wrap .form-control {
  height: auto;
  border: none;
  border-bottom: 2px solid #dbdbdb;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-black);
}
.ad-addr-body-wrap .profile-form-tag {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.add-addr-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
  margin-top: 30px;
}
.profile-form-field.error .profile-form-tag {
  color: var(--color-orange);
}
.profile-form-field.error .form-control {
  border-color: var(--color-orange);
  color: var(--color-orange);
}
.invalid_error {
  color: var(--color-orange);
}
.fields-active.error {
  padding-top: 5px;
  display: block;
}
@media (min-width: 1366.8px) {
  .select-addr-body-wrap .profile-pg-inner-wrapper {
    min-height: 500px;
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .select-addr-modal-btn {
    font-size: 16px;
    line-height: 1.13;
  }
  .profile-pg-inner-wrap {
    padding: 20px !important;
  }
  .yt-cm-sadd-row.row .yt-cm-sadd-col:first-child {
    padding-right: 10px;
    max-width: 13%;
    flex: 0 0 13%;
  }
  .yt-cm-sadd-row.row .yt-cm-sadd-col:last-child {
    padding-left: 10px;
    padding-right: 0;
  }
  .yt-cm-sadd-row.row .yt-sadd-ttl-chek-img {
    margin-bottom: 6px !important;
  }
  .yt-cm-sadd-row.row .pp-sa-delet.text-right img {
    width: 29px;
  }

  .ad-addr-body-wrap .cm-edit-add-col {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .ad-addr-body-wrap {
    padding: 8px 0;
  }
  .ad-addr-body-wrap .form-group {
    margin-bottom: 30px;
  }
  .ad-addr-body-wrap .add-addr-btn {
    margin-top: 0;
    font-size: 16px;
    line-height: 1.13;
  }
  .fields-active.error {
    font-size: 14px;
    line-height: 1.14;
  }
}
@media (max-width: 767.98px) {
  /* .pp-sa-info-wrap {
    margin: 20px 0;
  } */
  .yt-sadd-ttl-chek-img {
    margin-bottom: 5px !important;
  }
  .pp-sa-delet img {
    margin-bottom: 0 !important;
  }
  .yt-cm-sadd-body.modal-body {
    padding: 14px 10px !important;
  }
  .yt-cm-sadd-col:last-child {
    max-width: 80%;
    padding-left: 7.5px;
  }
  .yt-cm-sadd-col:first-child {
    width: 20%;
    padding-right: 7.5px;
  }
  .pp-sa-img-wrap img {
    max-width: 34px;
    height: auto;
  }
  .select-addr-body-wrap .pp-sa-type {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  .select-addr-body-wrap .pp-sa-address {
    font-size: 14px;
    line-height: 1.14;
    text-align: left;
  }
  /* .select-addr-modal-btn.select-add-bg-blue {
    display: none;
  } */
  .yt-mb-add-new-address-nbtn {
    display: block !important;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    color: var(--color-black);
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-add-modal-body {
    padding: 30px 40px 20px !important;
  }
  .ad-addr-body-wrap .profile-form-tag {
    font-size: 14px;
    line-height: 1.14;
  }
  .ad-addr-body-wrap .form-control {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px;
    line-height: 1.11;
  }
  .add-addr-btn {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: normal;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
  .add-addr-title-bar span {
    font-size: 18px;
    line-height: 1.11;
  }
  .ad-addr-body-wrap .profile-form-tag {
    font-size: 13px;
    line-height: 1.15;
  }
  .ad-addr-body-wrap .form-control {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 17px;
    line-height: 1.12;
  }
  .ad-addr-body-wrap .yt-fp-form .form-group {
    margin-bottom: 20px !important;
  }
  .yt-add-modal-body {
    padding: 20px 15px !important;
  }
  .add-addr-btn {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.w3-hover-opacity:hover {
  opacity: 0.5;
}

.search-scroll {
  overflow-y: scroll;
}
.yt-main-header {
  box-shadow: 0px 6px 35px -12px rgba(0, 0, 0, 0.2);
}
.shop-list-of-info{
  height: 75vh;
  overflow-y: scroll;
}
.shop-list-of-info::-webkit-scrollbar{
  width: 2px;
}
.shop-list-of-info::-webkit-scrollbar-track{
  background: #f1f1f1;
}
.shop-list-of-info::-webkit-scrollbar-thumb{
  background: #888;
}
.shop-list-of-info::-webkit-scrollbar-thumb:hover{
    background: #555;
}

.yt-main-header .topbar {
  color: var(--color-black);
  background-color: #f6f8fa;
  padding: 21px 0 17px;
}

.yt-main-header .logocontainer {
  background-color: white;
}

.header-log-btn {
  border-radius: 6px;
  /* background-color: #324688; */
  background-color: var(--color-ButtonBackRegular);
  font-family: GTWalsheimPro;
  /* color: #fff; */
  color: var(--color-ButtonTextRegular);
  font-size: 18px;
  height: 50px;
}

.header-log-btn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.yt-header-top-message {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
}

.yt-main-header .supportbox {
  height: 95px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yt-main-header .support {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yt-main-header .supportimage {
  height: auto;
  width: 38.5px;
  margin-right: 10px;
  color: var(--color-primary);
}

.yt-main-header .supportnumber {
  display: flex;
  flex-direction: column;
}

.yt-main-header .supportnumber .title {
  font-size: 13px;
  line-height: 1.2;
  font-weight: normal;
  color: var(--color-dark-grey);
  padding-bottom: 5px;
  margin-top: -5px;
}

.yt-main-header .supportnumber .number {
  font-weight: 500;
  line-height: 1.15;
  font-size: 16px;
  color: var(--color-black);
}

.yt-main-header .searchcontainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.yt-main-header .searchinput {
  border-radius: 6px;
  border: solid 1px #b2b3b3;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 56px;
  line-height: 1.15;
  color: var(--color-black);
  font-size: 16px;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;
}

.yt-main-header .searchinput::-moz-placeholder {
  color: #b2b3b3;
}

.yt-main-header .searchinput:-ms-input-placeholder {
  color: #b2b3b3;
}

.yt-main-header .searchinput::placeholder {
  color: #b2b3b3;
}

.yt-main-header .searchicon {
  height: auto;
  width: 60px;
  padding: 20px;
  position: absolute;
  left: 18px;
}

.yt-main-header .cartcontainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex-wrap: wrap;
}

.yt-main-header .carticon {
  height: auto;
  width: 26px;
  margin-right: 0px;
  cursor: pointer;
  font-size: 10px;
  color: #596271;
}

.yt-main-header .favoriteicon {
  height: auto;
  width: 26px;
  margin-right: 0px;
  cursor: pointer;
  font-size: 10px;
  color: darkslategray;
}
.yt-main-header .loginbutton {
  width: 140px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border-radius: 6px;
  /* background-color: #324688; */
  background-color: var(--color-ButtonBackRegular);
  font-family: GTWalsheimPro;
  color: var(--color-ButtonTextRegular);
  /* color: #fff !important; */
  font-size: 18px;
  height: 50px;
}
.yt-main-header .loginbutton:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.yt-main-header .menucontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 2px;
  background-color: white;
}

.yt-main-header .menuicon {
  height: auto;
  width: 26px;
  position: absolute;
  left: 20px;
  background-color: white;
  cursor: pointer;
}

.yt-main-header nav.navbar {
  padding: 0px;
}

.yt-main-header ul.navbar-nav {
  width: 100%;
  justify-content: space-around;
  background-color: white;
  padding-bottom: 5px !important;
}

.yt-main-header .nav-link {
  color: #514e4e !important;
}

.yt-main-header .nav-link.active, .yt-main-header .nav-link:hover {
  background: #e6e6e6;
  border-radius: 5px;
}

.yt-main-header .cm-drop-down-wrap {
  position: static;
}

.yt-main-header .cm-big-drop-menu {
  /* width: 40%;
  padding: 28px 44px 28px;
  max-width: 1180px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto; */
  width: 100%;
  padding: 52px 60px 37px;
  max-width: 1180px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
}

.yt-main-header .cm-big-menu-head .dropdown-divider {
  border-top: 1px solid var(--color-dark-grey);
}

.yt-main-header .cm-big-menu-inner .dropdown-item {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  padding: 8px 10px;
  margin-bottom: 5px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}


.yt-main-header .cm-big-menu-inner .cm-big-menu-head button {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 1.14;
  color: var(--color-black);
}

.yt-main-header .head-dropdown-arrow-icn {
  font-size: 12px;
  margin-left: 4px;
  margin-top: -7px;
}

.yt-main-header li.cm-drop-down-wrap.dropdown.show > a {
  background: #e6e6e6;
  border-radius: 5px;
}

.yt-main-header .header-search-field-wrap {
  position: relative;
}

.yt-main-header .yt-recent-search-wrap {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 50;
}

.yt-main-header .recent-search-tag-name {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}

.yt-main-header .recent-search-list-wrap .active {
  background: transparent;
  color: inherit;
}

.yt-main-header .yt-recent-search-wrap {
  border-radius: 10px;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 32px 20px 27px;
}

.yt-main-header .yt-no-search-found-wrap {
  padding: 54px 30px 46px;
}

.yt-main-header .recent-search-list-wrap .list-group-item {
  border-bottom: 2px solid #e2e2e2;
  margin: 0;
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  padding: 15px 0 !important;
}

.yt-main-header .recent-search-list-wrap .list-group-item:first-child {
  padding-top: 0 !important;
}

.yt-main-header .recent-search-list-wrap .list-group-item:last-child {
  padding-bottom: 0 !important;
}

.yt-main-header .search-no-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-top: 35px;
  margin-bottom: 15px;
}

.yt-main-header .search-no-sub-ttl {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}

.yt-main-header .yt-main-menu .nav-item {
  cursor: pointer;
  text-transform: uppercase;
}

.yt-main-header .logoimage {
  width: 100%;
  max-width: 190px;
  height: 100px;
}

.yt-head-login-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-dark-black);
  padding: 15px 26px;
}

.user_Name {
  cursor: pointer;
}

.left-menu-items-container {
  overflow-y: scroll;
  max-height: 300px;
  min-height: 300px;
}

.left-menu-item {
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 10px;
}
.left-menu-item.active {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 10px;
}

/* width */
.left-menu-items-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.left-menu-items-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.left-menu-items-container::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.left-menu-items-container::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}

/* width */
.right-menu-items-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.right-menu-items-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.right-menu-items-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.right-menu-items-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.right-menu-items-container {
  overflow-y: auto;
  max-height: 300px;
  min-height: 300px;
}
.right-menu-item {
  font-size: 18px;
  color: #1c1b11;
  padding-bottom: 8px;
  font-weight: 300;
}

.yt-mobile-side-nav-wrap {
  display: none;
}
.yt-cart-icn-mainwp {
  position: relative;
  margin-right: 5px;
}
.yt-cart-icn-mainwp .cart-notify {
  position: absolute;
  font-size: 12px;
  top: -12px;
  right: -6px;
}

.yt-main-header .cartcontainer span.w3-green {
  text-align: center;
  padding: 5px 6px !important;
  border-radius: 50px !important;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  min-width: 20px !important;
  display: inline-block;
}
.cart-notify {
  color: #fff !important;
  background-color: #4caf50 !important;
}
@media (min-width: 1900px) {
  .yt-main-header .cm-big-drop-menu {
    max-width: 1421px;
  }

  .mobileContent {
    display: none;
  }
  .yt-main-header
    .cm-big-drop-menu
    .yt-main-header
    .cm-big-menu-inner
    .dropdown-item {
    font-size: 20px;
    line-height: 1.15;
  }
  .yt-main-header .cm-big-menu-inner .cm-big-menu-head button {
    font-size: 28px;
    line-height: 1.14;
  }
  .yt-main-header .searchinput {
    padding-top: 24px;
    padding-bottom: 23px;
  }
  .yt-main-header .logoimage {
    max-width: 297px;
  }
}

@media only screen and (min-width: 992px) {
  /* .yt-main-header .order-sm-1 {
    order: 0;
  } */
  .yt-main-header .searchicon {
    left: 0px;
  }
  .yt-main-header ul.navbar-nav {
    padding: 6px 226px 0px;
  }
  .mobileContent {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .yt-only-mobile-vw {
    display: none;
  }
  .yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap {
    display: none;
  }
  .mobileContent {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  /* .yt-main-header .order-sm-1 {
    order: 1;
  } */
  .yt-main-header .searchicon {
    left: 0;
  }
  .yt-main-header .searchinput {
    font-size: 13px;
  }
  .mobileContent {
    display: none;
  }
  .yt-main-header .loginbutton {
    height: auto;
    width: auto;
  }
}

/*
* Screen for under 768 Resolution 
*/

@media (max-width: 769px) {
  .userbox span.uname,
  .userbox svg {
    display: none;
  }

  .yt-cart-icn-mainwp {
    position: relative;
    margin-right: 5px;
  }
  .yt-cart-icn-mainwp span.w3-green {
    position: absolute !important;
    right: 0 !important;
  }
  .yt-main-header .searchinput {
    font-size: 12px;
    padding-left: 32px;
  }
  .yt-main-header .searchicon {
    width: 34px;
    padding: 8px;
  }
  .yt-main-header .yt-main-header .topbar {
    display: none;
  }
  .yt-main-header .logocontainer {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .yt-main-header .supportbox {
    display: none;
  }
  .yt-main-header .logoimage {
    max-width: 91px;
  }
  .yt-main-header .logobox {
    margin-left: 38px;
    max-width: 50px;
  }

  .yt-main-header .loginbutton span.yt-head-lgn-btn {
    display: block !important;
  }
  .yt-main-header .loginbutton span {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
  }
  .yt-main-header .loginbutton {
    width: auto;
    height: auto;
    border: none;
    margin-top: 5px;
    margin-left: 0 !important;
  }
  .yt-main-header .carticon {
    margin-right: 11px;
    width: 21px;
  }
  .yt-main-header .favoriteicon {
    margin-right: 11px;
    width: 21px;
  }
  .yt-main-header .yt-head-seachicn-mb {
    display: block !important;
    position: inherit;
    margin-right: 11px;
  }
  .yt-head-mb-search-panel {
    display: block !important;
  }
  .yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap {
    display: block;
  }
  .yt-mobile-side-nav-wrap {
    position: fixed;
    background-color: rgb(62 69 79 / 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  .yt-mobile-side-inner-content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 550;
    max-width: 221px;
    overflow: auto;
    height: 100%;
    width: 100%;
  }
  .yt-mb-user-profile {
    padding: 20px;
    background-color: var(--color-black);
    color: #fff;
  }
  .yt-mobile-side-inner-content .yt-inner-cnt-logo {
    padding: 15px 20px 20px;
  }
  .yt-mobile-side-inner-content .logobox-mb img {
    background-color: var(--color-dark-black);
  }
  .yt-mobile-nav-content {
    padding: 17px 20px;
    border-bottom: solid 1px #dbdbdb;
  }
  .yt-mobile-nav-content:last-child {
    border: none;
  }
  .yt-mb-user-profile .yt-head-name {
    font-size: 18px;
    line-height: 1.13;
    font-family: "GTWalsheimPro-Medium";
    color: var(--color-white);
  }
  .yt-mb-user-profile .yt-head-email {
    font-size: 12px;
    line-height: 1.13;
    font-family: "GTWalsheimPro-Medium";
    color: var(--color-white);
  }
  .yt-mobile-nav-content li:last-child a {
    padding-bottom: 0;
  }
  .yt-mb-user-prfl-lks li:first-child a {
    padding-top: 0;
  }
  .yt-mb-user-prfl-lks {
    border-top: solid 1px #dbdbdb;
    padding: 20px;
  }
  .yt-main-header .yt-mobile-nav-content .cm-big-drop-menu {
    padding: 0;
    border: none;
  }
  .yt-main-header
    .yt-mobile-nav-content
    li.cm-drop-down-wrap.dropdown.show
    > a {
    border-bottom: none;
  }
  .yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider {
    border-bottom: solid 1px #dbdbdb;
    border-top: none;
  }
  .yt-main-header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item {
    font-size: 10px;
    line-height: 1.1;
  }
  .yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-black);
  }
  .yt-mb-user-profile .yt-mb-nav-login-btn {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    font-weight: 500;
  }
  .yt-mb-innet-search {
    padding: 14px 15px 0;
  }
  .yt-main-header .yt-mb-innet-search .searchicon {
    top: 50%;
    transform: translateY(-50%);
    max-width: 16px;
    left: 12px;
    padding: 0px;
  }
  .yt-main-header .yt-mb-innet-search .searchinput {
    padding: 10px 10px 10px 38px !important;
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-main-header .yt-mb-innet-search .searchinput::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-main-header .yt-mb-innet-search .searchinput::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-main-header .yt-mb-innet-search .searchinput:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-main-header .yt-mb-innet-search .searchinput:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
    line-height: 1.17;
  }
  .yt-mb-header-search-bar-wrap input {
    border: none !important;
    padding: 12px 0;
    outline: none !important;
    width: 100%;
    font-size: 4vw;
    font-weight: 500;

    line-height: 1.11;
  }
  .yt-main-header .yt-recent-search-wrap {
    background-color: #f6f8fa;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    margin: 0 !important;
  }
  .yt-main-header .yt-recent-search-wrap > .d-flex {
    margin: 13px 18px !important;
  }
  .yt-main-header .yt-mb-header-search-bar-wrap {
    padding: 17px 20px;
    background: #fff;
  }
  .yt-main-header .recent-search-list-wrap {
    padding: 0 20px;
    background: #fff;
  }
  .yt-main-header .recent-search-list-wrap .list-group-item,
  .yt-main-header .recent-search-list-wrap .list-group-item:first-child,
  .yt-main-header .recent-search-list-wrap .list-group-item:last-child {
    padding: 15px 0 !important;
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-main-header .recent-search-tag-name {
    font-size: 15px;
    line-height: 1.2;
  }
  .yt-header-search-close-icn {
    position: absolute;
    top: 12px;
    right: 23px;
    display: none;
  }
  .yt-header-search-close-icn.d-none {
    display: block !important;
    max-width: 20px;
  }
  .yt-mb-header-search-bar-wrap input::-moz-placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
  }
  .yt-mb-header-search-bar-wrap input:-ms-input-placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
  }
  .yt-mb-header-search-bar-wrap input::placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
  }

  .yt-mb-header-search-bar-wrap input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #dbdbdb;
  }

  .yt-mb-header-search-bar-wrap input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #dbdbdb;
  }
  .yt-no-srch-found {
    margin-top: 58px;
  }
  .yt-main-header .search-no-ttl {
    font-size: 17px;
    line-height: 1.12;
    margin-top: 46px;
    margin-bottom: 8px;
  }
  .yt-main-header .search-no-sub-ttl {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
  }
}

.usericon {
  width: 42px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid silver;
}

.uname {
  margin-left: 10px;
  color: gray;
}

.userbox {
  cursor: pointer;
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .mobileContent {
    display: none;
  }
  .yt-main-header .cm-big-drop-menu {
    padding: 40px;
  }
  .yt-main-header .supportnumber .title {
    font-size: 14px;
  }
  .yt-main-header .supportnumber .number {
    font-size: 18px;
  }

  .yt-main-header .loginbutton {
    font-size: 16px;
  }
  .yt-main-header .yt-main-menu .nav-item {
    font-size: 16px;
  }
  .yt-main-header .cm-big-menu-inner .cm-big-menu-head button {
    font-size: 16px;
  }
}

@media (max-width: 479px) {
  .yt-main-header .loginbutton span {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0;
  }
  .yt-cart-icn-mainwp {
    margin-right: 0;
  }
  .usericon {
    width: 30px;
    height: 30px;
  }
  .yt-main-header .searchcontainer {
    width: 90%;
  }
}

/* div {
    background-color: green;
} */
.logo {
  font-family: GTWalsheimPro;
  font-size: 50px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #324688;
}

.logo img {
  width: 100%;
  max-width: 190px;
  height: auto;
}

.container {
  max-width: 1680px !important;
}
.bottombar {
  /* height: 40px; */
  color: #fff;
  background-color: #342e34;
  font-size: 12.5px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.footercontainer {
  /* padding-top: 50px; 
  padding-top: 5%;*/
  /* background-color: white; 
  padding-bottom: 44px;*/
}
.footercontainer .container-fluid{
  padding-top: 40px;
  padding-bottom: 40px;
  background: #4f464f;
  margin-top: -2px;
  position: relative;
  z-index: 1;
}
.social_all a:first-child img {
  margin-left: 0;
}

.logoimage {
  height: auto;
  width: 190px;
}

.data_underlogo {
  padding-top: 30px;
  height: 95px;
  width: 330px;
}

.navtitle {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
  padding-top: 10px;
}

.navlist {
  font-weight: normal;
  text-decoration: none;
  color: var(--color-dark-grey);
  padding-top: 20px;
}

.navitem {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.promotionsimage {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 270px;
  height: 150px;
}

.social_logos {
  width: 23px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.55s ease;
}

.social_logos:hover {
  transform: translateY(-3px);
  transition: all 0.55s ease;
}

.social_all {
  color: var(--color-primary);
}

.yt-footer-widget-title {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.15;
  color: white;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.yt-footler-menu-links .yt-ftr-link {
  /* font-size: 15px; */
  font-size: 12.5px;
  line-height: 1.36;
  text-align: left;
  color: white;
  cursor: pointer;
}

.yt-ftr-banner {
  margin-top: 6px;
}

.yt-text-about {
  font-size: 12.5px;
  line-height: 1.38;
  text-align: left;
  color: #d2ccd4;
  margin-right: 30px;
}

.yt-copyright-text {
  font-size: 12.5px;
  font-weight: 400;
  line-height: 1.13;
  color: white;
}

.emailinput {
  width: 100%;
  height: 40px;
}
.emailbutton {
  background-color: var(--button-primary);
  color: var(--button-text-primary);
  padding: 10px 35px;
  margin-top: 20px;
  border: none;
}
/* Started */
.dwn-app {
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding-top: 60px;
  padding-bottom: 42px;
}

.p-image {
  width: 100%;
}
/* .store-banner {
  margin-bottom: 80px;
} */

.app-banner {
  padding-bottom: 60px;
}

.bg-img {
  background-image: url(/static/media/neBackground.7c694b2e.jpg);
  width: 100%;
  background-size: cover;
  background-repeat: "no-repeat";
  background-position: "center";
  position: "relative";
}

/* End */
@media (max-width: 1700px) {
  .logo img {
    width: 90%;
  }
}
@media (max-width: 1500px) {
  .logo img {
    width: 80%;
  }
}
@media (max-width: 1370px) {
  .logo img {
    width: 65%;
  }
}

@media (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 93.3%;
  }

  .logo img {
    width: 60%;
  }
}
/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  .yt-col.yt-first-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .yt-foote-link-col,
  .yt-last-col {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .yt-footer-widget-title {
    font-size: 15px;
    line-height: 1.13;
    margin-bottom: 17px;
  }
  .yt-text-about {
    padding-top: 16px;
    padding-bottom: 21px;
    font-size: 14px;
    line-height: 1.57;
    margin-right: 40%;
  }
  .social_all {
    padding: 0;
    margin-bottom: 17px;
  }
  .social_all .social_logos:first-child {
    margin-left: 10px;
  }
  .yt-footler-menu-links {
    margin-top: -6% !important;
  }
  .yt-footler-menu-links .yt-ftr-link {
    font-size: 12.5px;
    line-height: 1.36;
  }
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 900px) {
  .logo img {
    width: 55%;
  }
}

@media (max-width: 767.88px) {
  .logo img {
    width: 40%;
  }
  .yt-last-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .yt-foote-link-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .yt-footer-widget-title,
  .yt-footler-menu-links {
    margin-bottom: 20px;
  }
  .yt-text-about {
    padding-top: 12px;
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 1.83;
    margin-right: 20%;
  }
  .social_all {
    padding: 0;
    margin-bottom: 20px;
  }
  .social_all .social_logos:first-child {
    margin-left: 8px;
  }
  .yt-main-footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .yt-footer-widget-title {
    font-size: 14px;
    line-height: 1.14;
  }
  .yt-footler-menu-links .yt-ftr-link {
    font-size: 12.5px;
    line-height: 1.97;
  }
}

@media (max-width: 767.88px) {
  .logo img {
    width: 30%;
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-footer-widget-title {
    font-size: 15px;
  }
  .yt-footler-menu-links .yt-ftr-link {
    font-size: 12.5px;
  }
}

input[type=radio] {
  border: 0px;
  width: 24px;
  height: 24px;
}

.payment-options {
  font-family: GTWalsheimPro;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: #3e454f;
  margin: 30px 0px;
}

.option {
  font-family: GTWalsheimPro;
  line-height: 1.13;
  text-align: left;
  color: #3e454f;
  font-size: 1.3em;
}
.radio-container {
  margin-bottom: 90px;
}
.radius-10 {
  border-radius: 10px;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.cart-pg-mb-30 {
  margin-bottom: 30px;
}
.yt-currency-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cart-pg-breadcrumbs {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 30px;
}

.cartno-wrap {
  margin-top: 52px;
  margin-bottom: 40px;
}

.cart-pg-current {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}

.empty-cartn-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 17px;
}

.empty-cart-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.empty-cart-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
}

.cart-page-main-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 30px;
}

.cart-prodict-info {
  width: 100%;
}

.cart-product-image {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.cart-product-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
  margin-bottom: 0px;
  max-width: 310px;
  display: inline-block;
}

.cart-action-wrap {
  float: right;
}

.yt-product-code {
  color: silver;
  margin-bottom: 5px;
}

.yt-product-code {
  color: silver;
  margin-bottom: 5px;
  font-size: 11px;
}
.cart-action-separator {
  color: #b2b3b3;
  font-size: 17px;
  vertical-align: top;
}

.cart-prodict-type-container {
  max-width: 17vw;
  overflow-x: auto;
  margin-bottom: 20px;
}
.cart-product-head-container {
  display: flex;
}

@media (max-width: 768px) {
  .cart-prodict-type-container {
    max-width: 54vw;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .cart-prodict-type-container {
    display: inline;
  }

  .yt-product-code {
    font-size: 11px;
  }
}

/* width */
.cart-prodict-type-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.cart-prodict-type-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cart-prodict-type-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.cart-prodict-type-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-prodict-type th {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: #15aa71;
}

.cart-prodict-type th,
.cart-prodict-type td {
  padding: 3px;
}

.cart-product-price {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: #5b5b5b;
}

.cart-product-delete {
  font-size: 14px;
  color: var(--color-orange);
  cursor: pointer;
  display: inline;
}

.cart-product-wishlist {
  font-size: 14px;
  color: #30458c;
  cursor: pointer;
  display: inline;
}

.cart-prodict-amount th {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-product-amount-ttl {
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-black);
  font-weight: bold;
}

.cart-product-amount-qty {
  color: silver;
  text-align: center;
  display: block;
}

.cart-product-amount-price {
  font-size: 18px;
  line-height: 1.11;
  text-align: right;
  color: var(--color-dark-grey);
  display: block;
}

.cart-prodict-amount thead th {
  padding-top: 0;
  padding-bottom: 18px;
  padding-left: 0;
  padding-right: 0;
}

.cart-prodict-amount thead th:first-child {
  width: 60%;
}

.cart-prodict-amount tbody td,
.cart-prodict-total-amount tbody td,
.ccart-prodict-sub-total-amount tbody td,
.cart-prodict-sub-total-amount tbody td {
  padding: 10px 0;
}

.cart-divider {
  border-bottom: solid 1px #e2e2e2 !important;
  display: block;
  margin: 15px 0 20px;
}

.cart-coupon .form-group {
  position: relative;
}

.cart-coupon .form-control {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}

.cart-coupon-btn {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-orange);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: var(--color-orange);
}

.cart-proceed-to-checkput {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  opacity: 0.99;
  border-radius: 5px;
  /* background-color: var(--button-primary) !important; */
  background-color: var(--color-ButtonBackRegular);
  border-color: var(--button-primary) !important;
  color: var(--color-ButtonTextRegular);
  /* color: var(--button-text-primary) !important; */
}
.cart-proceed-to-checkput:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.cart-checkout-tile {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-select-aar-btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: black;
}

.checkout-form-wrap .form-group .form-control {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb !important;
  border-radius: 0;
}

.checkout-form-label {
  font-size: 16px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-dark-grey);
}

.checkout-checkbox .form-group,
.yt-chekout-radio .form-group {
  padding-left: 25px;
  position: relative;
  line-height: 25px;
}

.yt-empty-cart-wrap {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  margin: 0;
}

.cart-pg-inner-wrap .yt-checkout-form-chk-box,
.cart-pg-inner-wrap .yt-checkout-form-rado-box {
  position: absolute;
  cursor: pointer;
  margin: 0;
  left: 0;
  top: 0;
}

.cart-pg-inner-wrap .checkout-checkbox .yt-checkout-form-chk-box:before,
.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}

.cart-pg-inner-wrap .yt-checkout-form-rado-box:before {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 5px;
}

.yt-checkout-chekbox-label {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-black);
}

.cart-pg-inner-wrap
  .checkout-checkbox
  .form-check-input:checked
  + label:before {
  background-color: var(--color-primary);
}

.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  z-index: 5;
  background: var(--color-primary);
  border-radius: 25px;
  left: 4px;
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label:before {
  border-color: var(--color-primary);
}

.cart-pg-inner-wrap .yt-chekout-radio .form-check-input {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
  bottom: 0;
  height: 100%;
}

.checkout-form-wrap .form-check-label {
  line-height: 1.5;
}

.cart-coupon .error .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}

.cart-coupon .success .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}

.cart-coupon-change-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}

.cart-coupon .cart-coupon-bottom-wrapper {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}

.cart-coupon .error .cart-coupon-bottom-wrapper,
.cart-coupon .success .cart-coupon-bottom-wrapper {
  opacity: 1;
}
form.yt-cart-disct-wrap .yt-form-cpn-err.form-group,
form.yt-cart-disct-wrap .success.form-group {
  margin-bottom: 20px !important;
}
.cart-coupon .success .cart-coupon-bottom-wrapper .error-message,
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message,
.login-on-cart-page .lp-bottom-links {
  display: none;
}

.yt-login-wrap {
  margin-top: 0 !important;
}

.cart-prodict-amount thead th:last-child {
  text-align: right;
}

.cart-prodict-type tr td {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}

.cart-pg-list-image {
  width: 20%;
}

.cart-pg-list-prdt-info {
  width: 75%;
}

.cart-list-other-act {
  position: relative;
  min-width: 210px;
}

.cart-quantity-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 115px;
}

.checkout-form-wrap .yt-remove-arrow::-webkit-outer-spin-button,
.checkout-form-wrap .yt-remove-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.checkout-form-wrap .yt-remove-arrow[type="number"] {
  -moz-appearance: textfield;
}

.cart-produt-list-wrap {
  padding: 39px 19px 38px;
  /* padding: 40px 40px 28px; */
}

.yt-cart-price-lister {
  padding: 40px 30px 56px;
  margin-bottom: 30px;
}

.checkout-form-wrap .cart-pg-inner-wrap {
  padding: 40px 30px 30px;
}

.yt-cm-lt-col {
  padding-right: 7px;
}

.yt-cm-rt-col {
  padding-left: 7px;
}

@media (min-width: 768px) {
  .cart-proceed-to-checkput.cart-btn-bill-pg {
    display: none;
  }
  .cart-pg-list-image .cart-product-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .cart-pg-list-image .cart-product-image {
    width: 100%;
    height: 10vw;
  }
}

@media (max-width: 992px) {
  .cart-product-head-container {
    display: block;
  }
}

@media (min-width: 1280px) {
  .empty-main-ttl {
    display: none;
  }
  .cart-prodict-type th,
  .cart-prodict-type td {
    padding: 3.5px 0;
    padding-right: 74px;
  }
  .cart-prodict-type th:last-child,
  .cart-prodict-type td:last-child {
    padding-right: 0;
  }
}

@media (min-width: 1280.88px) {
  .yt-cm-lt-col {
    padding-right: 10px;
  }
  .yt-cm-rt-col {
    padding-left: 10px;
  }
}

@media (min-width: 1900px) {
  .yt-cm-lt-col {
    padding-right: 20px;
  }
  .yt-cm-rt-col {
    padding-left: 20px;
  }
  .cart-product-title {
    line-height: 1.67;
    max-width: 100%;
  }
  .cart-produt-list-wrap {
    padding: 40px 40px 37px;
  }
}

/*
* Screen for under 1280 Resolution 
*/

@media (max-width: 1279.92px) {
  .empty-cartn-ttl {
    font-size: 24px;
    line-height: 1.17;
  }
  .empty-cart-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .empty-cart-btn {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .yt-empty-cart-wrap.cart-pg-inner-wrap {
    margin-top: 10px !important;
  }
  .cart-page-main-title {
    margin-bottom: 10px !important;
    font-size: 24px;
    line-height: 1.17;
  }
  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
    padding-bottom: 34px;
  }
  .yt-cart-price-lister {
    padding: 20px 15px;
  }
  .cart-product-title {
    font-size: 16px;
    line-height: 1.13;
  }
  .cart-prodict-type th {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
  }
  .cart-prodict-type tr td {
    font-size: 16px;
    line-height: 1.31;
  }
  .cart-product-price {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
  }
  .cart-prodict-amount thead th:first-child {
    font-size: 18px;
    line-height: 1.11;
  }
  .cart-prodict-amount thead th:last-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
  }
  .cart-product-amount-ttl {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .cart-product-amount-price {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }
  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 7.5px 0;
  }
  .cart-divider {
    margin: 15px 0;
  }
  .cart-proceed-to-checkput {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .cart-coupon .form-control {
    /* padding: 12px 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }
  .cart-coupon .error .cart-coupon-code-message {
    font-size: 12px;
    line-height: 1.58;
  }
  .cart-coupon-change-btn {
    font-size: 14px;
    line-height: 1.14;
  }
  .checkout-form-wrap .yt-chekout-radio .yt-currency-wrap.form-group {
    padding-left: 0 !important;
  }
}

/*
* Screen for under 768 Resolution 
*/

@media (max-width: 767.92px) {
  .cart-pg-inner-wrap
    .yt-chekout-radio
    .form-check-input:checked
    + label:after {
    top: 4px !important;
  }
  .cart-pg-list-image .cart-product-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
  .cart-pg-list-image .cart-product-image {
    width: 70px;
    height: 70px;
  }
  .cart-checkout-wrap .cart-proceed-to-checkput {
    margin-bottom: 15px;
  }
  .yt-cm-row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .yt-cm-lt-col {
    padding-right: 11px;
    padding-left: 11px;
  }
  .yt-cm-rt-col {
    padding: 0;
  }
  .empty-cartn-ttl {
    font-size: 17px;
    line-height: 1.12;
  }
  .empty-cart-text {
    font-size: 15px;
    line-height: 1.2;
  }
  .empty-cart-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .cart-page-main-title {
    font-size: 18px;
    line-height: 1.11;
  }
  .cart-produt-list-wrap {
    padding: 15px;
    margin-bottom: 10px;
    padding-bottom: 34px;
  }
  .cart-product-title {
    font-size: 14px;
    line-height: 1.36;
  }
  .cart-prodict-type th {
    /* font-size: 10px; */
    line-height: 1.1;
    letter-spacing: 0.38px;
  }
  .cart-prodict-type tr td {
    font-size: 14px;
    line-height: 1.5;
  }
  .cart-product-price {
    font-size: 15px;
    line-height: 1.2;
  }
  .cart-product-delete svg {
    font-size: 15px;
  }
  .cart-page-main-title {
    margin-bottom: 10px !important;
  }
  .yt-cart-price-lister {
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
  }
  .cart-prodict-amount thead th:first-child {
    font-size: 16px;
    line-height: 1.19;
  }
  .cart-prodict-amount thead th:last-child {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
  }
  .cart-product-amount-ttl {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
  }
  .cart-product-amount-price {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
  }
  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    padding: 8px 0;
  }
  .cart-divider {
    margin: 3px 0 7px;
  }
  .cart-coupon .form-control {
    /* padding: 15px; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }
  .text-right .btn-secondary {
    color: #fff;
    background-color: #324688 !important;
    border-color: #324688 !important;
  }
  .cart-proceed-to-checkput {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .checkout-form-wrap .cart-pg-inner-wrap {
    padding: 20px;
    margin-bottom: 10px;
  }
  .cart-checkout-tile {
    font-size: 15px;
    line-height: 1.2;
  }
  .cart-select-aar-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .checkout-form-label {
    font-size: 12px;
    line-height: 1.17;
  }
  .cart-pg-inner-wrap .checkout-checkbox .form-check-input {
    font-size: 10px;
    line-height: 1.1;
  }
  .checkout-form-wrap .yt-chekout-radio .form-check-label {
    font-size: 12px;
    line-height: 1.17;
  }
  .checkout-form-wrap .yt-chekout-radio .form-check-label .form-check-input {
    margin-top: 0px;
  }
  /* .checkout-form-wrap .yt-chekout-radio > div {
    width: 100%;
    margin-bottom: 10px;
  } */
  .cart-pg-inner-wrap .yt-checkout-form-rado-box {
    top: 6px;
  }
}

@media (max-width: 575.98px) {
  .empty-cart-text {
    max-width: 233px;
    margin-left: auto;
    margin-right: auto;
  }
  .empty-cart-btn {
    width: 100%;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-chekout-radio .checkout-form-label {
    font-size: 16px;
    line-height: 1.13;
  }
  .yt-inner-wrap .yt-items.item {
    padding-top: 30px;
  }
  .empty-cart-btn {
    font-size: 16px;
  }
  .cart-page-main-title {
    font-size: 26px;
  }
  .cart-proceed-to-checkput {
    font-size: 16px;
  }
  .cart-produt-list-wrap {
    padding: 30px;
    margin-bottom: 20px !important;
  }
  .yt-cart-price-lister {
    padding: 30px;
  }
  .cart-divider {
    margin: 10px 0 !important;
  }
  form.yt-cart-disct-wrap {
    padding: 10px 0 !important;
  }
  .cart-prodict-amount tbody tr:last-child td {
    padding-bottom: 20px;
  }
  table.yt-sub-ttl-tbl-wrap.table tr:first-child td {
    padding-top: 20px;
  }
  table.yt-sub-ttl-tbl-wrap.table {
    margin: 0;
  }
}

.card-details .rccs {
  margin: 0;
  width:100%;
}
.card-details .rccs .rccs__card{
  width: 100%;
}

.card-details {
  font-family: GTWalsheimPro;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #3e454f;
  margin-bottom: 30px;
}

.stripe-btn button {
  /* background: var(--button-primary) !important; */
  background-color: var(--color-ButtonBackRegular) !important;
  color: var(--color-ButtonTextRegular);
  cursor: pointer;
}

.stripe-btn button:hover{
  background-color: var(--color-ButtonBackHover) !important;
  color: var(--color-ButtonTextHover) !important;
}

.stripe-label {
  font-family: GTWalsheimPro;
  line-height: 1.13;
  color: #8b8f95;
  font-size: 16px;
}
.radius-10 {
  border-radius: 10px;
}
.orderplc-pg-mb-30 {
  margin-bottom: 30px;
}
.cm-bg-green {
  background-color: var(--color-green);
}
.yt-order-placed-icn {
  max-height: 131px;
  width: auto;
}
.orderplc-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-white) !important;
}
.orderplc-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-white) !important;
}
.orderplc-btn {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--button-text-primary);
  width: 100%;
  max-width: 180px;
}
.orderplc-pg-inner-wrap {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
.orderplc-pg-success-main-wrap {
  background-color: var(--color-green) !important;
}
@media (min-width: 1900px) {
  .orderplc-pg-mb-80 {
    margin-bottom: 80px;
  }
}

@media (min-width: 1280px) {
  .orderplc-pg-inner-wrap {
    margin-top: 40px !important;
    margin-bottom: 40px;
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .orderplc-pg-inner-wrap {
    margin-top: 20px !important;
    margin-bottom: 60px;
  }
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
  .orderplc-pg-inner-wrap {
    margin-top: 15px !important;
    margin-bottom: 15px;
    padding: 20px !important;
  }
  .orderplc-pg-success-main-wrap {
    padding-bottom: 0 !important;
  }
  .yt-order-placed-icn {
    max-height: 85px;
  }
  .orderplc-ttl {
    font-size: 16px;
    line-height: 1.13;
  }
  .orderplc-text {
    line-height: 1.14;
    font-size: 14px;
  }
  .orderplc-btn {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .orderplc-btn {
    font-size: 16px;
    line-height: 1.13;
  }
}

.radius-10 {
  border-radius: 10px;
}
.trans-fl-pg-mb-30 {
  margin-bottom: 30px;
}
.trans-fl-pg-mb-80 {
  margin-bottom: 80px;
}
.trans-text-div .trans-fl-ttl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-white);
}
.trans-fl-wrap {
  margin-bottom: 50px;
  margin-top: 50px;
}
.trans-text-div .trans-fl-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-white) !important;
}

button.btn.btn-trans-fl-btn.py-3.px-5 {
  opacity: 0.99;
  border-radius: 6px;
  /* background-color: var(--color-primary); */
  background-color: var(--color-ButtonBackRegular);
  /* border-color: var(--color-primary); */
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  /* color: var(--color-white); */
  color:var(--color-ButtonTextRegular);
}

button.btn.btn-trans-fl-btn.py-3.px-5:hover{
  background-color: var(--color-ButtonBackHover);
  color:var(--color-ButtonTextHover);
}

.trans-fl-cancel-btn {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-white) !important;
  box-shadow: none !important;
}
.yt-transaction-cl-icn {
  max-height: 135px;
  width: auto;
}
.transaction-fail .trans-fl-pg-inner-wrap {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-orange) !important;
}
@media (min-width: 1280px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 60px !important;
    margin-bottom: 60px;
  }
}
@media (min-width: 1900px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 80px !important;
    margin-bottom: 80px;
  }
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .trans-fl-wrap {
    margin-top: 75px;
    margin-bottom: 40px;
  }
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 15px !important;
    margin-bottom: 15px;
    padding: 11px 20px 20px !important;
  }
  .trans-fl-btn {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
  }
  .cart-pg-empty-main-wrap {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .trans-fl-cancel-btn {
    font-size: 12px;
    line-height: 1.17;
  }
  .trans-fl-ttl {
    font-size: 16px;
    line-height: 1.13;
  }
  .trans-fl-text {
    font-size: 14px;
    line-height: 1.14;
  }
  .trans-fl-wrap {
    margin-bottom: 22px !important;
    margin-top: 20px !important;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .trans-fl-pg-inner-wrap {
    margin-top: 40px !important;
    margin-bottom: 40px;
  }
}

:root {
  --color-primary: #324688;
  --color-dark-grey: #8b8f95;
  --color-black: #3e454f;
  --color-dark-grey: #8b8f95;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f0573a;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;

  --button-primary: #324688;
  --button-text-primary: #ffffff;
}

* {
  font-family: 'Avenir Next LT Pro', sans-serif;
}

#root br {
  display: none;
}

.custom-scroll-bar {
  max-height: calc(100vh - 22vh);
  height: auto;
  overflow-y: auto;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 8px;
}
.custom-scroll-bar:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-scroll-bar:hover::-webkit-scrollbar-thumb {
  background: gray;
  overflow-y: auto;
  min-height: 150px;
}

